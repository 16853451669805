import React from 'react'
import { TextInputProps } from './text-input.props'
import { Box, TextField, Typography } from '@mui/material'
import { useForm, Controller } from "react-hook-form";
import InputMask from 'react-input-mask';

export default function TextInput({
    control,
    name,
    error,
    label,
    placeholder = "",
    width = "100%",
    className = "",
    isDisabled = false,
    mask = "",
    helperText,
    maxLength
}: TextInputProps) {



    return (
        <Box
            className={`${className}`}
            component="div"
            width={width}
        >
            <Controller
                name={name}
                control={control}
                render={({ formState, field }) =>
                    // <InputMask
                    //     mask={mask}
                    //     value={field.value}
                    //     onChange={field.onChange}
                    //     disabled={isDisabled}
                    //     maskPlaceholder=' '
                    //     alwaysShowMask={true}
                    //     placeholder={placeholder}
                    //     {...field}
                    // >
                    <TextField
                        label={label}
                        variant="outlined"
                        placeholder={placeholder}
                        fullWidth
                        error={error ? true : false}
                        helperText={error}
                        disabled={isDisabled}
                        // disabled={isDisabled}
                        sx={{
                            "fieldset": {
                                borderColor: "gree#D7E7FA",
                                borderRadius: "12px"
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset>legend": {
                                    fontSize: "8px",
                                },
                            },

                        }}
                        InputLabelProps={{
                            shrink: true,
                            sx: {
                                color: "#8E92B9",
                                '&.MuiInputLabel-shrink': {
                                    fontSize: "10px",

                                },
                                left: "3px",
                                top: "3px"
                            },
                        }
                        }
                        inputProps={maxLength ? { maxLength } : {}}

                        InputProps={{
                            sx: {
                                "input": {
                                    px: "24px",
                                    "&:-webkit-autofill": {
                                        WebkitBoxShadow: "0 0 0 1000px white inset"
                                    }
                                },
                                fontSize: "17px",
                                color: "#4A4F78",
                                height: "55px"
                            },
                        }}
                        {...field}
                        onChange={(e) => {
                            if (e.target.name !== "phone_number") {
                                field.onChange(e.target.value)
                                return;
                            }
                            const value = e.target.value.startsWith('+971') ? e.target.value : '+971';
                            field.onChange(value);
                        }}
                    />

                    // </InputMask>

                }
            />

            {helperText && <Typography
                color="#8E92B9"
                fontSize="10px"
                mt={1}

            >
                {helperText}
            </Typography>}
        </Box>
    )
}