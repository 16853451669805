import React, { useEffect, useRef, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '../../button/Button';
import { ButtonType } from '../../button/button.props';
import { useAppDispatch } from '../../../hooks/store.hook';
import { setErrorMessages } from '../../../store/slices/error.slice';
import { CircularProgress } from '@mui/material';
import { getOrders } from '../../../utilities/api';
import { createFullSearchQuery } from '../../../utilities/helper';
import { CSVLink } from "react-csv";
import moment from 'moment';
import { useParams } from 'react-router-dom';

const orderHeader = [
    { label: 'id', key: "id" },
    { label: 'Ticket No', key: 'ticket_number' },
    { label: 'Phone Number', key: "phone_number" },
    { label: 'Full Name', key: "full_name" },
    { label: 'Branch Name', key: "location_name" },
    { label: 'City', key: "city" },
    { label: 'Created Date', key: "created_at" },
    { label: 'Duration', key: "completed_at" },
    { label: 'Order Details', key: "order_details" },
    { label: 'Status', key: "status" },
];

function calcDuration(completed_at: any, created_at: any) {
    const completedTime = completed_at ? moment(completed_at) : null;
    const createdTime = moment(created_at);
    const duration = completedTime ? moment.duration(completedTime.diff(createdTime)) : null;

    if (!duration) return;
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    const parts = [];

    if (years > 0) {
        parts.push(`${years} Yr${years > 1 ? 's' : ''}`);
    }
    if (months > 0) {
        parts.push(`${months} Mon${months > 1 ? 'ths' : 'th'}`);
    }
    if (days > 0) {
        parts.push(`${days} Day${days > 1 ? 's' : ''}`);
    }
    if (hours > 0) {
        parts.push(`${hours} Hr${hours > 1 ? 's' : ''}`);
    }
    if (minutes > 0) {
        parts.push(`${minutes} Min${minutes > 1 ? 's' : ''}`);
    }

    return parts.join(' ');
}

export default function ExportButton({ query = "" }) {

    const { status } = useParams();

    const dispatch = useAppDispatch();
    const exportRef = useRef(null);

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    async function onClick() {
        setLoading(true);
        try {

            if (status) {
                const response = await getOrders(`${createFullSearchQuery({
                    query, page: 1,
                    status
                })}&export=true`);
                const data = response.data;

                setData(data.map((item: any) => ({
                    ...item,
                    created_at: moment(item.created_at).format("DD/MM/YYYY hh:mm A"),
                    completed_at: calcDuration(item.completed_at, item.created_at),
                })));
            } else {
                const response = await getOrders(`${createFullSearchQuery({ query, page: 1 })}&export=true`);
                const data = response.data;

                setData(data.map((item: any) => ({
                    ...item,
                    created_at: moment(item.created_at).format("DD/MM/YYYY hh:mm A"),
                    completed_at: calcDuration(item.completed_at, item.created_at),
                })));
            }


            return;

        } catch (error) {
            dispatch(setErrorMessages(error));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (data.length > 0 && exportRef.current) {
            exportRef.current.link.click();
        }
    }, [data]);


    return (
        <div>
            <Button
                onClick={onClick}
                type={isLoading ? ButtonType.DISABLED : ButtonType.COLORED}
            >
                {isLoading ? (
                    <CircularProgress color="inherit" size={30} />
                ) : (
                    <>
                        Export <span className='ml-3'><FileDownloadIcon /></span>
                    </>
                )}
            </Button>

            <CSVLink
                style={{ display: "none" }}
                data={data}
                headers={orderHeader}
                ref={exportRef}
                filename={`orders_${moment().format("YYYYMMDD_HHmmss")}.csv`}
            >
                Download me
            </CSVLink>
        </div>
    );
}
