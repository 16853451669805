import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILocation } from "../../interfaces/locations";
import { addBranch, updateBranch } from "../thunk-actions/branch-action";


const initialState: {
    branches: ILocation[],
    isLoading: boolean
} = {
    branches: [],
    isLoading: false
}

const slice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        setBranches(state, action: PayloadAction<ILocation[]>) {
            state.branches = action.payload
        }
    },

    extraReducers: (builder) => {
        //add branch
        builder.addCase(addBranch.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(addBranch.fulfilled, (state, action) => {
            state.isLoading = false;
            state.branches = [action.payload, ...state.branches];
        })
        builder.addCase(addBranch.rejected, (state, action) => {
            state.isLoading = false;
        })

        //update branch
        builder.addCase(updateBranch.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateBranch.fulfilled, (state, action) => {

            state.isLoading = false;

            state.branches = state.branches.map(branch => {
                if (branch.id !== action.payload.id) return branch;

                return action.payload
            })


        })
        builder.addCase(updateBranch.rejected, (state, action) => {
            state.isLoading = false;
        })
    }
})


const { setBranches } = slice.actions

export { setBranches }
export default slice.reducer;