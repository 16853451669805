import { IOrder } from "../interfaces/order.interface";
import { addDoneOrdersCount, addProcessingOrderCount, addRealTimeOrder, removeProcessingOrderCount, removeRealTimeOrders, updateRealTimeOrders } from "../store/slices/order.slice";
import { OrderStatus } from "../utilities/constants";
import pusher from "../utilities/pusher";
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "./store.hook";
// import { playSoundNotification } from "../utilities/helper";
import { EmployeeRole } from "../interfaces/employees.interface";
import { useParams } from "react-router-dom";
import { playSoundNotification } from "../utilities/helper";
import { setSuccessMessage } from "../store/slices/success.slice";


const usePusher = () => {

    const dispatch = useAppDispatch()
    const { locations } = useAppSelector(s => s.location)
    const { user, role } = useAppSelector(s => s.user)

    //get status
    const { status } = useParams();

    //channel name
    const [channelName, setChannelName] = useState(null)

    //CONSTANTS
    const roleArr = ["admin", "superadmin"];
    const createdEvent = "order.created"
    const updateEvent = "order.updated"


    function createEventHandler(data: IOrder) {

        console.log(data)

        if (!data) return;

        const location = locations.find(location => location.id === data.location)

        if (!["done", "canceled"].includes(status)) {

            dispatch(addRealTimeOrder({
                ...data,
                location_prefix: location?.location_prefix,
                location_name: location?.name,
                city: location?.city
            }))
            dispatch(addProcessingOrderCount(1))

            if (data.status === OrderStatus.NEW && user.location === data.location && role === EmployeeRole.KITCHEN) {
                playSoundNotification()
                dispatch(setSuccessMessage(`New order #${location.location_prefix}-${data.ticket_number} is created`))
            }
        }


    }



    function updateEventHandler(data: IOrder) {

        if (!data) return;

        const location = locations.find(location => location.id === data.location)

        if (status === "done" && data.status === OrderStatus.DONE) {
            dispatch(addRealTimeOrder({
                ...data,
                location_prefix: location?.location_prefix,
                location_name: location?.name,
                city: location?.city
            }))

        } else if (status === "canceled" && data.status === OrderStatus.CANCELED) {
            dispatch(addRealTimeOrder({
                ...data,
                location_prefix: location?.location_prefix,
                location_name: location?.name,
                city: location?.city
            }))
        } else if (status === "processing" && data.status === OrderStatus.UPDATED) {
            dispatch(removeRealTimeOrders(data))
            dispatch(addRealTimeOrder({
                ...data,
                location_prefix: location?.location_prefix,
                location_name: location?.name,
                city: location?.city
            }))
        } else if (status === "processing" && (data.status === OrderStatus.CANCELED || data.status === OrderStatus.DONE)) {
            dispatch(removeRealTimeOrders(data))

        } else {
            dispatch(updateRealTimeOrders(data))

            if (data.status === OrderStatus.DONE || data.status === OrderStatus.CANCELED)
                dispatch(removeProcessingOrderCount(1))

            if (data.status === OrderStatus.DONE)
                dispatch(addDoneOrdersCount(1))
        }

        if (data.status === OrderStatus.UPDATED && user.location === data.location && role === EmployeeRole.KITCHEN) {
            playSoundNotification()
            dispatch(setSuccessMessage(`Order #${location.location_prefix}-${data.ticket_number} is updated`))
        }


    }

    useEffect(() => {
        if (!user) return;
        if (roleArr.includes(role)) {
            setChannelName("admin-update")
        } else {
            setChannelName(`employee-update-${user.location}`)
        }
    }, [role, user])



    useEffect(() => {

        if (!channelName) return;
        const channel = pusher.subscribe(channelName);

        channel.bind(createdEvent, createEventHandler);
        channel.bind(updateEvent, updateEventHandler);

        return () => {
            channel.unbind(createdEvent, createEventHandler);
            channel.unbind(updateEvent, updateEventHandler);

            channel.unsubscribe();
        };
    }, [channelName, status]);


};

export default usePusher;

