import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useAppSelector } from '../hooks/store.hook';

const styleContent = {

    height: "fit-content"


};

type props = {
    open: boolean,
    children?: React.ReactNode,
    toggleOpen: any
}

const Model: React.FC<props> = ({ open = true, children, toggleOpen }) => {


    return (
        <Modal
            open={open}
            onClose={toggleOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                backgroundColor: "rgba(12, 12, 19, 0.82)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: { xs: 1, md: 5 },
                bottom: "unset",
                minHeight: "100%",
                height: "100%"
            }}
        >

            <Box
                component="div"
                sx={{
                    overflowY: "auto",
                    maxHeight: "100%",
                    scrollbarWidth: 'none', // Hide scrollbar in Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none' // Hide scrollbar in WebKit browsers (like Chrome and Safari)
                    },
                    borderRadius: "12px",
                    bgcolor: "#fff",
                    border: "none",
                    outline: "none",
                    boxShadow: 24,
                    mx: "auto",
                    width: {
                        xs: "100%",
                        md: 675
                    },
                }}
            >
                {children}
            </Box>


        </Modal>
    );
}

export default Model