import React, { useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { updateUserAction } from '../store/slices/user.slice';
import PasswordInput from '../components/form/password-input/PasswordInput';
import { setErrorMessages } from '../store/slices/error.slice';
import { changePassword } from '../utilities/api';
import { setSuccessMessage } from '../store/slices/success.slice';


const schema = yup.object({
    new_password: yup.string().min(8).max(16).required("password is required"),
    new_password_confirmation: yup.string()
        .oneOf([yup.ref('new_password'), null], 'Passwords must match')
}).required();


export default function ChangePasswordForm() {

    const dispatch = useAppDispatch()
    const { user } = useAppSelector(s => s.user)

    const [isLoading, setLoading] = useState(false)
    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            old_password: "",
            new_password: "",
            new_password_confirmation: "",
        }
    });

    const onSubmit = async (data: any) => {
        setLoading(true)
        try {
            await changePassword(data)
            reset()
            dispatch(setSuccessMessage("Password changed successfully"))
        } catch (error) {
            dispatch(setErrorMessages(error))
        } finally {
            setLoading(false)
        }
    }

    return (
        <FormLayout

            title='Change Password'
            onSubmit={handleSubmit(onSubmit)}
            p={3}
            fontSize='16px'
            withCancel={true}
            onClick={() => reset()}
            button={{
                isDirty,
                loading: isLoading,
                isValid
            }}
        >
            <Grid
                container
                columnSpacing={{ xs: 0, md: 4 }}
                rowSpacing={{ md: 4, xs: 2 }}
                mb={{ xs: 2, md: 4 }}
            >
                <Grid item xs={12} >
                    <PasswordInput
                        control={control}
                        name={"old_password"}
                        error={errors.old_password?.message}
                        label={"Password"}
                        placeholder="Password"
                    />
                </Grid>
                <Grid item xs={12} >
                    <PasswordInput
                        control={control}
                        name={"new_password"}
                        error={errors.new_password?.message}
                        label={"New Password"}
                        placeholder="New Password"
                    />
                </Grid>
                <Grid item xs={12} >
                    <PasswordInput
                        control={control}
                        name={"new_password_confirmation"}
                        error={errors.new_password_confirmation?.message}
                        label={"Confirm Password"}
                        placeholder="Confirm Password"
                    />
                </Grid>
            </Grid>
        </FormLayout>
    )
}
