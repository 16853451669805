import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import ReportsList from '../../components/reports/reports-list/ReportsList'
import PageHeader from '../../components/page-header/PageHeader'
import { useState } from 'react'
import ReportFilter from '../../components/reports/report-filter/ReportFilter'
import ReportInformation from '../../components/reports/information/ReportInformation'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Pagination from '../../components/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook'
import { useGetOrders } from '../../hooks/get-orders.hook'
import { ISorting } from '../../interfaces/sorting.interface'
import { updateQueryStringParameter } from '../../utilities/helper'
import usePusher from '../../hooks/usePusher'
import { useGetOrdersCount } from '../../hooks/get-orders-count.hook'



export default function Reports() {

    const navigate = useNavigate()
    const { orders, doneCount, processingCount } = useAppSelector(s => s.order)
    const dispatch = useAppDispatch()


    //set number of page
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get("page") || '1', 10);

    const [query, setQuery] = useState("")
    const [sorting, setSorting] = useState<ISorting>({
        sort_by: "",
        sort: "ASC"
    });


    function handelSorting(value: string) {
        setSorting(s => ({
            sort_by: value,
            sort: s.sort === "ASC" ? "DESC" : "ASC",
            // sort: s.sort_by !== value ? "ASC" : s.sort === "ASC" ? "DESC" : "ASC"

        }))

        setQuery(s => {
            let updatedQuery = s || '';
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort_by', value);
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort', sorting.sort);
            return updatedQuery;
        });
        navigate("")
    }



    //get orders
    const { isLoading, isFetching, data } = useGetOrders(dispatch, "", page, query)
    useGetOrdersCount(dispatch, "", page, query)

    return (
        <PageLayout>

            <PageHeader title='Reports' className='mb-4 md:mb-6' />

            <ReportInformation
                doneCount={doneCount}
                processingCount={processingCount}
                query={query}
            />

            <ReportFilter
                query={query}
                updateFilter={(query) => setQuery(query)}
                isAll={true}
            />

            {/* {isLoading ? <Spinner /> : <>
                <ReportsList isAll={true} reports={orders} handelSorting={handelSorting} isLoading={isFetching} />
                <Pagination count={Math.ceil(data?.all?.total / 10) || 1} page={page} />
            </>} */}

            <ReportsList
                isAll={true}
                reports={isLoading || isFetching ? [] : orders}
                handelSorting={handelSorting}
                isLoading={isLoading || isFetching}
            />

            <Pagination count={Math.ceil(data?.total / 10) || 1} page={page} />

        </PageLayout>
    )
}
