import React, { useEffect, useState } from 'react'
import PageHeader from '../../components/page-header/PageHeader'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import EmployeeData from '../../components/employee/employee/EmployeeData'
import { useParams } from 'react-router-dom'
import { employees } from '../../data/employees'
import { IEmployee } from '../../interfaces/employees.interface'
import BackArrow from '../../components/back-arrow/BackArrow'
import { useAppDispatch } from '../../hooks/store.hook'
import { setErrorMessages } from '../../store/slices/error.slice'
import { getEmployeeById } from '../../utilities/api'

export default function Employee() {

    const { id } = useParams()
    const dispatch = useAppDispatch()

    const [employee, setEmployee] = useState(null);

    async function getEmployee() {

        try {


            const { data } = await getEmployeeById(+id)

            setEmployee(data)
        } catch (error) {
            dispatch(setErrorMessages(error))
        }
    };


    useEffect(() => {
        getEmployee()
    }, [id])


    if (!employee) return null

    return (
        <PageLayout
        >
            <PageHeader
                title='Employee'

                className='mb-6 mt-4'
            />
            <BackArrow />
            <EmployeeData employee={employee} />
        </PageLayout>)
}
