import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Button } from '@mui/material';
import { RadioInputProps } from './radio-input.props';

export default function RadioInput({ control, name, menu, defaultValue, label }: RadioInputProps) {
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                    <RadioGroup {...field} >
                        {menu.map(item => <FormControlLabel
                            key={item.value}
                            value={item.value}
                            control={<Radio />}
                            label={item.label}
                        />)}
                    </RadioGroup>
                )}
            />
        </FormControl>
    )
}
