import { createSlice } from "@reduxjs/toolkit";
import { login } from '../thunk-actions/auth-actions';
import { getUser, updateProfile, updateUser, updateUserImage } from '../thunk-actions/user-action';
import { EmployeeRole } from '../../interfaces/employees.interface';
import { logout } from './auth.slice';
import { IUser } from "../../interfaces/user";


const initialState: {
    user: IUser,
    loading: boolean,
    errors: string[],
    role: string
} = {
    loading: true,
    errors: [] as string[],
    role: "",
    user: null
}

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {

        setUserRole(state, action) {
            state.role = action.payload
        },
        updateUserAction(state, { payload }) {
            state.user = {
                ...state.user,
                ...payload
            }
        }
    },
    extraReducers: (builder) => {

        //logout
        builder.addCase(logout, (state, action) => {
            state.user = null;
            state.role = ""
        })

        //login
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload;
            state.role = action.payload.user_type
        })
        //getUser
        builder.addCase(getUser.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.role = action.payload.user_type
        })
        builder.addCase(getUser.rejected, (state, action) => {
            state.loading = false;
            localStorage.removeItem("token");
            state.user = null;
            state.role = ""
        })

        //updateUser
        builder.addCase(updateUser.pending, (state, action) => {
            state.loading = true;
            state.errors = [];
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload
        })
        builder.addCase(updateUser.rejected, (state, action) => {
            state.loading = false;
        })

        //updateUser
        builder.addCase(updateProfile.pending, (state, action) => {
            // state.loading = true;
            state.errors = [];
        })
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            // state.loading = false;
            state.user = action.payload
        })

        //updateUser
        builder.addCase(updateUserImage.pending, (state, action) => {
            // state.loading = true;
            state.errors = [];
        })
        builder.addCase(updateUserImage.fulfilled, (state, action) => {
            // state.loading = false;
            state.user.profile_pic = action.payload
        })
    }
})

export default slice.reducer;

const {
    updateUserAction,
    setUserRole
} = slice.actions

export { updateUserAction, setUserRole }