import React, { useEffect, useState } from 'react'
import { EmployeeRole, IEmployee } from '../../../interfaces/employees.interface'
import { Avatar, Box, Typography } from '@mui/material';
import Status from '../../status/Status';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { IUser } from '../../../interfaces/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook';
import { updateUserType, uploadEmployeeImage } from '../../../store/thunk-actions/employee-action';
import { setSuccessMessage } from '../../../store/slices/success.slice';

export default function EditEmployeeData({ employee, status, setStatus }: { employee: IUser, status: string, setStatus: any }) {

    const dispatch = useAppDispatch();

    const [file, setFile] = useState(null)
    const [open, setOpen] = useState(false)
    const { role } = useAppSelector(s => s.user)


    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        const file = event.target.files[0]
        dispatch(uploadEmployeeImage({
            id: employee.id,
            file,
            employee
        })).unwrap()
            .then(() => {
                setFile(file)
                dispatch(setSuccessMessage("Image Uploaded"))
            })
    };

    const empRoles = role === "superadmin" ? [...Object.values(EmployeeRole), "admin"] : Object.values(EmployeeRole)
    function updateRole(role: string) {

        dispatch(updateUserType({ id: employee.id, user_type: role }))
            .unwrap()
            .then(() => {
                setStatus(role);
                setOpen(s => !s)
                dispatch(setSuccessMessage("Employee role updated successfully."))
            })
    }

    return (
        <Box
            component="div"
            sx={{
                backgroundColor: "#FFF",
                p: 3,
                border: "1px solid #D4E6FF",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "space-between"
            }}
        >

            <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
            }}>

                <label
                    className='cursor-pointer relative'
                >
                    <Avatar
                        sx={{
                            width: "95px",
                            height: "95px"
                        }}
                        alt={employee.name.toUpperCase()}
                        src={file ?
                            URL.createObjectURL(file) :
                            `${process.env.REACT_APP_SERVER_STORAGE_URL}/${employee.profile_pic}`
                        }
                    />

                    <Box
                        component="div"
                        sx={{
                            height: "25px",
                            width: "25px",
                            borderRadius: "50%",
                            backgroundColor: "#FFF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            bottom: "0",
                            right: "0"
                        }}
                    >
                        <BorderColorOutlinedIcon
                            sx={{
                                color: "#A01A1F",
                                fontSize: "16px"
                            }}
                        />
                    </Box>
                    <input type="file" hidden onChange={handleImageChange} />
                </label>

                <Box
                    component="div"
                >
                    <Typography
                        component="div"
                        fontSize={{ xs: "18px", md: "24px" }}
                        color="#202020"
                        lineHeight="30px"
                        mb={1}
                        fontWeight={600}
                    >
                        {employee.name}
                    </Typography>

                    <Box
                        sx={{
                            position: "relative"
                        }}
                    >
                        <Status
                            status={status}
                            editAble
                            onClick={() => setOpen(s => !s)}
                        />

                        {open && <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                border: "1px solid #D4E6FF",
                                borderRadius: "12px",
                                position: "absolute",
                                left: 3,
                                top: "40px",
                                backgroundColor: "#fff",
                                p: 1,
                                gap: 1
                            }}
                        >
                            {empRoles.map(role =>
                                <Status
                                    width='100%'
                                    status={role}
                                    selected={status}
                                    key={role}
                                    onClick={() => updateRole(role)}
                                />
                            )}
                        </Box>}
                    </Box>

                </Box>

            </Box>

        </Box>
    )
}
