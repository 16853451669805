import React from 'react'
import { CardProps } from './card.props'
import { Box, IconButton, ListItem, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { mobileBreakPoint } from '../../../utilities/constants';

export default function Card({ branch }: CardProps) {
    return (
        <Box
            component="div"
        >
            <Link to={`/branches/${branch.id}`}>
                <Box
                    component="div"
                    sx={{
                        mb: 2,
                        borderRadius: "12px",
                        overflow: "hidden",
                        height: "235px"
                    }}
                >
                    <img
                        src={branch?.location_img ?
                            `${process.env.REACT_APP_SERVER_STORAGE_URL}/${branch.location_img}` :
                            "/media/default.jpg"}
                        className='w-full h-full object-cover' />
                </Box>

                <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >

                    <Typography
                        color="#0C0C13"
                        fontWeight="500"
                        fontSize={{ xs: "16px", md: "18px" }}
                        lineHeight={{ xs: "16px", md: "18px" }}
                    >
                        {branch.name}
                    </Typography>

                    <IconButton
                        sx={{
                            color: "#A01A1F",
                            fontSize: "18px",
                            fontWeight: "500",
                            lineHeight: "18px",
                            [mobileBreakPoint]: {
                                fontSize: "14px",
                                lineHeight: "14px",
                            }
                        }}
                    >
                        <BorderColorOutlinedIcon
                            sx={{
                                mr: 1,
                                fontSize: "20px",
                                [mobileBreakPoint]: {
                                    fontSize: "16px"
                                }
                            }}
                        />
                        Edit
                    </IconButton>

                </Box>

                <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    mt={2}
                >

                    <Typography
                        color="#A01A1F"
                        fontSize={{ xs: "16px", md: "18px" }}
                        lineHeight={{ xs: "16px", md: "18px" }}
                        fontWeight="600"
                        mr={1}
                    >
                        {branch.orders_count || 0}
                    </Typography>

                    <Typography
                        color="#4A4F78"
                        fontWeight="400"
                        fontSize={{ xs: "16px", md: "18px" }}
                        lineHeight={{ xs: "16px", md: "18px" }}
                        mr={1}
                    >
                        Orders
                    </Typography>

                </Box>
            </Link>
        </Box>
    )
}
