import React from 'react'
import { PageHeaderProps } from './pageHeader.props'
import { Box, Typography } from '@mui/material'
import { mobileBreakPoint } from '../../utilities/constants'

function PageHeader({ title, content, className = "", caption }: PageHeaderProps) {
    return (
        <Box
            component="div"
            className={`${className}`}
        >
            <Box
                component="div"
                display="flex"
                alignItems="center"
            >

                <Typography
                    variant='h2'
                    component={"div"}
                    fontSize="24px"
                    color="#0C0C13"
                    fontWeight="600"
                    lineHeight="1.3"
                    sx={{
                        [mobileBreakPoint]: {
                            fontSize: "18px",
                            lineHeight: "21px"
                        }
                    }}

                >
                    {title}

                </Typography>
                {caption && <Typography
                    component="span"
                    sx={{
                        backgroundColor: "#A01A1F",
                        color: "#FFF",
                        p: "10px",
                        fontSize: "10px",
                        lineHeight: "11px",
                        borderRadius: "12px",
                        ml: 1,

                    }}
                >
                    {caption}
                </Typography>}

            </Box>

            {content && <Typography
                component={"p"}
                mt="12px"
                fontSize="14px"
                color="#797e96"
                lineHeight="1.7"
            >
                {content}
            </Typography>}

        </Box>
    )
}

export default PageHeader