import React, { useEffect, useState } from 'react'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import { Box, Grid } from '@mui/material'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { IBreadcrumbList } from '../../components/breadcrumb/breadcrumb.props'
import Button from '../../components/button/Button'
import AddIcon from '@mui/icons-material/Add';
import BranchesList from '../../components/branch/branches-list/BranchesList'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { mobileBreakPoint } from '../../utilities/constants'
import { useGetBranches } from '../../hooks/get-branches.hook'
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook'
import Pagination from '../../components/pagination/Pagination'
import SortDropDown from '../../components/filters/sort-dropdown/SortDropDown'
import { ISortMenu } from '../../components/filters/sort-dropdown/sort-drop-down.props'
import Spinner from '../../components/spinner/Spinner'

const breadcrumbList: IBreadcrumbList[] = [
    {
        type: "text",
        text: "Branches",
        path: "/locations/branches"
    }
];

const sortMenu: ISortMenu[] = [
    { label: "Sort By", sort_by: "---", sort: "---" },
    { label: "By Name", sort_by: "name", sort: "ASC" },
    { label: "Latest", sort_by: "created_at", sort: "DESC" }
]

export default function Branches() {

    // let query = "";
    const navigate = useNavigate()

    //setup redux options
    const dispatch = useAppDispatch()
    const { branches } = useAppSelector(s => s.branch);
    const [sort, setSort] = useState("---")
    const [query, setQuery] = useState(null)

    //get page param
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get("page") || '1', 10);

    //get orders
    const { isLoading, data, isFetching, refetch } = useGetBranches(dispatch, page, query)

    function handelChange(value: string) {
        const sorting = sortMenu.find(item => item.sort_by === value)
        const query = value !== "---" ? `sort_by=${value}&sort=${sorting.sort}` : null;

        setSort(value)

        setQuery(query)
    }

    useEffect(() => {

        if (query) {
            console.log("refetch...")
            refetch()
        }
    }, [query])

    return (
        <PageLayout>
            <Box
                component="div"
                sx={{
                    [mobileBreakPoint]: {
                        mt: "20px"
                    }
                }}
            >
                <Breadcrumb list={breadcrumbList} />

                <Button
                    onClick={() => navigate("/branches/add")}
                    width='200px'
                    className='ml-auto mt-4 md:mt-14 mb-4 md:mb-0'
                >
                    Add New Branch
                    <AddIcon sx={{
                        fontSize: "20px",
                        ml: 1
                    }} />
                </Button>
            </Box>


            <Grid container
                rowSpacing={{ xs: 2, md: 3 }}
                columnSpacing={3}
                mb={{ xs: 2, md: 0 }}
                mt={3}
            >
                <Grid item xs={12} md={4}>
                    <SortDropDown
                        label='Sort By'
                        onChange={handelChange}
                        menu={sortMenu}
                        value={sort}
                    />
                </Grid>
            </Grid>

            {isFetching ? <Spinner /> : <BranchesList branches={branches} />}

            <Pagination count={Math.ceil(data?.total / 9) || 1} page={page} />

        </PageLayout>
    )
}
