import { Grid } from '@mui/material'
import Card from '../card/Card'
import { ILocation } from '../../../interfaces/locations'



export default function BranchesList({ branches }: { branches: ILocation[] }) {
    return (
        <Grid
            container
            rowSpacing={{ xs: 2, md: 5 }}
            columnSpacing={3}
            mt={{ xs: 0, md: 3 }}>
            {branches.map(branch => <Grid
                key={branch.id}
                item
                xs={12}
                md={4}
            >
                <Card branch={branch} />
            </Grid>)}

        </Grid>
    )
}
