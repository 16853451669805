import * as React from 'react';
import Snackbar from '../snackbar/Snackbar';
import { useAppSelector } from '../../hooks/store.hook';


export default function Success() {

    const { isOpen, message } = useAppSelector(s => s.success);

    if (!isOpen) return null
    return <Snackbar type="success" messages={[message]} />
}
