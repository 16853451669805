import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { DropDownProps } from './drop-down.props'

export default function DropDown({
    label,
    value,
    menu,
    onChange,
    width = "100%"
}: DropDownProps) {
    return (
        <Box
            component="div"
            width={width}
            height="55px"
        >
            <FormControl
                fullWidth
                sx={{
                    height: "100%",
                    ".MuiInputBase-root": {
                        textTransform: "capitalize"
                    }
                }}
            >
                <InputLabel
                    shrink
                    id={`demo-simple-select-label-${label}`}
                    sx={{
                        color: "#8E92B9",
                        textTransform: "capitalize",
                        '&.MuiInputLabel-shrink': {
                            fontSize: "10px",

                        },
                        left: "3px",
                        top: "3px"
                    }}
                >
                    {label}
                </InputLabel>
                <Select
                    labelId={`demo-simple-select-label-${label}`}
                    id="demo-simple-select"
                    value={value}
                    label={label}
                    onChange={(e) => onChange(e.target.value)}
                    notched={true}
                    sx={{
                        fontSize: "17px",
                        color: "#4A4F78",
                        height: "100%",
                        pl: "10px",
                        "fieldset": {
                            borderColor: "gree#D7E7FA",
                            borderRadius: "12px",
                            "& legend": {
                                fontSize: "9px",
                            },
                        }

                    }}
                >
                    {menu.map((item, index) => <MenuItem
                        key={index}
                        value={item.value}
                        sx={{
                            fontSize: "12px",
                            color: "#191B29"
                        }}

                    >{item.label}
                    </MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    )
}
