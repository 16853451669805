import React from 'react'
import "./Spinner.css"
import { Box } from '@mui/material'
export default function Spinner() {
    return (
        <Box
            component="div"
            width="100%"
            display="flex"
            justifyContent="center"
            my={10}

        >
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </Box>

    )
}
