import React from 'react';
import { Box } from '@mui/material';
import style from "./Loading.module.css"

const Loading = () => {
    return (
        <div className={`${style.loading}`}>
            <div className={`${style.logo} flex justify-center`}>
                <img width="w-full" src="/media/logo.png" alt="logo" />
            </div>
            <Box
                className={`${style.spinner}`}
                sx={{
                    '& > div': {
                        backgroundColor: 'palette.secondary.main',
                    },
                }}
            >
                <div className={`${style["bounce-1"]}`} />
                <div className={`${style["bounce-2"]}`} />
                <div />
            </Box>
        </div>
    );
};

export default Loading;
