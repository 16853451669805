export function apiErrorFormat(error: any): any[] | any {
    if (Array.isArray(error?.response?.data?.message)) {
        return error.response.data.message
    }

    if (error?.response?.data?.message) {
        return [error.response.data.message]
    }

    if (error?.message) {
        return [error.message]
    }

    return []
}

export function setErrorFormat(error: any) {

    let err: any;

    if (error?.response?.data?.errors) {
        err = []
        const errors = error.response.data.errors


        Object.keys(errors).map(key => {
            err = [...err, `${key}: ${errors[key]}`]
        })
    } else {

        err = error?.response?.data?.message || error.message;
    }

    if (Array.isArray(err)) return err


    return [err]
}