import { Box, Stack } from '@mui/material'
import { mobileBreakPoint } from '../../../utilities/constants'

import { IOrder } from '../../../interfaces/order.interface'
import OrderCard from './OrderCard'


export default function MobileView({ orders }: { orders: IOrder[] }) {

    return (
        <Box
            component="div"
            sx={{
                display: "none",
                [mobileBreakPoint]: {
                    display: "block"
                }
            }}
        >

            <Stack
                spacing={1}
            >
                {orders.map(order => <OrderCard key={order.id} order={order} />)}

            </Stack>

        </Box>
    )
}
