import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../interfaces/user";
import { addEmployee, deleteEmployee, updateEmployee, updateUserType, uploadEmployeeImage } from "../thunk-actions/employee-action";


const initialState: {
    employees: IUser[],
    isLoading: boolean
} = {
    employees: [],
    isLoading: false
}

const slice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        setEmployees(state, action) {
            state.employees = action.payload;
        }
    },

    extraReducers: (builder) => {


        //add order
        builder.addCase(addEmployee.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(addEmployee.fulfilled, (state, action) => {
            state.isLoading = false;
            state.employees = [action.payload, ...state.employees];
        })
        builder.addCase(addEmployee.rejected, (state, action) => {
            state.isLoading = false;
        })

        //update orderStatus
        builder.addCase(updateUserType.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateUserType.fulfilled, (state, action) => {
            state.isLoading = false;
            state.employees = state.employees.map(user => {
                if (user.id !== action.payload.id) return user;

                return action.payload
            })
        })
        builder.addCase(updateUserType.rejected, (state, action) => {
            state.isLoading = false;
        })

        //update orderStatus
        builder.addCase(uploadEmployeeImage.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(uploadEmployeeImage.fulfilled, (state, action) => {
            state.isLoading = false;
            state.employees = state.employees.map(user => {
                if (user.id !== action.payload.id) return user

                return {
                    ...user,
                    profile_pic: action.payload.profile_pic
                }
            })
        })
        builder.addCase(uploadEmployeeImage.rejected, (state, action) => {
            state.isLoading = false;
        })

        //update client
        builder.addCase(updateEmployee.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateEmployee.fulfilled, (state, action) => {
            state.isLoading = false;
            state.employees = state.employees.map(user => {
                if (user.id !== action.payload.id) return user;

                return action.payload
            })
        })
        builder.addCase(updateEmployee.rejected, (state, action) => {
            state.isLoading = false;
        })

        //delete employee
        builder.addCase(deleteEmployee.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(deleteEmployee.fulfilled, (state, action) => {
            state.isLoading = false;
            state.employees = state.employees.filter(user => user.id !== action.payload)
        })
        builder.addCase(deleteEmployee.rejected, (state, action) => {
            state.isLoading = false;
        })

    }

})

export default slice.reducer;

const {
    setEmployees,
} = slice.actions;

export {
    setEmployees,
}