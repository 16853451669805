import React from 'react'
import { SettingMenuProps } from './setting-menu.props'
import { Menu, MenuItem, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../hooks/store.hook'
import { logout } from '../../../../store/slices/auth.slice'

export default function SettingMenu({ anchorEl, settings, onClick }: SettingMenuProps) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    function userLogout() {
        dispatch(logout());
        navigate("/login", { replace: true })
    }

    return (
        <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={onClick}
        >
            {settings.map((setting) => (
                <MenuItem key={setting.id} onClick={onClick}>
                    <Link to={setting.path}>
                        <Typography textAlign="center">{setting.text}</Typography>
                    </Link>
                </MenuItem>
            ))}

            <MenuItem onClick={userLogout}>
                <Typography textAlign="center">Logout</Typography>
            </MenuItem>
        </Menu>
    )
}
