import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import "./../../table/table.css"
import HeaderSort from "../../table/header-sort/HeaderSort";
import moment from "moment";
import ClientName from "../client-name/ClientName";



export const columns = (handelSorting: (value: string) => void, isLoading: boolean): GridColDef[] => ([
    {
        field: 'No.',
        headerName: 'id',
        width: 60,
        headerClassName: "table-header-col",
        cellClassName: "table-cell-col",
        renderCell: (param) => <Box component="div" color="#000" textAlign="center">{param.row.id}</Box>
    },
    {
        field: 'full_name',
        headerName: 'Name',
        width: 385,
        sortable: false,
        cellClassName: "table-cell-col",
        headerClassName: "table-header-col",
        renderCell: (param) => <ClientName client={param.row} />
    },
    {
        field: 'phone_number',
        headerName: 'Phone Number',
        width: 225,
        hideable: true,
        sortable: false,
        headerClassName: "table-header-col",
        cellClassName: "table-cell-col",
        renderCell: (params) => (
            <Box
                color="#000"
                component="span"
            >
                {params.row.phone_number}
            </Box>
        ),
    },

    {
        field: 'created_at',
        headerName: 'Added Date',
        width: 220,
        headerClassName: "table-header-col-sort",
        cellClassName: "table-cell-col",
        renderHeader: (params) => <HeaderSort
            text={params.colDef.field}
            handelSorting={handelSorting}
            isLoading={isLoading}
            title={params.colDef.headerName}
        />,
        sortable: false,
        renderCell: (params) => (
            <Box
                component="span"
                maxWidth="100%"
                whiteSpace="pre-wrap"
            >
                {moment(params.row.created_at).format("DD/MM/YYYY")}
            </Box>
        ),
    },

    {
        field: 'completed_orders',
        headerName: 'completed',
        width: 150,
        headerClassName: "table-header-col-sort",
        cellClassName: "table-cell-col",
        renderHeader: (params) => <HeaderSort
            text={params.colDef.field}
            handelSorting={handelSorting}
            isLoading={isLoading}
            title={params.colDef.headerName}
        />,
        sortable: false,
        renderCell: (params) => (
            <Box
                component="div"
                sx={{
                    padding: "11px",
                    backgroundColor: "rgba(92, 190, 67, 0.2)",
                    color: "#5CBE43",
                    fontSize: "14px",
                    borderRadius: "12px"
                }}
            >
                {params.row?.completed_orders || 0}
            </Box>
        ),
    },

])