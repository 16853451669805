import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "./../../utilities/api"
import { IOrder } from "../../interfaces/order.interface";
import { OrderStatus } from "../../utilities/constants";



//update user
export const updateOrderStatus = createAsyncThunk<
    IOrder,
    { id: number, status: string },
    {
        rejectValue: unknown
    }
>("orders/updateOrderStatus", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.updateOrderStatus(payload.id, payload.status)
        return data.order;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

//update user
export const updateOrder = createAsyncThunk<
    IOrder,
    { id: number, data: Partial<IOrder> },
    {
        rejectValue: unknown
    }
>("orders/updateOrder", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.updateOrder(payload.id, payload.data)
        return data.order;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

//add order
export const addOrder = createAsyncThunk<
    IOrder,
    IOrder,
    {
        rejectValue: unknown
    }
>("orders/addOrder", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.addOrder(payload)

        console.log("order = ", data)
        return data.order;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})
