import React from 'react'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import { Box } from '@mui/material'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { IBreadcrumbList } from '../../components/breadcrumb/breadcrumb.props'
import AddEmployeeForm from '../../forms/AddEmployeeForm'
import BackArrow from '../../components/back-arrow/BackArrow'

const breadcrumbList: IBreadcrumbList[] = [
    {
        type: "link",
        text: "Employees",
        path: "/employees"
    },
    {
        type: "text",
        text: "Add new employee",
        path: "/locations/employees/add"
    }
]

export default function AddEmployee() {
    return (
        <PageLayout>
            <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                className='mt-4 md:mt-0'
            >
                <BackArrow />
                <Breadcrumb list={breadcrumbList} />
            </Box>

            <AddEmployeeForm />


        </PageLayout>
    )
}
