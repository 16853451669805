import { useState } from 'react'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import ReportsList from '../../components/reports/reports-list/ReportsList'
import PageHeader from '../../components/page-header/PageHeader'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ReportFilter from '../../components/reports/report-filter/ReportFilter'
import Pagination from '../../components/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook'
import { useGetOrders } from '../../hooks/get-orders.hook'
import Spinner from '../../components/spinner/Spinner'
import { ISorting } from '../../interfaces/sorting.interface'
import { updateQueryStringParameter } from '../../utilities/helper'
import usePusher from '../../hooks/usePusher'

export default function StatusReports() {

    //setup redux options
    const dispatch = useAppDispatch()
    const { orders } = useAppSelector(s => s.order);
    const navigate = useNavigate()

    //get order status
    const { status } = useParams();

    //get page param
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get("page") || '1', 10);

    //set query
    const [query, setQuery] = useState("");

    const [sorting, setSorting] = useState<ISorting>({
        sort_by: "",
        sort: "ASC"
    });

    function handelSorting(value: string) {
        setSorting(s => ({
            sort_by: value,
            sort: s.sort === "ASC" ? "DESC" : "ASC",
        }))

        setQuery(s => {
            let updatedQuery = s || '';
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort_by', value);
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort', sorting.sort);
            return updatedQuery;
        });
        navigate("")
    }




    //get orders
    const { isLoading, data, isFetching } = useGetOrders(dispatch, status, page, query)

    return (
        <PageLayout>
            <PageHeader
                title={`${status} Orders`}
                caption={`${data?.total || 0} Orders`}
                className='mb-6 capitalize'
            />

            <ReportFilter query={query} updateFilter={(query) => setQuery(query)} isAll={false} />


            {/* {isLoading ? <Spinner /> : <>
                <ReportsList
                    handelSorting={handelSorting}
                    isLoading={isFetching}
                    isAll={false}
                    reports={status === "done" ?
                        orders.filter(item => item.status === "done")
                        : orders.filter(item => item.status !== "done")}
                />
                <Pagination count={Math.ceil(data?.all?.total / 10) || 1} page={page} />
            </>} */}


            <ReportsList
                handelSorting={handelSorting}
                isLoading={isLoading || isFetching}
                isAll={false}
                reports={isLoading || isFetching ? [] : orders}
            />
            <Pagination count={Math.ceil(data?.total / 10) || 1} page={page} />




        </PageLayout>
    )
}
