import React, { useEffect, useState } from 'react';
import { TextInputProps } from '../text-input/text-input.props';
import { Box, Button } from '@mui/material';
import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { useAppDispatch } from '../../../hooks/store.hook';
import { setErrorMessages } from '../../../store/slices/error.slice';
import { getClients } from '../../../utilities/api';
import { IClient } from '../../../interfaces/client.interface';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

const filter = createFilterOptions<any>();

interface AutoCompleatProps extends TextInputProps {
    setValue: any
}

export default function ClientAutoCompleat({
    control,
    error,
    label,
    placeholder = "",
    width = "100%",
    className = "",
    isDisabled = false,
    mask = "",
    setValue
}: AutoCompleatProps) {


    // const [isLoading , setLoading] = useState(false)
    const dispatch = useAppDispatch()

    const [clients, setClients] = useState<IClient[]>([])
    const [defaultClient, setClient] = useState(null)
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('+971');

    const options = clients.map((item) => ({
        value: item.phone_number,
        label: `${item.phone_number}`,
    }))

    const loadOptions = async () => {
        setLoading(true)
        try {
            const { data } = await getClients(`phone_number=${inputValue}`);
            setClients(data.data)
        } catch (error) {
            dispatch(setErrorMessages(error))
        } finally {
            setLoading(false)
        }
    };

    const handleChange = (e: any, value: any) => {
        if (!value) return
        const selectedClient = clients.find(client => client.phone_number === value.value);


        if (selectedClient) {
            setClient(selectedClient);
            setValue("full_name", selectedClient.full_name);
        } else {
            setValue("full_name", "");
        }
    }

    const handleInputChange = (e: any, value: any) => {
        if (!value.startsWith('+971')) return;
        setInputValue(value);

    };

    const handleAddOption = () => {
        // Implement the action to add the new option here
        setInputValue('');
    };

    useEffect(() => {
        if (!inputValue || inputValue === "+971") return;
        const debounce = setTimeout(loadOptions, 250)
        return () => clearTimeout(debounce)
    }, [inputValue])

    return (
        <Box className={`${className}`} component="div" width={width}>
            <Controller
                name="phone_number"
                control={control}
                defaultValue={defaultClient}
                render={({ field }) => (
                    <Autocomplete
                        freeSolo
                        id="combo-box-demo"
                        options={options}
                        disableClearable
                        onChange={(e, value) => {
                            handleChange(e, value);
                        }}
                        loading={loading}

                        onInputChange={(e, value) => {
                            handleInputChange(e, value);
                            field.onChange(value);
                        }}


                        // filterOptions={(options, params) => {
                        //     const filtered = filter(options, params);

                        //     const { inputValue } = params;
                        //     // Suggest the creation of a new value
                        //     const isExisting = options.some((option) => inputValue === option.value);
                        //     if (inputValue !== '' && !isExisting) {
                        //         filtered.push({
                        //             value: inputValue,
                        //             label: `Add ${inputValue}`,
                        //         });
                        //     }

                        //     return filtered;
                        // }}

                        // getOptionLabel={(option) => {
                        //     // Value selected with enter, right from the input
                        //     if (typeof option === 'string') {
                        //         return option;
                        //     }
                        //     // Add "xxx" option created dynamically
                        //     if (option.value) {
                        //         return option.value;
                        //     }
                        //     // Regular option
                        //     return option.label;
                        // }}

                        // filterOptions={(options, params) => {
                        //     const filtered = filter(options, params);

                        //     const { inputValue } = params;
                        //     // Suggest the creation of a new value
                        //     const isExisting = options.some((option) => inputValue === option.value);
                        //     if (inputValue !== '' && !isExisting) {
                        //         filtered.push({
                        //             inputValue,
                        //             title: `Add "${inputValue}"`,
                        //         });
                        //     }

                        //     return filtered;
                        // }}
                        inputValue={inputValue}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                                placeholder={placeholder}
                                fullWidth
                                error={!!error}
                                helperText={error}
                                disabled={isDisabled}
                                value={field.value}
                                sx={{
                                    "fieldset": {
                                        borderColor: "#D7E7FA",
                                        borderRadius: "12px"
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset>legend": {
                                            fontSize: "8px",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    sx: {
                                        color: "#8E92B9",
                                        '&.MuiInputLabel-shrink': {
                                            fontSize: "10px",
                                        },
                                        left: "3px",
                                        top: "3px"
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                )}
            />
        </Box>
    )
}
