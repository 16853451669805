import { Box } from '@mui/material'
import Table from '../../table/Table'
import { columns } from './columns'
import MobileView from './MobileView'
import { IClient } from '../../../interfaces/client.interface'
import Spinner from '../../spinner/Spinner'


export default function ClientsList({ clients, isLoading, handelSorting }: {
    clients: IClient[],
    handelSorting: (value: string) => void,
    isLoading: boolean
}) {


    return (
        <Box
            component="div"
        >
            <Table
                columns={columns(handelSorting, isLoading)}
                rows={clients}
                columnVisibilityModel={{ id: false }}
                isLoading={isLoading}
            />

            {isLoading ? <Box display={{ md: "none" }}> <Spinner /></Box> : <MobileView clients={clients} />}

        </Box>
    )
}
