import * as React from 'react';
import Snackbar from '../snackbar/Snackbar';
import { useAppSelector } from '../../hooks/store.hook';
import { Stack } from '@mui/material';


export default function Errors() {

    const { isOpen, messages } = useAppSelector(s => s.errors);

    if (!isOpen) return null
    return <Snackbar type='error' messages={messages} />
}
