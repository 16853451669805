import { createSlice } from "@reduxjs/toolkit";


const initialState: { message: string, isOpen: boolean } = {
    isOpen: false,
    message: ""
}

const slice = createSlice({
    name: "success",
    initialState,
    reducers: {
        setSuccessMessage(state, action) {
            state.isOpen = true;
            state.message = action.payload
        },
        clearSuccessMessage(state) {
            state.isOpen = false
            state.message = ""
        },

    },
})

export default slice.reducer;

const {
    setSuccessMessage,
    clearSuccessMessage
} = slice.actions;

export {
    setSuccessMessage,
    clearSuccessMessage
}