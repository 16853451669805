import { Box } from '@mui/material'
import Table from '../../table/Table'
import { columns } from './columns'
import Pagination from '../../pagination/Pagination'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MobileView from './MobileView'
import { IUser } from '../../../interfaces/user'
import Spinner from '../../spinner/Spinner'


export default function EmployeeList({ employees, handelSorting, isLoading }: {
    employees: IUser[],
    handelSorting: (value: string) => void,
    isLoading: boolean
}) {


    const navigate = useNavigate()

    function navigateToUser(id: number) {
        navigate(`/employees/${id}`)
    }

    return (
        <Box
            component="div"
            mt={3}
        >

            <Table
                columns={columns(navigateToUser, handelSorting, isLoading)}
                rows={employees}
                columnVisibilityModel={{ id: false }}
                isLoading={isLoading}
            />

            {isLoading ? <Box display={{ md: "none" }}> <Spinner /></Box> : <MobileView employees={employees} />}
        </Box>
    )
}
