import { createSlice } from "@reduxjs/toolkit";
import { IOrder } from "../../interfaces/order.interface";
import { addOrder, updateOrder, updateOrderStatus } from "../thunk-actions/order-action";
import { OrderStatus } from "../../utilities/constants";
import { number } from "yup";


const initialState: {
    orders: IOrder[],
    page: number,
    limit: number,
    isLoading: boolean,
    doneCount: number,
    processingCount: number
} = {
    orders: [],
    page: 1,
    limit: 10,
    doneCount: 0,
    processingCount: 0,
    isLoading: false
}

const slice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        setOrders(state, action) {
            state.orders = action.payload;
        },

        setDoneOrdersCount(state, { payload }) {
            state.doneCount = payload
        },

        setProcessingOrderCount(state, { payload }) {
            state.processingCount = payload
        },

        removeDoneOrdersCount(state, { payload }) {
            state.doneCount -= payload
        },

        removeProcessingOrderCount(state, { payload }) {
            state.processingCount -= payload
        },

        addDoneOrdersCount(state, { payload }) {
            state.doneCount += payload
        },

        addProcessingOrderCount(state, { payload }) {
            state.processingCount += payload
        },

        updateOrders(state, { payload }) {
            state.orders = state.orders.map(order => {
                if (order.id !== payload.order.id) return order;

                return {
                    ...order,
                    status: payload.status
                }
            })
        },

        addRealTimeOrder(state, { payload }) {

            const order = state.orders.find(item => item.id === payload.is)

            if (order) return;
            state.orders.unshift(payload)

            if (state.orders.length > 10)
                state.orders.pop()
        },

        updateRealTimeOrders(state, { payload }) {

            state.orders = state.orders.map(order => {
                if (order.id !== payload.id) return order;

                return {
                    ...order,
                    ...payload
                }
            })
        },


        removeRealTimeOrders(state, { payload }) {

            state.orders = state.orders.filter(order => order.id !== payload.id)
        },


        setOrderPage(state, action) {
            state.page = action.payload
        },

        restOrders(state) {
            state = initialState
        }
    },

    extraReducers: (builder) => {

        //update orderStatus
        builder.addCase(updateOrderStatus.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orders = state.orders
                .map(order => {
                    if (order.id !== action.payload.id) return order;

                    return {
                        ...order,
                        ...action.payload
                    }
                }).filter(order => ![OrderStatus.DONE, OrderStatus.CANCELED].includes(order.status))
        })
        builder.addCase(updateOrderStatus.rejected, (state, action) => {
            state.isLoading = false;
        })

        //add order
        builder.addCase(addOrder.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(addOrder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orders = [action.payload, ...state.orders];
        })
        builder.addCase(addOrder.rejected, (state, action) => {
            state.isLoading = false;
        })

        //update client
        builder.addCase(updateOrder.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateOrder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orders = state.orders.map(order => {
                if (order.id !== action.payload.id) return order;

                return {
                    ...order,
                    ...action.payload
                }
            })
        })
        builder.addCase(updateOrder.rejected, (state, action) => {
            state.isLoading = false;
        })
    }
})

export default slice.reducer;

const {
    setOrders,
    restOrders,
    setOrderPage,
    updateOrders,
    addRealTimeOrder,
    updateRealTimeOrders,
    removeRealTimeOrders,
    setDoneOrdersCount,
    setProcessingOrderCount,
    removeDoneOrdersCount,
    removeProcessingOrderCount,
    addDoneOrdersCount,
    addProcessingOrderCount
} = slice.actions;

export {
    setOrders,
    restOrders,
    setOrderPage,
    updateOrders,
    addRealTimeOrder,
    updateRealTimeOrders,
    removeRealTimeOrders,
    setDoneOrdersCount,
    setProcessingOrderCount,
    removeDoneOrdersCount,
    removeProcessingOrderCount,
    addDoneOrdersCount,
    addProcessingOrderCount
}