import { employeeImage } from './../../utilities/api';
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../utilities/api"
import { IUser } from '../../interfaces/user';


//update user type
export const updateUserType = createAsyncThunk<
    IUser,
    { id: number, user_type: string },
    {
        rejectValue: unknown
    }
>("employee/updateUserType", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.updateUserType(payload.id, payload.user_type)
        return data.Employee;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

//update user type
export const uploadEmployeeImage = createAsyncThunk<
    IUser,
    { id: number, file: any, employee: IUser },
    {
        rejectValue: unknown
    }
>("employee/uploadEmployeeImage", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {

        const formData = new FormData();

        formData.append("profile_pic", payload.file);

        const { data } = await api.employeeImage(payload.id, formData);

        console.log("image", data)
        return {
            ...payload.employee,
            profile_pic: data.profile_pic
        };
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


//update user
export const updateEmployee = createAsyncThunk<
    IUser,
    { id: number, data: Partial<IUser> },
    {
        rejectValue: unknown
    }
>("employee/updateEmployee", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.updateEmployee(payload.id, payload.data)
        return data.Employee;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

//add branch
export const addEmployee = createAsyncThunk<
    IUser,
    IUser,
    {
        rejectValue: unknown
    }
>("employee/addEmployee", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {

        const { data } = await api.addEmployees(payload)

        return data.Employee;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

//add branch
export const deleteEmployee = createAsyncThunk<
    number,
    number,
    {
        rejectValue: unknown
    }
>("employee/deleteEmployee", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {

        const { data } = await api.deleteEmployee(payload)

        return payload;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

