import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { NavProps } from './nav.props';
import { Typography } from '@mui/material';
import SettingMenu from './setting-menu/SettingMenu';
import SortIcon from '@mui/icons-material/Sort';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAppSelector } from '../../../hooks/store.hook';
import { mobileBreakPoint } from '../../../utilities/constants';

const settings = [
    {
        id: "profile",
        text: "Profile",
        path: "/profile"
    }
];

function Nav({ open, onClick }: NavProps) {

    const { user } = useAppSelector(s => s.user)

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);


    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };



    return (
        <Box
            component="nav"
            width="100%"
            height="96px"
            display="flex"
            alignItems="center"
            sx={{
                [mobileBreakPoint]: {
                    height: "68px"
                }
            }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    paddingX: "0 !important",
                    backgroundColor: "#fff"
                }}>
                <Toolbar
                    disableGutters
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <IconButton
                        onClick={onClick}
                        sx={{
                            padding: 0,
                            pl: open ? "24px" : "0"
                        }}
                    >
                        {!open ?
                            <SortIcon style={{ color: "A01A1F" }} /> :
                            <ArrowForwardIcon
                                style={{ color: "A01A1F" }}
                                sx={{
                                    [mobileBreakPoint]: {
                                        display: "none"
                                    }
                                }}
                            />
                        }
                    </IconButton>

                    <Box sx={{ flexGrow: 0, display: "flex", gap: 2 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar
                                    sx={{
                                        width: "35px",
                                        height: "35px"
                                    }}
                                    alt={user?.name.toUpperCase()}
                                    src={`${process.env.REACT_APP_SERVER_STORAGE_URL}/${user?.profile_pic}`}
                                />
                                <Typography
                                    component="span"
                                    ml={1}
                                    fontSize="12px"
                                    color="#475569"
                                    sx={{
                                        textTransform: "capitalize",
                                        [mobileBreakPoint]: {
                                            display: "none"
                                        }
                                    }}
                                >
                                    {user?.name}
                                </Typography>
                            </IconButton>
                        </Tooltip>
                        <SettingMenu
                            anchorEl={anchorElUser}
                            settings={settings}
                            onClick={handleCloseUserMenu} />
                    </Box>
                </Toolbar>
            </Container>
        </Box >

    );
}
export default Nav;
