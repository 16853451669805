import Layout from '../layouts/layout/Layout';

export default function Root() {

    // const { pathname } = useLocation()
    // const [layout, setLayout] = useState<LayoutType>(null)
    // useEffect(() => {



    //     let layout: LayoutConfig = null

    //     if (pathname === "/") {
    //         layout = { layout: LayoutType.FIRST_LAYOUT, path: "" }

    //     } else {
    //         layout = layoutConfig.find((item, index) => pathname.includes(item.path) && pathname !== "/"
    //         );
    //     }

    //     if (!layout) {
    //         console.log(",,,")
    //         setLayout(LayoutType.SECOND_LAYOUT)
    //         document.title = 'Zana - 404'
    //         return
    //     }

    //     setLayout(layout.layout)
    //     document.title = `Zana - ${getPageTile(pathname)}`


    // }, [pathname]);


    return (
        <Layout />
    )

}