import { useEffect, useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import TextArea from '../components/form/text-area-input/TextArea';
import { IOrder } from '../interfaces/order.interface';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { EmployeeRole } from '../interfaces/employees.interface';
import { updateOrder } from '../store/thunk-actions/order-action';
import { toggleSuccessModel } from '../store/slices/model.slice';
import { OrderStatus, phonePlaceHolder } from '../utilities/constants';

const schema = yup.object({
    full_name: yup.string().required("Full name is required"),
    phone_number: yup.string().optional(),
    order_details: yup.string().required("Message is required"),
}).required();


export default function EditOrderForm({ order }: { order: IOrder }) {


    const dispatch = useAppDispatch()

    const { user: { user }, order: { isLoading } } = useAppSelector(s => s)

    const { role } = useAppSelector(s => s.user)

    const [status, setStatus] = useState(order.status)

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid } } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            full_name: order.full_name,
            phone_number: order.phone_number,
            order_details: order.order_details,
            created_at: moment(order.created_at).format("DD/MM/YYYY hh:mm A"),
            location: user.location
        }
    });

    useEffect(() => {
        setStatus(order.status)
        setValue("order_details", order.order_details)
    }, [order])
    const onSubmit = (data: any) => {
        const payload = {
            ...data,
            created_at: moment(order.created_at).format('YYYY-MM-DD HH:mm:ss')
        }

        dispatch(updateOrder({ id: order.id, data: payload }))
            .unwrap()
            .then(() => {
                dispatch(toggleSuccessModel())
                if (status !== OrderStatus.NEW)
                    setStatus(OrderStatus.UPDATED)
            })
    }

    return (
        <FormLayout
            orderId={order.id}
            title={<div>
                Ticket No <Box component="span" color="#A01A1F">
                    #{order.location_prefix}-{order.ticket_number}
                </Box>
            </div>}
            onSubmit={handleSubmit(onSubmit)}
            withPrint={true}
            printEvent={() => alert("print....")}
            status={status}
            button={{
                isDirty,
                loading: isLoading,
                isValid,
                text: "Place Order",
                isDisabled: role === EmployeeRole.KITCHEN || OrderStatus.DONE === order.status
            }}

        >

            <Stack spacing={{ md: 4, xs: 3 }} mb={{ xs: 3, md: 4 }}>

                {role !== EmployeeRole.KITCHEN && <>
                    <TextInput
                        control={control}
                        name={"full_name"}
                        error={errors.full_name?.message}
                        label={"Full name"}
                        placeholder='Full name'
                        isDisabled={true}
                    />

                    <TextInput
                        control={control}
                        name={"phone_number"}
                        error={errors.phone_number?.message}
                        label={"Phone number"}
                        placeholder={phonePlaceHolder}
                        isDisabled={true}
                    />
                </>
                }

                <TextInput
                    control={control}
                    name={"created_at"}
                    error={""}
                    label={"Created Date"}
                    isDisabled={true}
                />

                <TextArea
                    control={control}
                    name={"order_details"}
                    error={errors.order_details?.message}
                    label={"Order Details"}
                    placeholder="Order details"
                    isDisabled={role === EmployeeRole.KITCHEN || [OrderStatus.DONE, OrderStatus.READY_TO_PICKUP].includes(order.status)}
                />
            </Stack>

        </FormLayout>
    )
}


