import React from 'react'
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { useForm, Controller } from "react-hook-form";
import { SelectInputProps } from './select-input.props';
import { EmployeeRole } from '../../../interfaces/employees.interface';

export default function TextInput({
    control,
    name,
    error,
    label,
    width = "100%",
    className = "",
    menu,
}: SelectInputProps) {
    return (
        <Box
            className={`${className}`}
            component="div"
            width={width}
        >

            <FormControl
                fullWidth
                sx={{
                    height: "100%",
                    ".MuiInputBase-root": {
                        textTransform: "capitalize"
                    }
                }}
                error={error ? true : false}
            >
                <InputLabel
                    shrink
                    id={`demo-simple-select-label-${label}`}
                    sx={{
                        color: "#8E92B9",
                        textTransform: "capitalize",
                        '&.MuiInputLabel-shrink': {
                            fontSize: "10px",

                        },
                        left: "3px",
                        top: "3px"
                    }}
                >
                    {label}
                </InputLabel>
                <Controller
                    render={({ formState, field }) =>
                        <>
                            <Select
                                labelId={`demo-simple-select-label-${label}`}
                                id="demo-simple-select"
                                label={label}
                                // onChange={(e) => onChange(e.target.value)}
                                notched={true}
                                sx={{
                                    fontSize: "17px",
                                    color: "#4A4F78",
                                    height: "100%",
                                    pl: "10px",
                                    "fieldset": {
                                        borderColor: "gree#D7E7FA",
                                        borderRadius: "12px",
                                        "& legend": {
                                            fontSize: "9px",
                                        },
                                    }

                                }}
                                {...field}
                            >
                                {menu.map((item, index) => <MenuItem
                                    key={index}
                                    value={item.value}
                                    sx={{
                                        fontSize: "12px",
                                        color: "#191B29",
                                        textTransform: "capitalize"
                                    }}

                                >{item.label}
                                </MenuItem>)}
                            </Select>

                            {
                                error && <FormHelperText>{error}</FormHelperText>
                            }

                        </>

                    }
                    name={name}
                    control={control}
                    defaultValue={EmployeeRole.KITCHEN}
                />

            </FormControl>
        </Box>
    )
}


{/* <TextField
error={error !== ""}
helperText={error}
placeholder={placeholder}
sx={{
    display: "block",
    mb: 3,
    py: 0,
    ".MuiOutlinedInput-input": {
        textTransform: capitalize ? "capitalize" : "none"
    }
}}
variant="outlined"
type={buttonType}
fullWidth
{...field}

className={`${error ? style["error-input"] : ""}`}
/> */}