import { Stack } from '@mui/material'
import Status from '../../status/Status'
import { OrderStatusProps } from './order-status.props'
import { OrderStatus } from '../../../utilities/constants'
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import { IOrder } from '../../../interfaces/order.interface'
import { EmployeeRole } from '../../../interfaces/employees.interface'
import { updateOrderStatus } from '../../../store/thunk-actions/order-action'
import { setSuccessMessage } from '../../../store/slices/success.slice'
import { filterStatus } from './OrderStatusList'

export default function StatusList({ order }: OrderStatusProps) {

    const { user: { role } } = useAppSelector(s => s);


    const dispatch = useAppDispatch()

    function onclick(order: IOrder, status: string) {


        dispatch(updateOrderStatus({ id: order.id, status })).unwrap().then(() => dispatch(setSuccessMessage("Order status updated successfully.")))
    }

    const statusArr = filterStatus(role, order.status)
        .filter(item => {
            if (role === EmployeeRole.KITCHEN) {
                return item !== order.status && ![OrderStatus.DONE, OrderStatus.NEW, OrderStatus.CANCELED].includes(item)
            } else if (role === EmployeeRole.salesman) {
                return item !== order.status && ![OrderStatus.PREPARING, OrderStatus.READY_TO_PICKUP, OrderStatus.NEW].includes(item)
            } else {
                return item !== order.status && ![OrderStatus.NEW].includes(item)
            }
        })

    if (role === EmployeeRole.KITCHEN && order.status === OrderStatus.DONE)
        return null;
    return (
        <Stack
            spacing={1}
            sx={{
                backgroundColor: "#FFF",
                borderRadius: "12px",
                border: "1px solid #D4E6FF",
                padding: 1,
                marginTop: "10px",
                position: "absolute",
                top: {
                    md: role ===
                        EmployeeRole.KITCHEN ? "3px" :
                        EmployeeRole.salesman ? "-25px" :
                            EmployeeRole.SUPERVISOR ? "-50px" : "0",
                    xs: "10px"
                },
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 999,
                display: statusArr.length > 0 ? "flex" : "none"
            }}
        >
            {statusArr.map(status => <Status
                status={status}
                width='150px'
                onClick={() => onclick(order, status)}
                selected={order.status}
                key={status}
            />)}
        </Stack>
    )
}
