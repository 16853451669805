import React from 'react'
import LoginForm from '../../forms/LoginForm'
import ForgetPasswordSendEmail from '../../forms/ForgetPasswordSendEmail'
import { useSearchParams } from 'react-router-dom';
import RestPasswordForm from '../../forms/RestPasswordForm';

export default function ForgetPassword() {

    //get page param
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token")

    return (
        <>
            {token ? <RestPasswordForm token={token} /> : <ForgetPasswordSendEmail />}
        </>
    )
}
