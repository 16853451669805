import axios from "axios";
import { ILoginCredentials } from "../interfaces/auth.interface";
import { OrderStatus } from "./constants";
import { IClient } from "../interfaces/client.interface";
import { IOrder } from "../interfaces/order.interface";
import { ILocation } from "../interfaces/locations";
import { IUser } from "../interfaces/user";

const baseURL: string = process.env.REACT_APP_API_URL;
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const API = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
        Accept: "application/json"
    }
});

API.interceptors.request.use((req: any) => {

    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")!) : null
    if (token) {
        req.headers.Authorization = `Bearer ${token}`;
    } else {
        req.headers.Authorization = `Bearer `;
    }
    return req;
});


let config = {
    headers: { "Content-Type": "multipart/form-data" }
};

let authConfig = {
    headers: { crossDomain: true, 'Content-Type': 'application/json' },
    withCredentials: true
}
//auth
export const login = (data: ILoginCredentials) => API.post("/login", data)

//locations
export const getLocations = (query: string) => API.get(`/locations?${query}`)
export const getLocationById = (id: number) => API.get(`/locations/${id}`)
export const updateLocation = (id: number, data: any) => API.post(`/locations/${id}`, data, config)
export const addLocation = (data: any) => API.post(`/locations`, data, config)

//password
export const sendResetPasswordEmail = (data: { email: string }) => API.post("/passwords/send-link", data)

export const passwordReset = (data: {
    token: string,
    password: string,
    confirmPassword: string
}) => API.post("/passwords/rest-password", data)

//user
export const getUser = () => API.get("/user")
export const updateUser = (data: any) => API.put("/users", data)
export const updateProfile = (data: any, id: number) => API.post(`employees/update/${id}`, data)

//companies
export const updateCompanyName = (data: Partial<any>) => API.post("/companies", data)
export const uploadCompanyLogo = (data: any) => API.post("/companies/logo", data, config)

//orders
export const getOrders = (query: string) => API.get(`/orders?${query}`)
export const updateOrderStatus = (id: number, status: string) => API.patch(`/orders/${id}`, { status })
export const updateOrder = (id: number, data: Partial<IOrder>) => API.put(`/orders/${id}`, data)
export const getOrderById = (id: number) => API.get(`/orders/${id}`)
export const addOrder = (data: Partial<IOrder>) => API.post("/orders", data)

//employees
export const getEmployees = (query: string) => API.get(`/employees?${query}`)
export const addEmployees = (data: Partial<IUser>) => API.post(`/register`, data)
export const updateUserType = (id: number, user_type: string) => API.patch(`/employees/${id}`, { user_type })
export const updateEmployee = (id: number, data: Partial<IOrder>) => API.put(`/employees/${id}`, data)
export const getEmployeeById = (id: number) => API.get(`/employees/${id}`)
export const deleteEmployee = (id: number) => API.delete(`/employees/${id}`)
export const employeeImage = (id: number, data: any) => API.post(`/update-profile-picture/${id}`, data, config)



//clients
export const getClients = (query: string) => API.get(`/clients?${query}`)
export const updateClient = (id: number, data: Partial<IClient>) => API.put(`/clients/${id}`, data)
export const getClientById = (id: number) => API.get(`/clients/${id}`)


//password 
export const changePassword = (data: any) => API.post("/user/password", data)
export const sendForgetPasswordEmail = (data: any) => API.post("/forgot-password", data)
export const resetPassword = (data: any, token: string) => API.post(`/reset-password?token=${token}`, data)

//invoice
export const getInvoice = (id: number) => API.get(`/orders/print/${id}`)

// export const uploadUserImage = (data: any) => API.post("/users/image", data, config)

// export const deleteUserImage = () => API.delete("/users/image")


