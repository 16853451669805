// Invoice.tsx
import React, { forwardRef } from 'react';
import { IOrder } from '../../interfaces/order.interface';
import moment from 'moment';
import { EmployeeRole } from '../../interfaces/employees.interface';

interface InvoiceItem {
    description: string;
    quantity: number;
    price: number;
}

interface InvoiceProps {
    salesman: string;
    Order: IOrder,
    "orders ahead": string,
    role: string
}

const Invoice = forwardRef<HTMLDivElement, InvoiceProps>((props, ref) => (
    <div ref={ref} style={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f8f8f8' }}>
        <div style={{
            width: '80%',
            margin: '50px auto',
            padding: '20px',
            backgroundColor: '#fff',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        }}>
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <img
                    src="/media/logo.png"
                    alt="Company Logo"
                    style={{ maxWidth: '100px', marginBottom: '20px', margin: "auto" }} />
            </div>
            <div style={{ marginBottom: '20px' }}>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ fontWeight: 'bold' }}>Date: </span>
                    {moment(props.Order.created_at).format("DD/MM/YYYY hh:mm A")}
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <span style={{ fontWeight: 'bold' }}>Ticket Number: </span>
                    {`#${props.Order?.location_prefix}-${props.Order.ticket_number}`}
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <span style={{ fontWeight: 'bold' }}>Waiting Orders: </span>
                    {props["orders ahead"]}
                </div>

                {props.role !== EmployeeRole.KITCHEN && <>
                    <div style={{ marginBottom: '10px' }}>
                        <span style={{ fontWeight: 'bold' }}>Branch Name: </span>
                        <span className='capitalize'>{props.Order?.location_name}</span>
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <span style={{ fontWeight: 'bold' }}>City: </span>
                        <span className='capitalize'>{props.Order?.city}</span>
                    </div>
                </>}


                <div style={{ marginBottom: '10px' }}>
                    <span style={{ fontWeight: 'bold' }}>Sales Name: </span>
                    <span className='capitalize'>{props.salesman}</span>
                </div>
                {
                    props.role !== EmployeeRole.KITCHEN && <>

                        <div style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Client Name: </span>
                            <span className='capitalize'> {props.Order.full_name}</span>
                        </div>

                        <div style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Client Phone: </span>
                            {props.Order.phone_number}
                        </div>

                    </>
                }
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ fontWeight: 'bold' }}>Order Details: </span>
                    {props.Order.order_details}
                </div>
            </div>

        </div>
    </div>
));

export default Invoice;