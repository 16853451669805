import React, { useEffect, useState } from 'react'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import { Box } from '@mui/material'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { IBreadcrumbList } from '../../components/breadcrumb/breadcrumb.props'
import EditBranchForm from '../../forms/EditBranchForm'
import BackArrow from '../../components/back-arrow/BackArrow'
import { getLocationById } from '../../utilities/api'
import { useAppDispatch } from '../../hooks/store.hook'
import { setErrorMessages } from '../../store/slices/error.slice'
import { useParams } from 'react-router-dom'

const breadcrumbList: IBreadcrumbList[] = [
    {
        type: "link",
        text: "Branches",
        path: "/branches"
    },
    {
        type: "text",
        text: "Edit Branch",
        path: "/locations/branches/add"
    }
];

export default function EditBranch() {

    const dispatch = useAppDispatch()

    const { id } = useParams();

    const [branch, setBranch] = useState(null);

    async function getBranch() {

        try {
            const { data } = await getLocationById(+id)
            setBranch(data)
        } catch (error) {
            dispatch(setErrorMessages(error))
        }
    };


    useEffect(() => {
        getBranch()
    }, [id])

    if (!branch) return null;

    return (
        <PageLayout>
            <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                className='mt-4 md:mt-0'
            >
                <BackArrow />
                <Breadcrumb list={breadcrumbList} />
            </Box>

            <EditBranchForm branch={branch} />


        </PageLayout>
    )
}
