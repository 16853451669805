import React, { useEffect, useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import { IMenu } from '../components/filters/drop-down/drop-down.props';
import TextArea from '../components/form/text-area-input/TextArea';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { toggleSuccessModel } from '../store/slices/model.slice';
import DateInput from '../components/form/date-input/DateInput';
import DateTimeInput from '../components/form/date-time-input/DateTimeInput';
import moment from 'moment';
import { addOrder } from '../store/thunk-actions/order-action';
import ClientAutoCompleat from '../components/form/ClientAutoCompleat/ClientAutoCompleat';
import Print from '../components/form/print/Print';
import { phonePlaceHolder, phoneValidation, phoneValidationMsg } from '../utilities/constants';

const schema = yup.object({
    full_name: yup.string().required("Full name is required"),
    // phone_number: yup.string().matches(/^\+971-\d{2}-\d{7}$/, "Invalid phone number format").required("Phone number is required"),
    phone_number: yup.string().matches(phoneValidation, phoneValidationMsg).required("Phone number is required"),
    order_details: yup.string().required("Message is required"),
    created_at: yup.string().required()
}).required();

export default function AddOrderForm() {

    const printRef = React.useRef(null);


    const [orderId, setOrderId] = useState(null)

    const { user: { user }, order: { isLoading } } = useAppSelector(s => s)

    const dispatch = useAppDispatch()

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            full_name: "",
            phone_number: "+971",
            order_details: "",
            created_at: moment(),
            location: user.location
        }
    });

    const onSubmit = (data: any) => {


        const payload = {
            ...data,
            // location: [{ id: user.location }],
            created_at: moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        }

        dispatch(addOrder(payload))
            .unwrap()
            .then(data => {
                reset()
                setOrderId(data.id)
                dispatch(toggleSuccessModel())
            })
    }

    // useEffect(() => {

    //     console.log("prient = ", printRef)
    //     if (orderId && printRef.current) // Ensure printRef is not null
    //         printRef.current.click(); // Check if printRef.current is not null before accessing its method
    // }, [orderId])

    return (
        <FormLayout
            title='Add New Order'
            onSubmit={handleSubmit(onSubmit)}
            withPrint={true}
            printEvent={() => alert("print....")}
            orderId={orderId}
            button={{
                isDirty,
                loading: isLoading,
                isValid,
                text: "Place Order"
            }}

        >

            <Stack spacing={{ md: 4, xs: 3 }} mb={{ xs: 3, md: 4 }}>

                <DateTimeInput
                    control={control}
                    name={"created_at"}
                    error={errors.created_at?.message}
                    label={"Created date"}
                    placeholder='Created date'
                />

                <ClientAutoCompleat
                    control={control}
                    name={"phone_number"}
                    error={errors.phone_number?.message}
                    label={"Phone number"}
                    placeholder={phonePlaceHolder}
                    setValue={setValue}
                />

                <TextInput
                    control={control}
                    name={"full_name"}
                    error={errors.full_name?.message}
                    label={"Full Name"}
                    placeholder='Full name'
                />

                {/* <TextInput
                    control={control}
                    name={"phone_number"}
                    error={errors.phone_number?.message}
                    label={"Phone number"}
                    placeholder="ex +971-50-1234567"
                    mask="+\9\71-99-9999999"
                /> */}

                <TextArea
                    control={control}
                    name={"order_details"}
                    error={errors.order_details?.message}
                    label={"Order Details"}
                    placeholder="Order Details"
                />
            </Stack>

            {/* <Print orderId={orderId} ref={printRef} className='hidden' /> */}

        </FormLayout>
    )
}
