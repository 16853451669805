import { useEffect, useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel, Stack, Switch } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import SelectInput from '../components/form/select-input/SelectInput';
import ImageInput from '../components/form/image-input/ImageInput';
import TextArea from '../components/form/text-area-input/TextArea';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { setSuccessMessage } from '../store/slices/success.slice';
import { updateBranch } from '../store/thunk-actions/branch-action';
import { ILocation } from '../interfaces/locations';
import RadioInput from '../components/form/radio-input/RadioInput';
import { phonePlaceHolder, phoneValidation, phoneValidationMsg } from '../utilities/constants';
import { areaMenu, cityMenu } from '../data/cities';
import { IBranch } from '../interfaces/branche.interface';


const schema = yup.object({
    name: yup.string().required("Branch name is required"),
    area: yup.string().notOneOf(["---"], "Area is required").required("Area is required"),
    city: yup.string().notOneOf(["---"], "City is required").required("City is required"),
    phone_number: yup.string().matches(phoneValidation, phoneValidationMsg).required("Phone number is required"),
    message_ready: yup.string().optional(),
    message_create: yup.string().optional(),
    message_survey: yup.string().optional(),
}).required();

export default function EditBranchForm({ branch }: { branch: ILocation }) {

    const dispatch = useAppDispatch()
    const { isLoading } = useAppSelector(s => s.branch)
    const [file, setFile] = useState(null)

    const [is_disabled_create, setIsPlaceOrderMassage] = useState(branch.is_disabled_create)
    const [is_disabled_ready_to_pick_up, setIsReadyMassage] = useState(branch.is_disabled_ready_to_pick_up)
    const [is_disabled_survey, setIsSurveyMassage] = useState(branch.is_disabled_survey)

    //form part
    const { control, watch, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            name: branch.name,
            area: branch.area,
            city: branch.city,
            phone_number: branch.phone_number,
            message_ready: branch.message_ready || "",
            location_prefix: branch.location_prefix,
            state: branch.state,
            message_create: branch.message_create || "",
            message_survey: branch.message_survey || "",
            location_img: ""
        }
    });

    function setImage(e: any) {
        setFile(e)
        setValue("location_img", e)
    }


    const onSubmit = (data: any) => {


        data.is_disabled_create = is_disabled_create;
        data.is_disabled_ready_to_pick_up = is_disabled_ready_to_pick_up;
        data.is_disabled_survey = is_disabled_survey;

        dispatch(updateBranch({
            id: branch.id, data
        }))
            .unwrap()
            .then(() => {
                reset(s => ({
                    ...s,
                    ...data
                }))
                dispatch(setSuccessMessage("Branch updated successfully."))
            })
    }


    return (
        <FormLayout
            title={`Edit Branch ${branch?.name}`}
            onSubmit={handleSubmit(onSubmit)}
            button={{
                isDirty: true,
                loading: isLoading,
                isValid
            }}
        >

            <Stack spacing={{ md: 4, xs: 3 }} mb={{ xs: 3, md: 4 }}>
                <SelectInput
                    control={control}
                    name={"city"}
                    error={errors.city?.message}
                    label={"City"}
                    menu={cityMenu}
                />


                {/* <SelectInput
                    control={control}
                    name={"area"}
                    error={errors.area?.message}
                    label={"Area"}
                    menu={areaMenu}
                /> */}

                <TextInput
                    control={control}
                    name={"area"}
                    error={errors.area?.message}
                    label={"Area"}
                    placeholder='Area'
                />

                <TextInput
                    control={control}
                    name={"name"}
                    error={errors.name?.message}
                    label={"Branch Name"}
                    placeholder='Branch name'
                />

                <TextInput
                    control={control}
                    name={"location_prefix"}
                    error={errors.location_prefix?.message}
                    label={"Branch Code"}
                    placeholder='Branch Code'
                    helperText='Max 4 character!'
                    maxLength={4}
                // isDisabled={true}
                />

                <ImageInput
                    file={file}
                    onchange={setImage}
                    className='mb-9'
                    image={branch?.location_img ?
                        `${process.env.REACT_APP_SERVER_STORAGE_URL}/${branch.location_img}` :
                        "/media/default.jpg"
                    }
                />

                <TextInput
                    control={control}
                    name={"phone_number"}
                    error={errors.phone_number?.message}
                    label={"Branch Manager Phone number"}
                    placeholder={phonePlaceHolder}
                    mask="+\9\71999999999"
                />

                <Box>
                    <TextArea
                        control={control}
                        name={"message_create"}
                        error={errors.message_create?.message}
                        label={"Place order message"}
                        placeholder="The message that will be sent in the SMS for the client when the salesman places an order."
                        helperText='Input variable "{{client_name}} = client name , {{orders_head_of}} = orders ahead of , {{ticket_number}} = ticket number"'
                        isDisabled={is_disabled_create}
                    />

                    <FormControlLabel
                        onChange={() => setIsPlaceOrderMassage(s => !s)}
                        control={<Switch checked={is_disabled_create ? false : true} />}
                        label={
                            is_disabled_create ? "Place order message is disabled" : "Place order message is enabled"
                        } />
                </Box>

                <Box>
                    <TextArea
                        control={control}
                        name={"message_ready"}
                        error={errors.message_ready?.message}
                        label={"Ready to pickup a message"}
                        placeholder="The message that will be sent in the SMS for the client when the kitchen make an order ready to pickup."
                        helperText='Input variable "{{client_name}} = client name , {{ticket_number}} = ticket number"'
                        isDisabled={is_disabled_ready_to_pick_up}
                    />

                    <FormControlLabel
                        onChange={() => setIsReadyMassage(s => !s)}
                        control={<Switch checked={is_disabled_ready_to_pick_up ? false : true} />}
                        label={
                            is_disabled_ready_to_pick_up ? "Ready to pickup message is disabled" : "Ready to pickup message is enabled"
                        } />
                </Box>

                <Box>
                    <TextArea
                        control={control}
                        name={"message_survey"}
                        error={errors.message_survey?.message}
                        label={"Survey message"}
                        placeholder="The message that will be sent in the SMS for the client after 24 hrs"
                        helperText='Input variable "{{client_name}} = client name"'
                        isDisabled={is_disabled_survey}
                    />

                    <FormControlLabel
                        onChange={() => setIsSurveyMassage(s => !s)}
                        control={<Switch checked={is_disabled_survey ? false : true} />}
                        label={
                            is_disabled_survey ? "Survey message is disabled" : "Survey message is enabled"
                        } />
                </Box>

                <RadioInput
                    control={control}
                    name={"state"}
                    label={"Status"}
                    defaultValue={branch.state}
                    menu={[
                        { value: "enabled", label: "Enabled" },
                        { value: "disabled", label: "Disabled" }
                    ]}

                />
            </Stack>

        </FormLayout>
    )
}
