import { Grid } from '@mui/material'
import React from 'react'
import Processing from '../../processing/Processing'
import ExportButton from '../export-btn/ExportButton'

export default function ReportInformation({ doneCount, processingCount, query = "" }: {
    doneCount: number,
    processingCount: number,
    query?: string
}) {
    return (
        <Grid container
            rowSpacing={{ xs: 1, md: 3 }}
            columnSpacing={{ xs: 0, md: 3 }}
            mb={{ xs: 2, md: 4 }}
        >
            <Grid item xs={12} md={4}>
                <Processing title='Done Orders' count={doneCount} image='/media/check.svg' />
            </Grid>
            <Grid item xs={12} md={4}>
                <Processing title='Processing Orders' count={processingCount} image='/media/circle.svg' />
            </Grid>
            <Grid item xs={12} md={4}>
                <ExportButton query={query} />
            </Grid>
        </Grid>)
}
