import React from 'react'
import PageLayout from '../layouts/layout/page-layout/PageLayout'
import ClientsList from '../components/clients/clients-list/ClientsList'
import PageHeader from '../components/page-header/PageHeader'
import ProfileForm from '../forms/ProfileFrom'
import ChangePasswordForm from '../forms/ChangePasswordForm'
import ProfileData from '../components/profile/ProfileData'

export default function Profile() {
    return (
        <PageLayout
        >
            <PageHeader
                title='Profile'
                className='mb-6 mt-4 md:mt-0'
            />

            <ProfileData />
            <ProfileForm />
            <ChangePasswordForm />
        </PageLayout>
    )
}
