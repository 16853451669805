import React from 'react'
import { CircularProgress } from '@mui/material'
import { SubmitButtonProps } from './submitButton.props'
import Button from '../../button/Button'
import { ButtonType } from '../../button/button.props'

export default function SubmitButton({
    isDirty,
    isValid,
    loading,
    text = "Submit",
    className = "",
    isDisabled = false,
    width = "100%"
}: SubmitButtonProps) {
    return (
        <Button
            width={width}
            className={className}
            onClick={() => { return }}
            // type={isValid && !isDirty && !loading ? ButtonType.COLORED : ButtonType.DISABLED}
            type={loading || isDisabled ? ButtonType.DISABLED : ButtonType.COLORED}

        >
            {loading ?
                <CircularProgress color="inherit" size={30} />
                :
                text
            }
        </Button>
    )
}
