import { CardContentProps } from './card-content.proos'
import { Box, Typography } from '@mui/material'

export default function CardContent({
    title,
    text,
    dark,
    color = "#4A4F78",
    underline = false,
    titleNode,
    textNode
}: CardContentProps) {
    return (
        <Box
            component="div"
            sx={{
                height: "100%",
                p: "8px 16px",
                backgroundColor: dark ? "#F4F8FF" : "#FFF"
            }}
        >

            {title && <Typography
                color="#4A4F78"
                fontSize="12px"
                fontWeight="600"
                lineHeight="21px"
            >
                {title}
            </Typography>}


            {titleNode && <Box
                color="#4A4F78"
                fontSize="12px"
                fontWeight="600"
                lineHeight="21px"
            >
                {titleNode}
            </Box>}

            {text && <Typography
                color={color}
                fontSize="12px"
                fontWeight="400"
                lineHeight="18px"
                sx={{
                    textDecoration: underline ? "underline" : "none"
                }}
            >
                {text}
            </Typography>}

            {textNode && <Box
                color={color}
                fontSize="12px"
                fontWeight="400"
                lineHeight="18px"
                sx={{
                    textDecoration: underline ? "underline" : "none"
                }}
            >
                {textNode}
            </Box>}

        </Box>
    )
}
