import { number } from 'yup';
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    // isOpen: false,
    deleteEmployeeModel: {
        isOpen: false,
        employeeId: 0
    },
    successModel: {
        isOpen: false,
    },
    notificationModel: {
        isOpen: true,
    }
}

const slice = createSlice({
    name: "models",
    initialState,
    reducers: {
        toggleDeleteEmployeeModel: (state, action) => {
            state.deleteEmployeeModel.isOpen = !state.deleteEmployeeModel.isOpen;
            state.deleteEmployeeModel.employeeId = action.payload
        },
        toggleSuccessModel: (state) => {
            // state.isOpen = !state.isOpen;
            state.successModel.isOpen = !state.successModel.isOpen;
        },
        toggleNotificationModel: (state) => {
            // state.isOpen = !state.isOpen;
            state.notificationModel.isOpen = !state.notificationModel.isOpen;
        },
    },
})

export default slice.reducer;

const {
    toggleSuccessModel,
    toggleDeleteEmployeeModel,
    toggleNotificationModel
} = slice.actions;

export {
    toggleSuccessModel,
    toggleDeleteEmployeeModel,
    toggleNotificationModel
}