import React from 'react'
import { TextInputProps } from './text-input.props'
import { useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Box, IconButton, TextField } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers';

export default function DateTimeInput({
    control,
    name,
    error,
    label,
    placeholder = "",
    width = "100%",
    className = ""
}: TextInputProps) {


    return (
        <Box
            className={`${className}`}
            component="div"
            width={width}
        >
            <Controller
                name={name}
                control={control}
                render={({ formState, field }) => <LocalizationProvider

                    dateAdapter={AdapterMoment}
                >
                    <DateTimePicker
                        // {...field}

                        className='date-picker-style'
                        closeOnSelect={true}
                        label={label}

                        slotProps={{
                            actionBar: {
                                actions: ["cancel", "clear"]
                            },
                        }}

                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                        format='DD/MM/YYYY hh:mm A'
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}

                    />
                </LocalizationProvider>

                }
            />
        </Box>
    )
}
