import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { ButtonType } from '../../button/button.props'
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import Button from '../../button/Button'
import { setSuccessMessage } from '../../../store/slices/success.slice'
import { toggleDeleteEmployeeModel } from '../../../store/slices/model.slice'
import { deleteEmployee } from '../../../store/thunk-actions/employee-action'
export default function DeleteEmployeeModel() {

    const dispatch = useAppDispatch()

    const { employee: { isLoading }, model: { deleteEmployeeModel: { employeeId: id } } } = useAppSelector(s => s)

    async function onclick() {
        dispatch(deleteEmployee(id)).unwrap().then(() => {
            dispatch(toggleDeleteEmployeeModel(0))
            dispatch(setSuccessMessage("Employee is deleted successfully"))
        })
    }


    return (
        <Box
            component="div"
            position="relative"
            p={2}
        >

            <Typography
                variant='h2'
                component="div"
                color="#d32f2f"
                fontSize="32px"
                lineHeight="1.5"
                fontWeight="700"
                mb={2}
                align='center'
            >
                Warning
            </Typography>

            <Typography
                variant='h2'
                component="div"
                color="#1f2f4c"
                fontSize="18px"
                lineHeight="1.5"
                mb={3}
                align='center'
            >
                Are you sure you want to delete this employee?
            </Typography>

            <Box
                display="flex"
                gap="16px"
                justifyContent="center"
            >
                <Button
                    onClick={() => dispatch(toggleDeleteEmployeeModel(0))}
                    width='50%'
                >
                    Close
                </Button>
                <Button
                    onClick={onclick}
                    type={!isLoading ? ButtonType.COLORED : ButtonType.DISABLED}
                    width='50%'
                >
                    {isLoading ? <CircularProgress color="inherit" size={30} /> : "Delete"}
                </Button>
            </Box>

        </Box >)
}
