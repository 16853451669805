import React, { useEffect, useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeRole } from '../interfaces/employees.interface';
import { Box, Stack } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import { fullName, generateLocationMenu } from '../utilities/helper';
import { IMenu } from '../components/filters/drop-down/drop-down.props';
import SelectInput from '../components/form/select-input/SelectInput';
import PasswordInput from '../components/form/password-input/PasswordInput';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { addEmployee } from '../store/thunk-actions/employee-action';
import { setSuccessMessage } from '../store/slices/success.slice';
import { phonePlaceHolder, phoneValidation, phoneValidationMsg } from '../utilities/constants';

const schema = yup.object({
    name: yup.string().required("Full name is required"),
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
    user_type: yup.string().required("Role is required").oneOf([...Object.values(EmployeeRole), "admin"]),
    location: yup.string().when('user_type', {
        is: (user_type: any) => {
            return user_type !== 'admin'
        },
        then: (s) => s.notOneOf(["---"], "Location is required").required("Location is required"),
        otherwise: (s) => s.optional().nullable(),
    }),
    phone_number: yup.string().matches(phoneValidation, phoneValidationMsg).required("Phone number is required"),
}).required();


const employeeRoll: IMenu[] = [
    { value: "---", label: "Choose The Role" },
    { value: EmployeeRole.KITCHEN, label: "Kitchen" },
    { value: EmployeeRole.salesman, label: "Salesman" },
    { value: EmployeeRole.SUPERVISOR, label: "Supervisor" },
]



export default function AddEmployeeForm() {

    const dispatch = useAppDispatch()

    const { location: { locations }, employee: { isLoading }, user: { role } } = useAppSelector(s => s)

    const [hideBranch, setHideBranch] = useState(false)
    //form part
    const { control, watch, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            email: "",
            password: "",
            user_type: "---",
            location: "---",
            phone_number: "+971",

        }
    });

    const onSubmit = (data: any) => {

        if (data.user_type === "admin") {
            data.location = null
        }

        dispatch(addEmployee({
            ...data,
            password_confirmation: data.password
        })).unwrap()
            .then(() => {
                reset()
                dispatch(setSuccessMessage("Employee created successfully."))
            })
    }

    const userType = watch("user_type");

    useEffect(() => {
        if (userType === "admin") {
            setHideBranch(true)
            setValue("location", null)
        } else {
            setHideBranch(false)
            setValue("location", "---")
        }
    }, [userType])

    return (
        <FormLayout
            title='Add New Employee'
            onSubmit={handleSubmit(onSubmit)}
            button={{
                isDirty,
                loading: isLoading,
                isValid
            }}
        >

            <Stack spacing={{ md: 4, xs: 3 }} mb={{ xs: 3, md: 4 }}>
                <TextInput
                    control={control}
                    name={"name"}
                    error={errors.name?.message}
                    label={"Full Name"}
                    placeholder='Full name'
                />

                <SelectInput
                    control={control}
                    name={"user_type"}
                    error={errors.user_type?.message}
                    label={"Role"}
                    menu={role === "superadmin" ? [
                        ...employeeRoll,
                        { value: "admin", label: "Admin" },
                    ] : employeeRoll}
                />

                {!hideBranch && <SelectInput
                    control={control}
                    name={"location"}
                    error={errors.location?.message}
                    label={"Branch Name"}
                    menu={generateLocationMenu(locations)}
                />}


                <TextInput
                    control={control}
                    name={"phone_number"}
                    error={errors.phone_number?.message}
                    label={"Phone number"}
                    placeholder={phonePlaceHolder}
                    mask="+\9\71-99-9999999"
                />

                <TextInput
                    control={control}
                    name={"email"}
                    error={errors.email?.message}
                    label={"Email"}
                    placeholder="ex email@gmail.com"
                />

                <PasswordInput
                    control={control}
                    name={"password"}
                    error={errors.password?.message}
                    label={"Password"}
                    placeholder="Password"
                />
            </Stack>

        </FormLayout>
    )
}
