import { Box, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";
import "./../../table/table.css"
import HeaderSort from "../../table/header-sort/HeaderSort";
import Status from "../../status/Status";
import Duration from "../../duration/Duration";
import { Link } from "react-router-dom";

interface ColumnsProps {
    sortTicket: string;
    setSortTicket: (value: string) => void;
    sortDate: string;
    setSortDate: (value: string) => void;
    sortStatue: string,
    setSortStatus: (value: string) => void;

}

export const columns = (handelSorting: (value: string) => void, isLoading: boolean): GridColDef[] => ([
    {
        field: 'id',
        headerName: 'id',
        width: 130,
        headerClassName: "table-header-col"
    },

    {
        field: 'ticket_number',
        headerName: 'Ticket No',
        width: 130,
        sortable: false,
        headerClassName: "table-header-col-sort",
        cellClassName: "table-cell-col",
        renderHeader: (params) => <HeaderSort
            text={params.colDef.field}
            handelSorting={handelSorting}
            isLoading={isLoading}
            title={params.colDef.headerName}
        />,
        renderCell: (params) => (
            <Box
                color="#A01A1F"
                component="span"
            >
                #{params.row.location_prefix}-{params.row.ticket_number}
            </Box>
        ),
    },
    {
        field: 'phone_number',
        headerName: 'Phone Number',
        width: 160,
        hideable: true,
        sortable: false,
        headerClassName: "table-header-col",
        cellClassName: "table-cell-col",
        renderCell: (params) => (
            <Box
                color="#000"
                component="span"
            >
                {params.row.phone_number}
            </Box>
        ),
    },
    {
        field: 'full_name',
        headerName: 'Name',
        width: 160,
        sortable: false,
        cellClassName: "table-cell-col",
        headerClassName: "table-header-col",
        renderCell: (params) => (
            <Box
                component="span"
                maxWidth="100%"
                whiteSpace="pre-wrap"
                lineHeight="18px"
                textTransform='capitalize'
            >
                {params.row.full_name}
            </Box>
        ),
    },
    {
        field: 'location_name',
        headerName: 'Branch Name',
        cellClassName: "table-cell-col",
        headerClassName: "table-header-col",
        sortable: false,
        width: 140,
        renderCell: (params) => (
            <Box
                component="span"
                className="underline"
                sx={{
                    whiteSpace: "pre-wrap"
                }}
            >
                <Link className="flex" to={`/branches/${params.row.location}`}> {params.row.location_name}</Link>
            </Box>
        ),
    },
    {
        field: 'city',
        headerName: 'City',
        cellClassName: "table-cell-col",
        headerClassName: "table-header-col",
        sortable: false,
        width: 140,
        renderCell: (params) => (
            <Box
                component="span"
                className="underline"
            >
                {params.row.city}
            </Box>
        ),
    },
    {
        field: 'created_at',
        headerName: 'Created Date',
        width: 130,
        headerClassName: "table-header-col-sort",
        cellClassName: "table-cell-col",
        renderHeader: (params) => <HeaderSort
            text={params.colDef.field}
            handelSorting={handelSorting}
            isLoading={isLoading}
            title={params.colDef.headerName}
        />,
        sortable: false,
        renderCell: (params) => (
            <Box
                component="span"
                maxWidth="100%"
                whiteSpace="pre-wrap"
            >
                {moment(params.row.created_at).format("DD/MM/YYYY hh:mm A")}
            </Box>
        ),
    },
    {
        field: 'completed_at',
        headerName: 'Duration',
        width: 120,
        // renderHeader: (params) => <HeaderSort text={params.colDef.headerName} />,
        cellClassName: "table-cell-col",
        headerClassName: "table-header-col",
        sortable: false,
        renderCell: (param) => <Duration
            completed_at={param.row?.completed_at}
            created_at={param.row?.created_at}
            picked_at={param.row?.picked_at}
        />
    },
    {
        field: 'details',
        headerName: 'Order Details',
        headerClassName: "table-header-col",
        cellClassName: "table-cell-col",
        sortable: false,
        width: 250,
        renderCell: (params) => (
            <Box
                component="span"
                maxWidth="100%"
                whiteSpace="pre-wrap"
                fontSize="10px"
                lineHeight="14px"
            >
                {params.row.order_details}
            </Box>
        ),
    },

    {
        field: 'status',
        headerName: 'Status',
        cellClassName: "table-cell-col",
        renderHeader: (params) => <HeaderSort
            text={params.colDef.field}
            handelSorting={handelSorting}
            isLoading={isLoading}
            title={params.colDef.headerName}
        />,
        renderCell: (param) => <Status
            status={param.row.status}
            time={param.row?.picked_at}
            width="150px"
        />,
        width: 200,
        headerClassName: "table-header-col-sort",
        sortable: false,
    },
]) 
