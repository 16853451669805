import { Box, Grid, Stack } from '@mui/material'
import moment from 'moment'
import { mobileBreakPoint } from '../../../utilities/constants'
import Card from '../../card/card/Card'
import CardContent from '../../card/card-content/CardContent'
import { IOrder } from '../../../interfaces/order.interface'
import Duration from '../../duration/Duration'

export default function MobileView({ reports }: { reports: IOrder[] }) {


    return (
        <Box
            component="div"
            sx={{
                display: "none",
                [mobileBreakPoint]: {
                    display: "block"
                }
            }}
        >

            <Stack
                spacing={1}
            >
                {reports.map(report => <Card
                    key={report.id}
                    withHeader={true}
                    status={report.status}
                    time={report?.picked_at}
                >
                    <Grid container>
                        <Grid item xs={6}>
                            <CardContent
                                title='Ticket No'
                                dark={true}
                                text={`#${report.location_prefix}-${report.ticket_number}`}
                                color='#A01A1F'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardContent
                                title='Duration'
                                textNode={<Duration
                                    completed_at={report.completed_at}
                                    created_at={report.created_at}
                                    picked_at={report?.picked_at}
                                />}
                                underline={true}
                                dark={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardContent
                                title='Client Name'
                                text={report.full_name}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardContent
                                title='Phone Number'
                                text={report.phone_number}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardContent
                                title='Created Date'
                                text={moment(report.created_at).format("DD/MM/YYYY hh:mm A")}
                                dark={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardContent
                                title='Branch Name'
                                dark={true}
                                text={report.location_name}
                                underline={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CardContent
                                title='Order Details'
                                text={report.order_details}
                            />
                        </Grid>
                    </Grid>
                </Card>)}

            </Stack>

        </Box>
    )
}
