import React, { useState } from 'react'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import { Box } from '@mui/material'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { IBreadcrumbList } from '../../components/breadcrumb/breadcrumb.props'
import Button from '../../components/button/Button'
import AddIcon from '@mui/icons-material/Add';
import EmployeeList from '../../components/employee/employees-list/EmployeeList'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetEmployees } from '../../hooks/get-employeess.hook'
import { useAppSelector, useAppDispatch } from '../../hooks/store.hook'
import Pagination from '../../components/pagination/Pagination'
import Spinner from '../../components/spinner/Spinner'
import { ISorting } from '../../interfaces/sorting.interface'
import { updateQueryStringParameter } from '../../utilities/helper'

const breadcrumbList: IBreadcrumbList[] = [
    {
        type: "text",
        text: "Employees",
        path: "/locations/employees"
    }
]

export default function Employees() {

    const navigate = useNavigate();
    const { employees } = useAppSelector(s => s.employee)
    const dispatch = useAppDispatch()


    const [query, setQuery] = useState("")


    //set number of page
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get("page") || '1', 10);
    const [sorting, setSorting] = useState<ISorting>({
        sort_by: "",
        sort: "ASC"
    });


    function handelSorting(value: string) {

        console.log("sort = ", value)
        setSorting(s => ({
            sort_by: value,
            sort: s.sort === "ASC" ? "DESC" : "ASC"
        }))

        setQuery(s => {
            let updatedQuery = s || '';
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort_by', value);
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort', sorting.sort);
            return updatedQuery;
        });
        navigate("")
    }

    //get orders
    const { isLoading, isFetching, data } = useGetEmployees(dispatch, page, query);

    return (
        <PageLayout>
            <Box
                component="div"
            >
                <Breadcrumb list={breadcrumbList} />

                <Button
                    onClick={() => navigate("/employees/add")}
                    width='fit-content'
                    className='ml-auto mt-4'

                >
                    Add New Employee
                    <AddIcon sx={{
                        fontSize: "20px",
                        ml: 1
                    }} />
                </Button>
            </Box>

            {/* {isLoading ? <Spinner /> : <>
                <EmployeeList employees={employees} handelSorting={handelSorting} isLoading={isFetching} />
                <Pagination count={Math.ceil(data?.total / 10) || 1} page={page} />
            </>} */}


            <EmployeeList
                employees={isLoading || isFetching ? [] : employees}
                handelSorting={handelSorting}
                isLoading={isLoading || isFetching}
            />
            <Pagination count={Math.ceil(data?.total / 10) || 1} page={page} />



        </PageLayout>
    )
}
