import React from 'react'
import Bar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SnackbarProps } from './snackbar.props';
import { useAppDispatch } from '../../hooks/store.hook';
import { clearSuccessMessage } from '../../store/slices/success.slice';
import { clearErrorMessages } from '../../store/slices/error.slice';
import { Box, Stack } from '@mui/material';

export default function Snackbar({ type, messages }: SnackbarProps) {
    const [open, setOpen] = React.useState(true);
    const dispatch = useAppDispatch()

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

        if (type === "success") {
            dispatch(clearSuccessMessage())
        } else if (type === "error") {
            dispatch(clearErrorMessages())
        }

    };

    return (
        <div>
            <Bar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
            >
                <Box
                    component="div"
                    display="flex"
                    sx={{ width: '100%' }}
                    gap={1}
                    flexDirection="column"
                >
                    {messages?.map((message, index) => <Alert
                        severity={type}
                        key={index}
                    >
                        {message}
                    </Alert>
                    )}
                </Box>
            </Bar>
        </div>
    );
}
