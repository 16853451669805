import { Box, IconButton, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";
import "./../../table/table.css"
import HeaderSort from "../../table/header-sort/HeaderSort";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Link } from "react-router-dom";
import { IReport } from "../../../interfaces/report.interface";
import OrderStatusList from "../order-status/OrderStatusList";
import Duration from "../../duration/Duration";
import { OrderStatus } from "../../../utilities/constants";

export const columns = (handelSorting: (value: string) => void, isLoading: boolean): GridColDef[] => ([
    {
        field: 'id',
        headerName: 'id',
        width: 130,
        headerClassName: "table-header-col"
    },

    {
        field: 'ticket_number',
        headerName: 'Ticket No',
        width: 130,
        sortable: false,
        headerClassName: "table-header-col-sort",
        cellClassName: "table-cell-col",
        renderHeader: (params) => <HeaderSort
            text={params.colDef.field}
            handelSorting={handelSorting}
            isLoading={isLoading}
            title={params.colDef.headerName}
        />,
        renderCell: (params) => (
            <Link to={`/orders/edits/${params.row.id}`}>
                <Box
                    color="#A01A1F"
                    component="span"
                >
                    #{params.row.location_prefix}-{params.row.ticket_number}
                </Box>
            </Link>

        ),
    },
    {
        field: 'order_details',
        headerName: 'Order Details',
        headerClassName: "table-header-col",
        cellClassName: "table-cell-col",
        sortable: false,
        width: 250,
        renderCell: (params) => (
            // <Link to={`/orders/edits/${params.row.id}`}>
            <Box
                component="span"
                width="100%"
                maxWidth="100%"
                whiteSpace="pre-wrap"
                fontSize="10px"
                lineHeight="14px"
            >
                {
                    <Link to={`/orders/edits/${params.row.id}`}>
                        {params.row.order_details}
                    </Link>
                }
            </Box>
            // </Link>
        ),
    },

    {
        field: 'action',
        headerName: '',
        headerClassName: "table-header-col",
        cellClassName: "table-cell-col",
        sortable: false,
        width: 70,
        renderCell: (params) => (
            ![OrderStatus.DONE, OrderStatus.READY_TO_PICKUP].includes(params.row.status) && <Link to={`/orders/edits/${params.row.id}`}>
                <BorderColorOutlinedIcon
                    sx={{
                        fontSize: "20px",
                        color: "#A01A1F"

                    }}
                />
            </Link>
        ),
    },

    {
        field: 'created_at',
        headerName: 'Created date',
        width: 130,
        headerClassName: "table-header-col-sort",
        cellClassName: "table-cell-col",
        renderHeader: (params) => <HeaderSort
            text={params.colDef.field}
            handelSorting={handelSorting}
            isLoading={isLoading}
            title={params.colDef.headerName}
        />,
        sortable: false,
        renderCell: (params) => (
            <Box
                component="span"
                maxWidth="100%"
                whiteSpace="pre-wrap"
            >
                {moment(params.row.created_at).format("DD/MM/YYYY hh:mm A")}
            </Box>
        ),
    },

    {
        field: 'full_name',
        headerName: 'Name',
        width: 160,
        sortable: false,
        cellClassName: "table-cell-col",
        headerClassName: "table-header-col",
        renderCell: (params) => (
            <Box
                component="span"
                maxWidth="100%"
                whiteSpace="pre-wrap"
                lineHeight="18px"
                textTransform="capitalize"
            >
                {params.row.full_name}
            </Box>
        ),
    },

    {
        field: 'phone_number',
        headerName: 'Phone Number',
        width: 160,
        hideable: true,
        sortable: false,
        headerClassName: "table-header-col",
        cellClassName: "table-cell-col",
        renderCell: (params) => (
            <Box
                color="#000"
                component="span"
            >
                {params.row.phone_number}
            </Box>
        ),
    },
    {
        field: 'completed_at',
        headerName: 'Duration',
        width: 120,
        // renderHeader: (params) => <HeaderSort text={params.colDef.headerName} />,
        cellClassName: "table-cell-col",
        headerClassName: "table-header-col",
        sortable: false,
        renderCell: (param) => <Duration
            completed_at={param.row?.completed_at}
            created_at={param.row?.created_at}
            picked_at={param.row?.picked_at}
        />
    },
    {
        field: 'status',
        headerName: 'Status',
        cellClassName: "table-cell-col table-cell-col-with-overflow",
        renderHeader: (params) => <HeaderSort
            text={params.colDef.field}
            handelSorting={handelSorting}
            isLoading={isLoading}
            title={params.colDef.headerName}
        />,
        renderCell: (param) => <OrderStatusList order={param.row} />,
        width: 250,
        headerClassName: "table-header-col-sort",
        sortable: false,
    },

])