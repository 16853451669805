import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILocation } from "../../interfaces/locations";
import { addBranch } from "../thunk-actions/branch-action";


const initialState: {
    locations: ILocation[],
    page: number,
    limit: number
} = {
    locations: [],
    page: 1,
    limit: 10
}

const slice = createSlice({
    name: "locations",
    initialState,
    reducers: {
        setLocations(state, action: PayloadAction<ILocation[]>) {
            state.locations = action.payload
        }
    },
    extraReducers: (builder) => {
        {
            builder.addCase(addBranch.fulfilled, (state, action) => {
                state.locations = [action.payload, ...state.locations];
            })
        }
    }
})


const { setLocations } = slice.actions

export { setLocations }
export default slice.reducer;