import React from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/store.hook'
import {
    toggleDeleteEmployeeModel,
    toggleNotificationModel,
    toggleSuccessModel
} from '../store/slices/model.slice'
import Model from "./Model"
import SuccessModel from '../components/sucsses/SuccessModel'
import DeleteEmployeeModel from '../components/employee/delete-employee-model/DeleteEmployeeModel'
import SoundNotification from '../components/SoundNotification/SoundNotification'
import EnableNotificationModel from '../components/SoundNotification/EnableNotifcationModle'
import { EmployeeRole } from '../interfaces/employees.interface'

const Models = () => {
    const { model } = useAppSelector(s => s)
    const { role } = useAppSelector(s => s.user);
    const dispatch = useAppDispatch();

    function deleteEmployeeModel(arg0: number): any {
        throw new Error('Function not implemented.')
    }

    return (
        <>
            {/* <Model
                open={model.openOrderModel.isOpen}
                toggleOpen={() => dispatch(toggleOpenOrderModel(0))}
            >
            </Model> */}


            <Model
                open={model.deleteEmployeeModel.isOpen}
                toggleOpen={() => dispatch(toggleDeleteEmployeeModel(0))}
            >
                <DeleteEmployeeModel />
            </Model>
            <Model
                open={model.successModel.isOpen}
                toggleOpen={() => dispatch(toggleSuccessModel())}
            >
                <SuccessModel />
            </Model>
            {role === EmployeeRole.KITCHEN && <Model
                open={model.notificationModel.isOpen}
                toggleOpen={() => dispatch(toggleNotificationModel())}
            >
                <EnableNotificationModel />
            </Model>}

        </>
    )
}

export default Models