import { IBranch } from "./branche.interface";
import { IUser } from "./user";

export interface IEmployee {
    id: number
    user: IUser
    branch: IBranch
}

export enum EmployeeRole {
    KITCHEN = "kitchen",
    salesman = "salesman",
    SUPERVISOR = "supervisor",
}