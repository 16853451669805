import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateFilterProps } from './date-filter.props';
import { Box, IconButton, TextField } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { MobileDatePicker } from '@mui/x-date-pickers';

export default function DateFilter({
    label,
    value,
    onChange,
    width = "100%"
}: DateFilterProps) {

    return (
        <Box
            width={width}
            component="div"
            height="55px"

        >
            <LocalizationProvider

                dateAdapter={AdapterMoment}
            >
                {/* <MobileDatePicker
                    label={label}
                    value={value}
                    onChange={(newValue) => onChange(newValue)}
                    className='date-picker-style' slotProps={{
                        actionBar: {
                            actions: ["cancel", "clear" , "accept"]
                        },
                    }}

    

                    sx={{
                        width: "100%",
                        height: "100%",
                        ".MuiFormLabel-root": {
                            fontSize: "12px",
                            color: "#656B9F"
                        },
                        "input": {
                            fontSize: "10px !important",
                            padding: "0 8px !important",
                            "::placeholder": {
                                fontSize: "16px"
                            }
                        },
                        ".MuiSvgIcon-root": {
                            fontSize: "16px"
                        }
                    }}
                    format='DD/MM/YYYY'

                /> */}
                <DatePicker
                    closeOnSelect={true}
                    label={label}
                    value={value}
                    onChange={(newValue) => onChange(newValue)}
                    className='date-picker-style' slotProps={{
                        actionBar: {
                            actions: ["cancel", "clear", "accept", "today"]
                        },
                    }}

                    sx={{
                        width: "100%",
                        height: "100%",
                        ".MuiFormLabel-root": {
                            fontSize: "12px",
                            color: "#656B9F"
                        },
                        "input": {
                            fontSize: "10px !important",
                            padding: "0 8px !important",
                            "::placeholder": {
                                fontSize: "16px"
                            }
                        },
                        ".MuiSvgIcon-root": {
                            fontSize: "16px"
                        }
                    }}
                    format='DD/MM/YYYY'

                />
            </LocalizationProvider>
        </Box>

    );
}
