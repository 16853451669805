import React from 'react'
import { ProcessingProps } from './processing.props'
import { Box, Typography } from '@mui/material'

export default function Processing({ title, image, count }: ProcessingProps) {
    return (
        <Box
            component="div"
            width="100%"
            sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                border: "1px solid #D7E7FA",
                height: "100%",
                p: "5px 24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >

            <Box
                component="div"
                mr={3}
            >
                <img src={image} />
            </Box>


            <Box
                component="div"
            >
                <Typography
                    fontSize="13px"
                    fontWeight="500"
                    color="#475569"
                >
                    {title}
                </Typography>

                <Typography
                    fontSize="16px"
                    fontWeight="700"
                    color="#0F172A"
                >
                    {count} Orders
                </Typography>
            </Box>

        </Box>
    )
}
