// api/github.api.ts
import { useQuery } from '@tanstack/react-query';
import { setErrorFormat } from '../utilities/error-format';
import * as api from "../utilities/api"
import { createFullSearchQuery } from '../utilities/helper';
import { setClients } from '../store/slices/client.slice';

export const getClients = async (dispatch: any, page: number, query: string) => {

    try {

        const searchQuery = createFullSearchQuery({ page, query, limit: 10 })

        console.log("searchQuery = ", searchQuery)


        const { data } = await api.getClients(searchQuery);

        dispatch(setClients(data.data))

        return data;
    } catch (error) {
        throw setErrorFormat(error);
    }
};

export const useGetClients = (dispatch: any, page: number, query: string) => {

    return useQuery(['getClients', query, page], () => getClients(dispatch, page, query), {
        staleTime: 0, // 10 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes,
        keepPreviousData: false, // Maintain previous data while fetching new data
        refetchOnWindowFocus: false

    });
};
