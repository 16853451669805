import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Avatar, Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook';
import Status from '../status/Status';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { updateUserImage } from '../../store/thunk-actions/user-action';
import { setSuccessMessage } from '../../store/slices/success.slice';

export default function ProfileData() {

    const dispatch = useAppDispatch()
    const { user } = useAppSelector(s => s.user)

    const [file, setFile] = useState(null)

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0]
        dispatch(updateUserImage({
            id: user.id,
            file,
        })).unwrap()
            .then(() => {
                setFile(file)
                dispatch(setSuccessMessage("Image Uploaded"))
            })
    };
    return (
        <Box
            component="div"
            sx={{
                backgroundColor: "#FFF",
                p: 3,
                border: "1px solid #D4E6FF",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "space-between"
            }}
        >

            <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
            }}>

                <label
                    className='cursor-pointer relative'
                >
                    <Avatar
                        sx={{
                            width: "95px",
                            height: "95px"
                        }}
                        alt={user.name}
                        src={file ?
                            URL.createObjectURL(file) :
                            `${process.env.REACT_APP_SERVER_STORAGE_URL}/${user.profile_pic}`
                        }
                    />

                    <Box
                        component="div"
                        sx={{
                            height: "25px",
                            width: "25px",
                            borderRadius: "50%",
                            backgroundColor: "#FFF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            bottom: "0",
                            right: "0"
                        }}
                    >
                        <BorderColorOutlinedIcon
                            sx={{
                                color: "#A01A1F",
                                fontSize: "16px"
                            }}
                        />
                    </Box>
                    <input type="file" hidden onChange={handleImageChange} />
                </label>

                <Box
                    component="div"
                >
                    <Typography
                        component="div"
                        fontSize={{ xs: "18px", md: "24px" }}
                        color="#202020"
                        lineHeight="30px"
                        mb={1}
                        fontWeight={600}
                    >
                        {user.name}
                    </Typography>
                    <Status status={user.user_type} />

                </Box>

            </Box>

        </Box>
    )
}
