import { useEffect, useRef, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '../../button/Button';
import { ButtonType } from '../../button/button.props';
import { useAppDispatch } from '../../../hooks/store.hook';
import { setErrorMessages } from '../../../store/slices/error.slice';
import { CircularProgress } from '@mui/material';
import { getClients } from '../../../utilities/api';
import { createFullSearchQuery } from '../../../utilities/helper';
import { CSVLink } from "react-csv";
import moment from 'moment';

const headers = [
    { label: 'id', key: "id" },
    { label: 'Phone Number', key: "phone_number" },
    { label: 'Full Name', key: "full_name" },
    { label: 'Added Date', key: "created_at" },
    { label: 'Completed', key: "completed_orders" },
];

export default function ExportButton({ query = "" }) {

    const dispatch = useAppDispatch();
    const exportRef = useRef(null);

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    async function onClick() {
        setLoading(true);
        try {

            const response = await getClients(`${createFullSearchQuery({ query, page: 1 })}&export=true`);
            const data = response.data;

            setData(data.map((item: any) => ({
                ...item,
                created_at: moment(item.created_at).format("DD/MM/YYYY hh:mm A"),
            })));

            return;

        } catch (error) {
            dispatch(setErrorMessages(error));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (data.length > 0 && exportRef.current) {
            exportRef.current.link.click();
        }
    }, [data]);


    return (
        <div>
            <Button
                onClick={onClick}
                type={isLoading ? ButtonType.DISABLED : ButtonType.COLORED}
            >
                {isLoading ? (
                    <CircularProgress color="inherit" size={30} />
                ) : (
                    <>
                        Export <span className='ml-3'><FileDownloadIcon /></span>
                    </>
                )}
            </Button>

            <CSVLink
                style={{ display: "none" }}
                data={data}
                headers={headers}
                ref={exportRef}
                filename={`clients_${moment().format("YYYYMMDD_HHmmss")}.csv`}
            >
                Download me
            </CSVLink>
        </div>
    );
}
