import React, { useState } from 'react'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import PasswordInput from '../components/form/password-input/PasswordInput';
import { setErrorMessages } from '../store/slices/error.slice';
import { resetPassword } from '../utilities/api';
import { setSuccessMessage } from '../store/slices/success.slice';
import AuthFormLayout from '../components/form/auth-layout/AuthFormLayout';
import { Link } from 'react-router-dom';


const schema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().min(6).required("Password is required"),
    password_confirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
}).required();


export default function RestPasswordForm({ token }: { token: string }) {

    const dispatch = useAppDispatch()
    const { user } = useAppSelector(s => s.user)

    const [isLoading, setLoading] = useState(false)
    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: "",
            password_confirmation: "",
        }
    });

    const onSubmit = async (data: any) => {
        setLoading(true)
        try {
            await resetPassword(data, token)
            reset()
            dispatch(setSuccessMessage("Password reset successfully"))
        } catch (error) {
            console.log("error = ", error)
            dispatch(setErrorMessages(error))
        } finally {
            setLoading(false)
        }
    }

    return (
        <AuthFormLayout
            title='Forget Password'
            subTitle="Reset your password here."
            onSubmit={handleSubmit(onSubmit)}
            button={{
                isDirty: true,
                loading: isLoading,
                isValid,
                text: "Submit"
            }}
        >
            <Grid
                container
                columnSpacing={{ xs: 0, md: 4 }}
                rowSpacing={{ md: 4, xs: 2 }}
                mb={{ xs: 2, md: 4 }}
            >

                <Grid item xs={12} >
                    <TextInput
                        control={control}
                        name={"email"}
                        error={errors.email?.message}
                        label={"Email"}
                        placeholder="ex email@gmail.com"
                    />
                </Grid>

                <Grid item xs={12} >
                    <PasswordInput
                        control={control}
                        name={"password"}
                        error={errors.password?.message}
                        label={"Password"}
                        placeholder="Password"
                    />
                </Grid>
                <Grid item xs={12} >
                    <PasswordInput
                        control={control}
                        name={"password_confirmation"}
                        error={errors.password_confirmation?.message}
                        label={"Confirm Password"}
                        placeholder="Confirm Password"
                    />
                </Grid>
            </Grid>

            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems={{ md: "center", xs: "flex-start" }}
                flexDirection={{ md: "row", xs: "column" }}
                mb={6}
                gap={{ md: 0, xs: 4 }}
            >

                <Link to="/login" className='auth-link'>
                    Back to login
                </Link>

            </Box>

        </AuthFormLayout>
    )
}
