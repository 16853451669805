import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import PageHeader from '../../components/page-header/PageHeader'
import ProfileForm from '../../forms/ProfileFrom'
import ChangePasswordForm from '../../forms/ChangePasswordForm'
import ProfileData from '../../components/profile/ProfileData'
import { IBreadcrumbList } from '../../components/breadcrumb/breadcrumb.props'
import { Box } from '@mui/material'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { employees } from '../../data/employees'
import { IEmployee } from '../../interfaces/employees.interface'
import EditEmployeeData from '../../components/employee/employee/EditEmployData'
import EditEmployeeForm from '../../forms/EditEmployeeForm'
import BackArrow from '../../components/back-arrow/BackArrow'
import { useAppDispatch } from '../../hooks/store.hook'
import { setErrorMessages } from '../../store/slices/error.slice'
import { getEmployeeById } from '../../utilities/api'

const breadcrumbList: IBreadcrumbList[] = [
    {
        type: "link",
        text: "Employees",
        path: "/employees"
    },
    {
        type: "text",
        text: "Edit employee",
        path: "/locations/employees/add"
    }
]

export default function EditEmployee() {


    const { id } = useParams()
    const dispatch = useAppDispatch()

    const [employee, setEmployee] = useState(null);
    const [status, setStatus] = useState(employee?.user_type || "")


    async function getEmployee() {

        try {
            const { data } = await getEmployeeById(+id)
            setStatus(data.user_type)
            setEmployee(data)
        } catch (error) {
            dispatch(setErrorMessages(error))
        }
    };


    useEffect(() => {
        getEmployee()
    }, [id])

    if (!employee) return null

    return (
        <PageLayout
        >
            <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                className='mb-8 '
            >
                <BackArrow />
                <Breadcrumb list={breadcrumbList} />
            </Box>


            <EditEmployeeData
                employee={employee}
                status={status}
                setStatus={(value: string) => setStatus(value)}
            />

            <EditEmployeeForm employee={employee} status={status} />
        </PageLayout>
    )
}
