import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function BackArrow() {

    const navigate = useNavigate()
    return (
        <Box
            component="div"
            sx={{
                display: {
                    lg: "none"
                }
            }}
        >
            <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon
                    style={{ color: "A01A1F" }}
                />
            </IconButton>
        </Box>
    )
}
