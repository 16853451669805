import { Box, Typography } from '@mui/material'
import React from 'react'
import Button from '../button/Button'
import { useAppDispatch } from '../../hooks/store.hook'
import { toggleSuccessModel } from '../../store/slices/model.slice'

export default function SuccessModel() {

    const dispatch = useAppDispatch()

    return (
        <Box
            component="div"
        >

            <Box
                component="div"
                className='flex justify-center mb-4 mx-auto'
                width={{ xs: "115px", md: "260px" }}
            >
                <img src="/media/success-icon.svg" className='max-w-full' />
            </Box>

            <Typography
                component="div"
                fontSize={{ xs: "10px", md: "22px" }}
                lineHeight="28px"
                color="#0C0C13"
                textAlign="center"
                fontWeight="600"
                mb={2}
            >
                "Your request has been completed."
            </Typography>

            <Box
                component="div"
                className='flex justify-center mx-auto'
                width={{ xs: "115px", md: "275px" }}
            >
                <img src="/media/happy-logo.png" className='max-w-full' />
            </Box>

            <Box
                width='200px'
                className='mx-auto mb-10'
            >
                <Button
                    onClick={() => dispatch(toggleSuccessModel())}

                >
                    Close
                </Button>
            </Box>


        </Box>
    )
}
