import Box from '@mui/material/Box'
import React, { useEffect, useRef, useState } from 'react'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook';
import { setErrorMessages } from '../../../store/slices/error.slice';
import { getInvoice } from '../../../utilities/api';
import { useReactToPrint } from 'react-to-print';
import Invoice from '../../invoice/Invoice';
import CircularProgress from '@mui/material/CircularProgress';

export default function Print({ orderId, className }: { orderId: number, className?: string }) {

    const { role } = useAppSelector(s => s.user)

    const dispatch = useAppDispatch()
    const [isLoading, setLoading] = useState(false)
    const componentRef = useRef<HTMLDivElement>(null);
    const [invoiceData, setInvoiceData] = useState<any>(null);



    async function onclick() {

        if (!orderId) return;

        setLoading(true)
        try {
            const { data } = await getInvoice(orderId)

            setInvoiceData({
                ...data,
                role
            })

        } catch (error) {
            dispatch(setErrorMessages(error))
        } finally {
            setLoading(false)
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (invoiceData) {
            handlePrint();
        }
    }, [invoiceData]);

    return (
        <div className={className}>
            <Box
                onClick={onclick}
                sx={{

                    background: "#0C0C13",
                    fontSize: "14px",
                    borderRadius: "12px",
                    p: "12px",
                    color: "#FFF",
                    cursor: "pointer",
                    minWidth: {
                        xs: "100%",
                        md: "70px"
                    },
                    display: "flex",
                    justifyContent: "center"
                }} >

                {isLoading ?
                    <CircularProgress size={20} sx={{ color: "#FFF" }} /> :
                    <>
                        Print
                        <LocalPrintshopOutlinedIcon sx={{
                            fontSize: "16px",
                            color: "#FFF",
                            ml: 1
                        }} />
                    </>}

            </Box>
            {invoiceData && (
                <div style={{ display: 'none' }}>
                    <Invoice ref={componentRef} {...invoiceData} />
                </div>
            )}
        </div>
    )
}
