import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Search from '../../filters/search/Search'
import { OrdersFilterProps } from './orders-filter.props'
import { createSearchQuery } from '../../../utilities/helper'
import moment from 'moment'
import { useAppSelector } from '../../../hooks/store.hook'
import { EmployeeRole } from '../../../interfaces/employees.interface'
import { useNavigate } from 'react-router-dom'

export default function OrdersFilter({
    updateFilter,
}: OrdersFilterProps) {

    const { role } = useAppSelector(s => s.user);

    const navigate = useNavigate()


    const [full_name, setFullName] = useState("")
    const [date, setDate] = useState(null)
    const [phone_number, setPhone] = useState("")
    const [ticket_number, setTacketNum] = useState("")
    const [location, setLocation] = useState("---");

    function setSearchQuery() {
        const query = createSearchQuery({
            full_name,
            location: location !== "---" ? location : "",
            phone_number,
            ticket_number,
            date: date ? moment(date).format("YYYY-MM-DD") : null
        });

        updateFilter(query)
        navigate("")
    }


    useEffect(() => {
        setSearchQuery()
    }, [date, location])
    return (
        <Grid container
            rowSpacing={{ xs: 2, md: 3 }}
            columnSpacing={3}
            mb={{ xs: 2, md: 4 }}
        >
            <Grid item xs={12} md={4}>
                <Search
                    label='Ticket No'
                    placeholder="Enter Ticket No"
                    onChange={(value) => setTacketNum(value)}
                    onClick={setSearchQuery}
                />
            </Grid>

            {
                role !== EmployeeRole.KITCHEN && <>
                    <Grid item xs={12} md={4}>
                        <Search
                            label='Phone Number'
                            placeholder="ex +971-50-1234567"
                            onChange={(value) => setPhone(value)}
                            onClick={setSearchQuery}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Search
                            label='Full name'
                            placeholder="Enter full name"
                            onChange={(value) => setFullName(value)}
                            onClick={setSearchQuery}
                        />
                    </Grid>
                </>
            }


        </Grid>
    )
}
