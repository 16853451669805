import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { LogoProps } from './logo.props'

export default function Logo({
    width = "175px",
    src = "/media/logo.png"
}: LogoProps) {
    return (
        <Box
            component="div"
            width={{ xs: "85px", md: width }}
        >
            <Link to="/">
                <img src={src} alt='logo' className='max-w-full' />
            </Link>
        </Box>

    )
}
