import { Box, Typography } from '@mui/material'
import React from 'react'
import Button from '../button/Button'
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook'
import { toggleNotificationModel } from '../../store/slices/model.slice'
import { EmployeeRole } from '../../interfaces/employees.interface'

export default function EnableNotificationModel() {

    const { role, user } = useAppSelector(s => s.user);
    const dispatch = useAppDispatch()

    if (role !== EmployeeRole.KITCHEN) return null;


    return (
        <Box
            component="div"
            padding={2}
        >

            <Typography
                component="div"
                fontSize={{ xs: "16px", md: "22px" }}
                lineHeight="28px"
                color="#0C0C13"
                textAlign="center"
                fontWeight="600"
                mb={2}
            >
                Welcome <span className='capitalize font-bold'>{user.name}</span>
            </Typography>

            <Typography
                component="div"
                fontSize={{ xs: "10px", md: "16px" }}
                lineHeight="20px"
                color="#0C0C13"
                textAlign="center"
                fontWeight="600"
                mb={{ xs: 3, md: 5 }}
            >
                Please click here to allow notification sound
            </Typography>

            <Box
                width='200px'
                className='mx-auto'
            >
                <Button
                    onClick={() => dispatch(toggleNotificationModel())}

                >
                    Close
                </Button>
            </Box>


        </Box>
    )
}
