// api/github.api.ts
import { useQuery } from '@tanstack/react-query';
import * as api from "../utilities/api"
import { createFullSearchQuery } from '../utilities/helper';
import { setErrorMessages } from '../store/slices/error.slice';
import { setEmployees } from '../store/slices/employee.slice';

export const getEmployees = async (dispatch: any, page: number, query: string) => {

    try {

        const searchQuery = createFullSearchQuery({ page, query })

        const { data } = await api.getEmployees(searchQuery);

        dispatch(setEmployees(data.data))

        return data;
    } catch (error) {
        dispatch(setErrorMessages(error))
        // throw setErrorFormat(error);
    }
};

export const useGetEmployees = (dispatch: any, page: number, query: string) => {

    return useQuery(['getEmployees', page, query], () => getEmployees(dispatch, page, query), {
        cacheTime: 5 * 60 * 1000, // 10 minutes,
        keepPreviousData: false, // Maintain previous data while fetching new data
        refetchOnWindowFocus: false
    });
};
