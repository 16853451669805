import { NavigationItems } from '../../../config/navigation';
import { NavigationProps } from './navigation.props';
import { DrawerHeader } from '../drawer-header/DrawerHeader';
import Logo from '../../../components/logo/Logo';
import NavListGroup from '../../../components/navigation/nav-list-group/NavListGroup';
import Drawer from '../drawer/Drawer';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { mobileBreakPoint } from '../../../utilities/constants';
import useWindowSize from '../../../hooks/useWindowSize';


function Navigation({ open, width, onClick }: NavigationProps) {
    const windowSize = useWindowSize()

    function closeWindow() {
        if (windowSize.width > 1024)
            return
        onClick()
    }
    return (
        <Drawer
            width={width}
            variant="permanent"
            open={open}
            onClick={closeWindow}

            sx={{
                height: "100%",
                ".MuiPaper-root": {
                    borderRight: "none",
                    [mobileBreakPoint]: {
                        width: "80%"
                    }
                },
                [mobileBreakPoint]: {
                    display: open ? "flex" : "none",
                    position: "fixed",
                    background: "rgba(12, 12, 19, 0.82)",
                    width: open ? "100%" : 0,
                    zIndex: "9999",
                    top: 0,
                    ".MuiPaper-root": {
                        mt: "90px",
                        borderRadius: "12px 12px 0 0"
                    },
                }
            }}
        >
            <DrawerHeader
                sx={{
                    minHeight: "96px !important",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    [mobileBreakPoint]: {
                        justifyContent: "space-between",
                        px: 3
                    }
                }}
            >



                {open ? <Logo width="122px" /> : <Logo src='/media/logo-short.png' width="auto" />}

                <IconButton
                    // onClick={onClick}
                    sx={{
                        display: "none",
                        [mobileBreakPoint]: {
                            display: "block"
                        }
                    }}
                >
                    <CancelIcon
                        sx={{
                            color: "#A01A1F"
                        }}
                    />
                </IconButton>
            </DrawerHeader>
            <NavListGroup
                list={NavigationItems}
                open={open}
                onClick={onClick}
            />

        </Drawer >
    )
}

export default Navigation