import { createSlice } from "@reduxjs/toolkit";
import { IClient } from "../../interfaces/client.interface";
import { updateClient } from "../thunk-actions/clients-action";


const initialState: {
    clients: IClient[],
    isLoading: boolean
} = {
    clients: [],
    isLoading: false
}

const slice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        setClients(state, action) {
            state.clients = action.payload;
        },
    },

    extraReducers: (builder) => {

        //update client
        builder.addCase(updateClient.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateClient.fulfilled, (state, action) => {
            state.isLoading = false;
            state.clients = state.clients.map(client => {
                if (client.id !== action.payload.id) return client;

                return action.payload
            })
        })
        builder.addCase(updateClient.rejected, (state, action) => {
            state.isLoading = false;
        })
    }
})

export default slice.reducer;

const {
    setClients,
} = slice.actions;

export {
    setClients,
}