import * as React from 'react';
import { DataGrid, GridColDef, GridRowHeightParams } from '@mui/x-data-grid';
import { TableProps } from './table.props';
import { mobileBreakPoint } from '../../utilities/constants';
import useWindowSize from '../../hooks/useWindowSize';
import { Box } from '@mui/material';

function countLines(string: string) {
    let count = 1;

    let chr;
    let i = 0, end = string.length;
    for (; i < end; ++i) {
        if (string[i] == '\n' || string[i] == '\r') {
            count = 2;
            chr = string[i];
            break;
        }
    }
    for (++i; i < end; ++i) {
        if (string[i] == chr) {
            ++count;
        }
    }
    return count;
}

export default function Table({ columns, rows, columnVisibilityModel = {}, isLoading = false }: TableProps) {

    const windowSize = useWindowSize();


    const getRowHeight = (params: GridRowHeightParams) => {

        if (Object.keys(params.model).length === 0) return "auto"

        if (params.model.order_details) {

            const minHeight = 70;
            const lineHeight = 14;
            const padding = 20; // extra padding for spacing
            const countLine = countLines(params.model.order_details) // assuming 40 chars per line
            const lines = Math.ceil(params.model.order_details.length / 38); // assuming 40 chars per line

            console.log("lines = ", lines)
            const contentHeight = Math.max(countLine, lines) * lineHeight + padding;

            return Math.max(minHeight, contentHeight);
        }

        return 70
    };


    return (
        <Box
            className='table-container'
            style={{ minWidth: "400px", minHeight: "200px" }}
            sx={{
                [mobileBreakPoint]: {
                    display: "none"
                },
            }}
        >
            {windowSize.width > 1024 && <DataGrid
                loading={isLoading}
                rows={rows}
                columnVisibilityModel={columnVisibilityModel}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10 },
                    }
                }}

                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                checkboxSelection={isLoading}
                disableColumnResize
                getRowHeight={getRowHeight}
                pageSizeOptions={[5, 10]}
                autoHeight
                sx={{
                    ".MuiDataGrid-cellCheckbox , .MuiDataGrid-columnHeaderCheckbox , .MuiDataGrid-footerContainer , .MuiDataGrid-scrollbar , .MuiDataGrid-filler": {
                        display: "none"
                    },

                    // ".MuiDataGrid-virtualScrollerRenderZone": {
                    //     zIndex: "9999"
                    // },

                    ".MuiDataGrid-overlayWrapper": {
                        minHeight: "100px"
                    },

                    [mobileBreakPoint]: {
                        display: "none"
                    },

                }}
            />}

        </Box>
    );
}
