// api/github.api.ts
import { useQuery } from '@tanstack/react-query';
import { setErrorFormat } from '../utilities/error-format';
import * as api from "../utilities/api"
import { setLocations } from '../store/slices/location.slice';
import { setBranches } from '../store/slices/branch.slice';
import { createFullSearchQuery } from '../utilities/helper';

export const getLocations = async (dispatch: any, page: number, query: string) => {

    try {

        const searchQuery = createFullSearchQuery({ page, limit: 9, query })

        const { data } = await api.getLocations(searchQuery);

        dispatch(setBranches(data.data))

        return data;
    } catch (error) {
        throw setErrorFormat(error);
    }
};

export const useGetBranches = (dispatch: any, page: number, query: string) => {

    return useQuery(['getBranches', page, query], () => getLocations(dispatch, page, query), {
        // staleTime: 5 * 60 * 1000, // 10 minutes
        cacheTime: 600000, // 10 minutes
        keepPreviousData: false,
        refetchOnWindowFocus: false
    });
};
