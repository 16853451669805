import { Box } from '@mui/material'
import moment from 'moment'

export default function Duration(
    { completed_at, created_at, picked_at }: { completed_at: string, created_at: string, picked_at: string }
) {


    const completedTime = completed_at ? moment(completed_at) :
        picked_at ? moment(picked_at) : null
    const createdTime = moment(created_at);

    const differenceDate = completedTime ? moment.duration(completedTime.diff(createdTime)) : null

    function calcDuration(duration: moment.Duration) {

        if (!duration) return;
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const parts = [];

        if (years > 0) {
            parts.push(`${years} Yr${years > 1 ? 's' : ''}`);
        }
        if (months > 0) {
            parts.push(`${months} Mon${months > 1 ? 'ths' : 'th'}`);
        }
        if (days > 0) {
            parts.push(`${days} Day${days > 1 ? 's' : ''}`);
        }
        if (hours > 0) {
            parts.push(`${hours} Hr${hours > 1 ? 's' : ''}`);
        }
        if (minutes > 0) {
            parts.push(`${minutes} Min${minutes > 1 ? 's' : ''}`);
        }
        if (seconds > 0 && !parts.length) {
            parts.push(`${seconds} Sec${seconds > 1 ? 's' : ''}`);
        }

        return parts.join(' ');
    }

    return (
        <Box
            component="span"
            maxWidth="100%"
            whiteSpace="pre-wrap"
            fontSize="12px"
        >
            {differenceDate && calcDuration(differenceDate)}

        </Box>
    )
}
