
export type props = {
    list: ListItem
    open: boolean
    my?: string
    onClick?: any
}

export interface ListItem {
    path: string,
    Icon: any
    text: string
    key: string
    type: ListItemType,
    items?: ListItem[],
    roles: string[]
}

export enum ListItemType {
    LINK = "link",
    MENU = "menu"
}