import { Box, Grid } from '@mui/material'
import Table from '../../table/Table'
import { columns } from './columns'
import { OrdersListProps } from './list.props'
import MobileView from '../views/MobileView'
import { useAppSelector } from '../../../hooks/store.hook'
import { EmployeeRole } from '../../../interfaces/employees.interface'
import Spinner from '../../spinner/Spinner'

export default function OrdersList({ orders, handelSorting, isLoading }: OrdersListProps) {

    const { role } = useAppSelector(s => s.user)


    return (
        <Box
            component="div"
        >

            <Table
                rowHight="auto"
                columns={columns(handelSorting, isLoading)}
                rows={orders}
                columnVisibilityModel={{
                    id: false, full_name: role !== EmployeeRole.KITCHEN,
                    phone_number: role !== EmployeeRole.KITCHEN,
                    action: role !== EmployeeRole.KITCHEN,
                }}
                isLoading={isLoading}
            />

            {isLoading ? <Box display={{ md: "none" }}> <Spinner /></Box> : <MobileView orders={orders} />}

        </Box>
    )
}
