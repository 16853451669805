import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../utilities/api"
import { ILocation } from "../../interfaces/locations";

//update user
export const updateBranch = createAsyncThunk<
    ILocation,
    { id: number, data: Partial<ILocation> },
    {
        rejectValue: unknown
    }
>("branch/updateBranch", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {


        const formData = new FormData();

        formData.append("area", payload.data.area)
        formData.append("city", payload.data.city)
        formData.append("message_create", payload.data.message_create)
        formData.append("message_ready", payload.data.message_ready)
        formData.append("message_survey", payload.data.message_survey)
        formData.append("name", payload.data.name)
        formData.append("phone_number", payload.data.phone_number)
        formData.append("location_prefix", payload.data.location_prefix)
        formData.append("location_img", payload.data.location_img);
        formData.append("state", payload.data.state);
        formData.append("is_disabled_create", payload.data.is_disabled_create ? "1" : "0");
        formData.append("is_disabled_ready_to_pick_up", payload.data.is_disabled_ready_to_pick_up ? "1" : "0");
        formData.append("is_disabled_survey", payload.data.is_disabled_survey ? "1" : "0");


        const { data } = await api.updateLocation(payload.id, formData)
        return data.location;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

//add branch
export const addBranch = createAsyncThunk<
    ILocation,
    ILocation,
    {
        rejectValue: unknown
    }
>("brach/addBranch", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {

        const formData = new FormData();

        formData.append("area", payload.area)
        formData.append("city", payload.city)
        formData.append("message_create", payload.message_create)
        formData.append("message_ready", payload.message_ready)
        formData.append("message_survey", payload.message_survey)
        formData.append("name", payload.name)
        formData.append("phone_number", payload.phone_number)
        formData.append("location_prefix", payload.location_prefix)
        formData.append("location_img", payload.location_img)
        formData.append("is_disabled_create", payload.is_disabled_create ? "1" : "0");
        formData.append("is_disabled_ready_to_pick_up", payload.is_disabled_ready_to_pick_up ? "1" : "0");
        formData.append("is_disabled_survey", payload.is_disabled_survey ? "1" : "0");

        const { data } = await api.addLocation(formData)

        return data.location;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})
