import PageHeader from '../../components/page-header/PageHeader'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import AddOrderForm from '../../forms/AddOrderForm'

export default function AddOrder() {
    return (
        <PageLayout>

            <PageHeader
                title='New Order'
            />
            <AddOrderForm />
        </PageLayout>
    )
}
