import { Grid } from '@mui/material'
import { useState } from 'react'
import Search from '../../filters/search/Search'
import { createSearchQuery } from '../../../utilities/helper'
import { clientsFilterProps } from './client-filter.props'
import ExportButton from '../export-btn/ExportButton'
import { useNavigate } from 'react-router-dom'

export default function ClientFilter({
    updateFilter,
    query = ""
}: clientsFilterProps) {

    const navigate = useNavigate()


    const [full_name, setFullName] = useState("")
    const [phone_number, setPhone] = useState("")


    function setSearchQuery() {
        const query = createSearchQuery({
            limit: 10,
            page: 1,
            full_name,
            phone_number,
        })

        updateFilter(query)
        navigate("")
    }

    return (

        <Grid container
            rowSpacing={{ xs: 2, md: 3 }}
            columnSpacing={3}
            mb={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={4}>
                <Search
                    label='Full name'
                    placeholder="Enter full name"
                    onChange={(value) => setFullName(value)}
                    onClick={setSearchQuery}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Search
                    label='Phone Number'
                    placeholder="ex +201112345678"
                    onChange={(value) => setPhone(value)}
                    onClick={setSearchQuery}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ExportButton query={query} />
            </Grid>
        </Grid>
    )
}
