import React from 'react'
import PaginationWrap from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { PaginationProps } from './pagination.props';
import { Link, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { mobileBreakPoint } from '../../utilities/constants';

export default function Pagination({ count, page }: PaginationProps) {



    if (count === 1) return null
    return (
        <Box
            mt={{ xs: 2, md: 4 }}
            display="flex"
            justifyContent={{ xs: "center", md: "flex-end" }}
        >
            <PaginationWrap
                page={page}
                count={count}
                renderItem={(item) => (
                    <PaginationItem
                        sx={{
                            border: "1px solid #BABCD4",
                            height: "50px",
                            width: "50px",
                            mx: "5px",
                            "&.Mui-selected": {
                                color: "#FFF",
                                backgroundColor: "#A01A1F",
                                height: "50px",
                                width: "50px",
                                [mobileBreakPoint]: {
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "12px"
                                }
                            },
                            "&.MuiPaginationItem-previousNext": {
                                border: "1px solid #BABCD4",
                                height: "50px",
                                width: "50px",
                                [mobileBreakPoint]: {
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "12px"
                                }
                            },
                            "&.Mui-selected:hover": {
                                backgroundColor: "rgba(160, 26, 31, 0.8)"
                            },
                            [mobileBreakPoint]: {
                                height: "40px",
                                width: "40px",
                                borderRadius: "12px"
                            }
                        }}
                        component={Link}
                        to={item.page === 1 ? "" : `?page=${item.page}`}

                        // to={`/users${item.page === 1 ? '' : `?page=${item.page}`}`}
                        {...item}
                    />
                )}
            />
        </Box>

    )
}
