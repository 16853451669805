import React, { useState } from 'react'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import { useForm, Controller } from "react-hook-form";
import { PasswordInputProps } from './pasword-input.props';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function PasswordInput({
    control,
    name,
    error,
    label,
    placeholder = "",
    width = "100%",
    className = ""
}: PasswordInputProps) {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box
            className={`${className}`}
            component="div"
            width={width}
        >
            <Controller
                name={name}
                control={control}
                render={({ formState, field }) => <TextField
                    label={label}
                    variant="outlined"
                    placeholder={placeholder}
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    error={error ? true : false}
                    helperText={error}
                    sx={{
                        p: "0",
                        height: "55px",
                        "fieldset": {
                            borderColor: "gree#D7E7FA",
                            borderRadius: "12px"
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset>legend": {
                                fontSize: "9px", //or whatever works for you
                            },
                        }
                    }}
                    InputLabelProps={{
                        shrink: true,
                        sx: {
                            fontSize: "10px",
                            color: "#8E92B9",
                            left: "3px",
                            top: "3px"
                        },
                    }}
                    InputProps={{
                        sx: {
                            "input": { px: 0 },
                            px: "24px",
                            fontSize: "17px",
                            color: "#4A4F78",
                            height: "100%"
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...field}
                />

                }
            />
        </Box>
    )
}


{/* <TextField
error={error !== ""}
helperText={error}
placeholder={placeholder}
sx={{
    display: "block",
    mb: 3,
    py: 0,
    ".MuiOutlinedInput-input": {
        textTransform: capitalize ? "capitalize" : "none"
    }
}}
variant="outlined"
type={buttonType}
fullWidth
{...field}

className={`${error ? style["error-input"] : ""}`}
/> */}