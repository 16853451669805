import { createSlice } from "@reduxjs/toolkit";
import { login } from "../thunk-actions/auth-actions";
import { setErrorFormat } from "../../utilities/error-format";
import { getUser, updateProfile, updateUser, updateUserImage } from "../thunk-actions/user-action";
import { addOrder, updateOrder, updateOrderStatus } from "../thunk-actions/order-action";
import { updateClient } from "../thunk-actions/clients-action";
import { addBranch, updateBranch } from "../thunk-actions/branch-action";
import { addEmployee, updateEmployee, updateUserType } from "../thunk-actions/employee-action";


const initialState: { messages: string[], isOpen: boolean } = {
    isOpen: false,
    messages: []
}

const slice = createSlice({
    name: "error",
    initialState,
    reducers: {
        setErrorMessages(state, action) {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        },
        clearErrorMessages(state) {
            state.isOpen = false;
            state.messages = []
        },
    },
    extraReducers: (builder) => {

        //sign in errors
        builder.addCase(login.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //get user errors
        builder.addCase(updateUser.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //update order status errors
        builder.addCase(updateOrderStatus.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //update order errors
        builder.addCase(updateOrder.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //add order errors
        builder.addCase(addOrder.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //update client errors
        builder.addCase(updateClient.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //add branch errors
        builder.addCase(addBranch.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //update branch errors
        builder.addCase(updateBranch.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //employee errors
        builder.addCase(addEmployee.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        builder.addCase(updateUserType.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        builder.addCase(updateEmployee.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        builder.addCase(updateProfile.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        builder.addCase(updateUserImage.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

    }
})

export default slice.reducer;

const {
    setErrorMessages,
    clearErrorMessages
} = slice.actions;

export {
    setErrorMessages,
    clearErrorMessages
}