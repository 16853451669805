import { Box, Grid, Stack } from '@mui/material'
import moment from 'moment'
import { mobileBreakPoint } from '../../../utilities/constants'
import Card from '../../card/card/Card'
import CardContent from '../../card/card-content/CardContent'
import { IClient } from '../../../interfaces/client.interface'
import ClientName from '../client-name/ClientName'

export default function MobileView({ clients }: { clients: IClient[] }) {
    return (
        <Box
            component="div"
            sx={{
                display: "none",
                [mobileBreakPoint]: {
                    display: "block"
                }
            }}
        >
            <Stack
                spacing={1}
            >
                {clients.map(client => <Card
                    key={client.id}
                >
                    <Grid container>
                        <Grid item xs={6}>
                            <CardContent
                                dark={true}
                                titleNode={<ClientName client={client} text="Client Name" />}
                                text={client.full_name}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CardContent
                                title='Added Dated'
                                dark={true}
                                text={moment(client.created_at).format("DD/MM/YYYY")}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CardContent
                                title="Phone Number"
                                text={client.phone_number}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CardContent
                                title="Completed Orders"
                                textNode={<Box
                                    component="div"
                                    sx={{
                                        padding: "4px 8px",
                                        backgroundColor: "rgba(92, 190, 67, 0.2)",
                                        color: "#5CBE43",
                                        fontSize: "10px",
                                        borderRadius: "10px",
                                        width: "fit-content"
                                    }}
                                >
                                    {client.completed_orders || 0}
                                </Box>}
                            />
                        </Grid>

                    </Grid>
                </Card>)}

            </Stack>
        </Box>
    )
}
