import React, { useEffect, useState } from 'react'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import { Box } from '@mui/material'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { IBreadcrumbList } from '../../components/breadcrumb/breadcrumb.props'
import EditClientForm from '../../forms/EditeClientForm'
import { mobileBreakPoint } from '../../utilities/constants'
import BackArrow from '../../components/back-arrow/BackArrow'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/store.hook'
import { setErrorMessages } from '../../store/slices/error.slice'
import { getClientById } from '../../utilities/api'

const breadcrumbList: IBreadcrumbList[] = [
    {
        type: "link",
        text: "Clients",
        path: "/clients"
    },
    {
        type: "text",
        text: "Edit Client Info",
        path: "/clients/:id"
    }
]

export default function EditClient() {


    const dispatch = useAppDispatch()

    const { id } = useParams();

    const [client, setClient] = useState(null);

    async function getClient() {

        try {
            const { data } = await getClientById(+id)
            setClient(data)
        } catch (error) {
            dispatch(setErrorMessages(error))
        }
    };


    useEffect(() => {
        getClient()
    }, [id])

    if (!client) return null
    return (
        <PageLayout>
            <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                sx={{
                    [mobileBreakPoint]: {
                        mt: "20px"
                    }
                }}
            >
                <BackArrow />
                <Breadcrumb list={breadcrumbList} />
            </Box>

            <EditClientForm client={client} />


        </PageLayout>
    )
}
