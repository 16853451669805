import React from 'react'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import { Box } from '@mui/material'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { IBreadcrumbList } from '../../components/breadcrumb/breadcrumb.props'
import AddEmployeeForm from '../../forms/AddEmployeeForm'
import AddBranchForm from '../../forms/AddBranchForm'
import BackArrow from '../../components/back-arrow/BackArrow'

const breadcrumbList: IBreadcrumbList[] = [
    {
        type: "link",
        text: "Branches",
        path: "/branches"
    },
    {
        type: "text",
        text: "Add New Branch",
        path: "/locations/branches/add"
    }
];

export default function AddBranch() {
    return (
        <PageLayout>
            <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                className='mt-4 md:mt-0'
            >
                <BackArrow />
                <Breadcrumb list={breadcrumbList} />
            </Box>

            <AddBranchForm />


        </PageLayout>
    )
}
