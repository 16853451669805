import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { AppBarProps } from './app-bar.props';
import { mobileBreakPoint } from '../../../utilities/constants';


interface AppBarComponentProps extends MuiAppBarProps {
    open?: boolean;
    width: number
}

const AppBarComponent = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarComponentProps>(({ theme, open, width }) => ({
    backgroundColor: "#FFF",
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${width}px)`,
        marginLeft: `${width}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [mobileBreakPoint]: {
            marginLeft: 0,
            width: "100%"
        }
        // [theme.breakpoints.up('sm')]: {

        // }
    }),
}));


export default function AppBar({ open, onClick, width, children }: AppBarProps) {
    return (
        <AppBarComponent
            position="fixed"
            open={open}
            width={width}
            sx={{
                boxShadow: "none",
                ".MuiContainer-root": {
                    maxWidth: "100%"
                }
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onClick}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon color="error" />
                </IconButton>
                {children}
            </Toolbar>
        </AppBarComponent>
    )
}
