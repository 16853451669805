import React from 'react'
import { FormLayoutProps } from './form-layout.props'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import SubmitButton from '../submit-button/SubmitButton'
import Button from '../../button/Button'
import { ButtonType } from '../../button/button.props'
import { mobileBreakPoint } from '../../../utilities/constants'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import Status from '../../status/Status'
import Print from '../print/Print'
export default function FormLayout({
    title,
    children,
    onSubmit,
    button,
    p,
    fontSize = "24px",
    withCancel = false,
    className = "",
    onClick,
    withPrint,
    status,
    orderId
}: FormLayoutProps) {
    return (
        <Box
            className={`${className}`}
            component="div"
            mt={{ xs: 4, md: 7 }}
            sx={{
                backgroundColor: "#FFF",
                p: p ? p : "65px 92px",
                border: "1px solid #D4E6FF",
                borderRadius: "12px",
                [mobileBreakPoint]: {
                    p: "32px 10px"
                }
            }}
        >

            <Box
                component="div"
                sx={{
                    pb: 3,
                    borderBottom: "1px solid #D4E6FF",
                    mb: 4,
                    display: "flex",
                    alignItems: {
                        xs: "flex-start", md: "center"
                    },
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    flexDirection: {
                        md: "row", xs: "column"
                    }
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%"
                    }}
                >
                    <Box
                        display="flex"
                        gap={2}
                        alignItems="center"
                    >
                        <Typography
                            variant='h2'
                            component="div"
                            lineHeight="20px"
                            fontSize={{ xs: "18px", md: fontSize }}
                            fontWeight="600"
                            color="#0C0C13"
                            textTransform="capitalize"
                        >
                            {title}
                        </Typography>

                        {withPrint && <Print className='hidden md:flex' orderId={orderId} />}
                    </Box>
                    {status && <Status status={status} />}
                </Box>


                {withPrint && <Print className='flex md:hidden w-full mt-4' orderId={orderId} />}


            </Box>


            <Box
                component="form"
                onSubmit={onSubmit}
                width="100%"
            >
                {children}

                <Grid container
                    width={{ xs: "100%", md: withCancel ? "50%" : "100%" }}
                    rowSpacing={{ xs: 2, md: 3 }}
                    columnSpacing={{ xs: 0, md: withCancel ? 3 : 0 }}
                >
                    <Grid item
                        xs={12}
                        md={withCancel ? 6 : 12}
                    >
                        <SubmitButton {...button} />
                    </Grid>
                    {withCancel && <Grid item xs={12} md={6} >
                        <Button
                            onClick={onClick}
                            type={ButtonType.OUTLINE}
                            width='100%'
                        >
                            Cancel
                        </Button>
                    </Grid>}
                </Grid>

            </Box>


        </Box >
    )
}
