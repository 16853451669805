import React from 'react'
import { PageLayoutProps } from './page-layout.props'
import { Box } from '@mui/material'
import PageHeader from '../../../components/page-header/PageHeader'

export default function PageLayout({ children }: PageLayoutProps) {
    return (
        <Box
            component="div"
            position="relative"
        >
            <Box
                component="div"
            >
                {children}
            </Box>
        </Box>
    )
}
