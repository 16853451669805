import { Box, IconButton, TextField } from '@mui/material'
import React from 'react'
import { SearchProps } from './search.prop'
import SearchIcon from '@mui/icons-material/Search';


export default function Search({
    label,
    placeholder,
    onChange,
    onClick,
    width = "100%"
}: SearchProps) {
    return (
        <Box
            component="div"
            height="55px"
            width={width}
        >
            <TextField
                label={label}
                variant="outlined"
                placeholder={placeholder}
                fullWidth
                onChange={(e) => onChange(e.target.value)}
                sx={{
                    "fieldset": {
                        borderColor: "gree#D7E7FA",
                        borderRadius: "12px"
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset>legend": {
                            fontSize: "8px",
                        },
                    },

                }}
                InputLabelProps={{
                    shrink: true,
                    sx: {
                        color: "#8E92B9",
                        '&.MuiInputLabel-shrink': {
                            fontSize: "10px",

                        },
                        left: "3px",
                        top: "3px"
                    },
                }}
                InputProps={{
                    sx: {
                        "input": {
                            px: "24px",
                            "&:-webkit-autofill": {
                                WebkitBoxShadow: "0 0 0 1000px white inset"
                            }
                        },
                        fontSize: "17px",
                        color: "#4A4F78",
                        height: "55px"
                    },
                    endAdornment: <IconButton sx={{ p: 0 }} onClick={onClick}>
                        <SearchIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                }}
            />
        </Box>

    )
}
