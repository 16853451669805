import React, { useEffect, useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { phonePlaceHolder, phoneValidation, phoneValidationMsg } from '../utilities/constants';
import { updateProfile } from '../store/thunk-actions/user-action';
import { setSuccessMessage } from '../store/slices/success.slice';


const schema = yup.object({
    name: yup.string().required("Full name is required"),
    email: yup.string().email().required("Email is required"),
    phone_number: yup.string().matches(phoneValidation, phoneValidationMsg).required("Phone number is required")

}).required();


export default function ProfileForm() {

    const dispatch = useAppDispatch()
    const { user } = useAppSelector(s => s.user)

    const [isLoading, setLoading] = useState(false)
    //form part
    const { control, handleSubmit, watch, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            name: user.name,
            email: user.email,
            phone_number: user.phone_number,
        }
    });

    const onSubmit = (data: any) => {
        setLoading(true)
        dispatch(updateProfile({ id: user.id, data })).unwrap()
            .then(() => dispatch(setSuccessMessage("Profile Update Successfully")))
            .finally(() => setLoading(false))
    }




    return (
        <FormLayout
            title='Edit Profile'
            onSubmit={handleSubmit(onSubmit)}
            p={3}
            fontSize='16px'
            withCancel={true}
            onClick={() => reset()}
            button={{
                isDirty,
                loading: isLoading,
                isValid
            }}
        >
            <Grid
                container
                rowSpacing={{ md: 3, xs: 2 }}
                columnSpacing={{ md: 3, xs: 0 }}
                mb={{ xs: 2, md: 4 }}
            >
                <Grid item xs={12} md={12} >
                    <TextInput
                        control={control}
                        name={"name"}
                        error={errors.name?.message}
                        label={"Full Name"}
                        placeholder='Full name'
                    />
                </Grid>
                <Grid item xs={12} md={6} >
                    <TextInput
                        control={control}
                        name={"email"}
                        error={errors.email?.message}
                        label={"Email"}
                        placeholder='Email'
                    />
                </Grid>
                <Grid item xs={12} md={6} >
                    <TextInput
                        control={control}
                        name={"phone_number"}
                        error={errors.phone_number?.message}
                        label={"Phone"}
                        placeholder={phonePlaceHolder}
                        mask="+\9\71-99-9999999"
                    />
                </Grid>
            </Grid>
        </FormLayout>
    )
}
