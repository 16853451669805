import { Box } from '@mui/material'
import { useState } from 'react'
import { CardProps } from './card.props'
import style from "./Card.module.css";
import { slugName } from '../../../utilities/helper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StatusList from '../../orders/order-status/StatusList';
import { OrderStatus } from '../../../utilities/constants';

export default function Card({
    withHeader = false,
    status,
    time,
    children,
    onClick = () => { return },
    order,
    withFooter = false,
    footerEvent,
    cardCollops
}: CardProps) {

    const [open, setOpen] = useState(false)

    return (
        <Box
            component="div"
            sx={{
                borderRadius: "12px",
                border: "1px solid #BABCD4",
                overflow: "hidden",
            }}
            onClick={onClick}
        >

            {withHeader && <Box
                component="div"
                className={`${style.header} ${style[slugName(status)]}`}
                position="relative"
                onClick={() => setOpen(s => !s)}
            >
                <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                >
                    <div>{status}</div>
                    {order && order?.status !== OrderStatus.DONE ? open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
                </Box>
                {(time && status === OrderStatus.DONE) && <div>Pick up time{time}</div>}

                {order && OrderStatus.DONE !== order?.status ? open && <StatusList order={order} /> : null}

            </Box>}

            <Box
                component="div"
            >
                {children}
            </Box>

            {withFooter && <Box
                component="div"
                className={`${style.header} ${style[slugName(status)]}`}
                position="relative"
                onClick={footerEvent}
            >

                {cardCollops ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

            </Box>}

        </Box>
    )
}
