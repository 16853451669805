import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { ListItem, ListItemType } from '../components/navigation/nav-list/props';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LoopIcon from '@mui/icons-material/Loop';
import { EmployeeRole } from '../interfaces/employees.interface';

export const NavigationItems: ListItem[] = [
    {
        path: "/",
        key: "reports-index",
        text: "Reports",
        Icon: AssessmentOutlinedIcon,
        type: ListItemType.MENU,
        roles: ["admin", "superadmin"],
        items: [
            {
                path: "/",
                key: "reports.all",
                text: "All Orders",
                Icon: SummarizeIcon,
                type: ListItemType.LINK,
                roles: ["admin", "superadmin"]
                ,
            },
            {
                path: "/reports/done",
                key: "reports.done",
                text: "Done Orders",
                Icon: CheckCircleOutlineIcon,
                type: ListItemType.LINK,
                roles: ["admin", "superadmin"]
                ,
            },
            {
                path: "/reports/processing",
                key: "reports.processing",
                text: "Processing Orders",
                Icon: LoopIcon,
                type: ListItemType.LINK,
                roles: ["admin", "superadmin"]
                ,
            },
            {
                path: "/reports/canceled",
                key: "reports.canceled",
                text: "Canceled Orders",
                Icon: CancelOutlinedIcon,
                type: ListItemType.LINK,
                roles: ["admin", "superadmin"]
                ,
            },
        ]
    },
    {
        path: "/orders",
        key: "orders-index",
        text: "Orders",
        Icon: AssessmentOutlinedIcon,
        type: ListItemType.MENU,
        roles: [EmployeeRole.salesman, EmployeeRole.KITCHEN, EmployeeRole.SUPERVISOR],
        items: [
            // {
            //     path: "/orders",
            //     key: "orders.all",
            //     text: "All Orders",
            //     Icon: SummarizeIcon,
            //     type: ListItemType.LINK,
            //     roles: [EmployeeRole.salesman, EmployeeRole.KITCHEN],
            // },
            {
                path: "/orders/processing",
                key: "orders.processing",
                text: "Processing Orders",
                Icon: LoopIcon,
                type: ListItemType.LINK,
                roles: [EmployeeRole.salesman, EmployeeRole.KITCHEN, EmployeeRole.SUPERVISOR],
            },
            {
                path: "/orders/done",
                key: "orders.done",
                text: "Done Orders",
                Icon: CheckCircleOutlineIcon,
                type: ListItemType.LINK,
                roles: [EmployeeRole.salesman, EmployeeRole.KITCHEN, EmployeeRole.SUPERVISOR],
            },

        ]
    },
    // {
    //     path: "/locations",
    //     key: "locations",
    //     text: "Locations",
    //     Icon: PlaceOutlinedIcon,
    //     type: ListItemType.MENU,
    //      roles: ["admin" , "superadmin"]
    ,
    //     items: [
    //         {
    //             path: "/locations/branches",
    //             key: "branches",
    //             text: "Branches",
    //             Icon: StorefrontOutlinedIcon,
    //             type: ListItemType.LINK,
    //              roles: ["admin" , "superadmin"]
    ,
    //         },
    //         {
    //             path: "/locations/employees",
    //             key: "employees",
    //             text: "Employees",
    //             Icon: BadgeOutlinedIcon,
    //             type: ListItemType.LINK,
    //              roles: ["admin" , "superadmin"]
    ,
    //         },
    //     ]
    // },
    {
        path: "/branches",
        key: "branches",
        text: "Branches",
        Icon: StorefrontOutlinedIcon,
        type: ListItemType.LINK,
        roles: ["admin", "superadmin"]
        ,
    },
    {
        path: "/employees",
        key: "employees",
        text: "Employees",
        Icon: BadgeOutlinedIcon,
        type: ListItemType.LINK,
        roles: ["admin", "superadmin"]
        ,
    },
    {
        path: "/clients",
        key: "clients",
        text: "Clients",
        Icon: GroupOutlinedIcon,
        type: ListItemType.LINK,
        roles: ["admin", "superadmin"]
        ,
    },
]