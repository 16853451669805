import React from 'react'
import { FormLayoutProps } from './form-layout.props'
import { Box, Typography } from '@mui/material'
import SubmitButton from '../submit-button/SubmitButton'
import { mobileBreakPoint } from '../../../utilities/constants'

export default function AuthFormLayout({
    title,
    subTitle,
    children,
    onSubmit,
    button,
    className = "",
}: FormLayoutProps) {
    return (
        <Box
            className={`${className}`}
            component="div"
            width="100%"
        >

            <Box
                component="div"
                sx={{
                    mb: 5,
                    [mobileBreakPoint]: {
                        marginBottom: 4
                    }
                }}
            >
                <Typography
                    variant='h2'
                    component="div"
                    lineHeight="48px"
                    fontSize={{ xs: "24px", md: "40px" }}
                    fontWeight="600"
                    color="#0C0C13"
                >
                    {title}
                </Typography>

                {subTitle && <Typography
                    variant='h2'
                    component="div"
                    mt={{ md: 1, xs: "4px" }}
                    lineHeight="24px"
                    fontSize="16px"
                    fontWeight="400"
                    color="#64748B"
                >
                    {subTitle}
                </Typography>}
            </Box>


            <Box
                component="form"
                onSubmit={onSubmit}
                width="100%"
            >
                {children}

                <SubmitButton {...button} />

            </Box>


        </Box >
    )
}
