import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook';
import PageHeader from '../../components/page-header/PageHeader';
import PageLayout from '../../layouts/layout/page-layout/PageLayout';
import EditOrderForm from '../../forms/EddOrderForm';
import { setErrorMessages } from '../../store/slices/error.slice';
import { getOrderById } from '../../utilities/api';
import pusher from '../../utilities/pusher';
import { IOrder } from '../../interfaces/order.interface';

export default function EditOrder() {

    const dispatch = useAppDispatch()
    const { user } = useAppSelector(s => s.user)

    const { id } = useParams();

    const [order, setOrder] = useState(null);

    async function getOrder() {

        try {
            const { data } = await getOrderById(+id)
            setOrder(data)
        } catch (error) {
            dispatch(setErrorMessages(error))
        }
    };

    function updateEventHandler(data: IOrder) {


        if (data.id !== +id) return;
        console.log(",ihvmi")

        setOrder((s: any) => ({
            ...s,
            ...data
        }))
    }

    useEffect(() => {
        getOrder()
    }, [id])

    useEffect(() => {
        const channel = pusher.subscribe(`employee-update-${user.location}`);
        const updateEvent = "order.updated"

        channel.bind(updateEvent, updateEventHandler);

        return () => {
            channel.unbind(updateEvent, updateEventHandler);

            channel.unsubscribe();
        };
    }, [id, user.location]);


    if (!order) return null;
    return (
        <PageLayout>

            <PageHeader
                title='Order Details'
            />

            <EditOrderForm order={order} />


        </PageLayout>
    )
}
