import { number } from 'yup';
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isOpen: false,
}

const slice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        toggleOpen: (state) => {
            state.isOpen = !state.isOpen;
        },

    },
})

export default slice.reducer;

const {
    toggleOpen
} = slice.actions;

export {
    toggleOpen
}