import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import DateFilter from '../../filters/date/DateFilter'
import DropDown from '../../filters/drop-down/DropDown'
import Search from '../../filters/search/Search'
import { ReportFilterProps } from './report-filter.props'
import { createSearchQuery, generateLocationMenu } from '../../../utilities/helper'
import moment from 'moment'
import ExportButton from '../export-btn/ExportButton'
import { useAppSelector } from '../../../hooks/store.hook'
import { IOrder } from '../../../interfaces/order.interface'
import { useNavigate } from 'react-router-dom'


export default function ReportFilter({
    updateFilter,
    isAll,
    query
}: ReportFilterProps) {

    const navigate = useNavigate()

    const { locations } = useAppSelector(s => s.location)

    const [full_name, setFullName] = useState("")
    const [start_date, setDate] = useState(null)
    const [end_date, setEndDate] = useState(null)
    const [phone_number, setPhone] = useState("")
    const [ticket_number, setTacketNum] = useState("")
    const [location, setLocation] = useState("---");

    function setSearchQuery() {

        console.log("end_date = ", end_date)
        const query = createSearchQuery({
            full_name,
            location: location !== "---" ? location : "",
            phone_number,
            ticket_number,
            start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
            end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : null,
        });

        updateFilter(query)

        navigate("")
    }


    useEffect(() => {
        setSearchQuery()
    }, [start_date, end_date, location])
    return (
        <Grid container
            rowSpacing={{ xs: 2, md: 3 }}
            columnSpacing={3}
            mb={{ xs: 2, md: 4 }}
        >
            <Grid item xs={12} md={4}>
                <DropDown
                    label='Branch Name'
                    value={location}
                    onChange={(value) => setLocation(value)}
                    menu={generateLocationMenu(locations)}
                />
            </Grid>
            <Grid container item xs={12} md={4} columnSpacing={2}>
                <Grid item columnSpacing={3} xs={6}>
                    <DateFilter
                        label='From Date'
                        value={start_date}
                        onChange={(value) => setDate(value)}
                    />
                </Grid>
                <Grid item columnSpacing={3} xs={6}>
                    <DateFilter
                        label='To Date'
                        value={end_date}
                        onChange={(value) => setEndDate(value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Search
                    label='Phone'
                    placeholder="ex +971-50-1234567"
                    onChange={(value) => setPhone(value)}
                    onClick={setSearchQuery}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Search
                    label='Ticket No'
                    placeholder="Enter Ticket No"
                    onChange={(value) => setTacketNum(value)}
                    onClick={setSearchQuery}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Search
                    label='Full name'
                    placeholder="Enter full name"
                    onChange={(value) => setFullName(value)}
                    onClick={setSearchQuery}
                />
            </Grid>
            {!isAll && <Grid item xs={12} md={4}>
                <ExportButton query={query} />
            </Grid>}
        </Grid>
    )
}
