import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import PasswordInput from '../components/form/password-input/PasswordInput';
import AuthFormLayout from '../components/form/auth-layout/AuthFormLayout';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { login } from '../store/thunk-actions/auth-actions';


const schema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
    // location: yup.number().nullable().transform(value => (isNaN(value) ? null : value)).optional(),

}).required();


export default function LoginForm() {

    const {
        // location: { locations },
        auth: { isLoading },
        // user: { role }
    } = useAppSelector(s => s)

    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: "",
            // location: "---"
        }
    });

    const onSubmit = (data: any) => {
        dispatch(login({ payload: data })).unwrap()
            .then((data: any) => {
                navigate(data.user_type === "admin" ? "/" : "/orders/processing")
            })
    }

    return (
        <AuthFormLayout
            title='Sign in'
            subTitle='Sign in to your account to start using Almwashi'
            onSubmit={handleSubmit(onSubmit)}
            button={{
                isDirty: true,
                loading: isLoading,
                isValid,
                text: "Sign in"
            }}
        >

            <Stack spacing={{ md: 4, xs: 4 }} mb={{ xs: 4, md: 4 }}>


                <TextInput
                    control={control}
                    name={"email"}
                    error={errors.email?.message}
                    label={"Email"}
                    placeholder="ex email@gmail.com"
                />

                <PasswordInput
                    control={control}
                    name={"password"}
                    error={errors.password?.message}
                    label={"Password"}
                    placeholder="Password"
                />

                {/* <SelectInput
                    control={control}
                    name={"location"}
                    error={errors.location?.message}
                    label={"Location"}
                    menu={generateLocationMenu(locations)}
                /> */}

            </Stack>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems={{ md: "center", xs: "flex-start" }}
                flexDirection={{ md: "row", xs: "column" }}
                mb={6}
                gap={{ md: 0, xs: 4 }}
            >
                <FormControlLabel
                    control={<Checkbox />}
                    label="Keep me signed in"
                    sx={{
                        color: "#64748B",
                        fontSize: "18px"
                    }}
                />

                <Link to="/forget-password" className='auth-link'>
                    Forgot Password?
                </Link>

            </Box>

        </AuthFormLayout>
    )
}
