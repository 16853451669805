import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import PageHeader from '../../components/page-header/PageHeader'
import { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Pagination from '../../components/pagination/Pagination'
import { Box } from '@mui/material'
import Button from '../../components/button/Button'
import AddIcon from '@mui/icons-material/Add';
import OrdersFilter from '../../components/orders/filter/OrdersFilter'
import OrdersList from '../../components/orders/list/OrdersList'
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook'
import OrderFooter from '../../components/orders/order-footer/OrderFooter'
import { EmployeeRole } from '../../interfaces/employees.interface'
import { useGetOrders } from '../../hooks/get-orders.hook'
import { ISorting } from '../../interfaces/sorting.interface'
import { updateQueryStringParameter } from '../../utilities/helper'
import usePusher from '../../hooks/usePusher'
import { useGetOrdersCount } from '../../hooks/get-orders-count.hook'

export default function StatusOrder() {

    //setup redux options
    const dispatch = useAppDispatch()
    const { orders, doneCount, processingCount } = useAppSelector(s => s.order);
    const { role, user } = useAppSelector(s => s.user)

    const navigate = useNavigate()

    //get order status
    const { status } = useParams();

    //get page param
    const [searchParams] = useSearchParams();
    const page = parseInt(searchParams.get("page") || '1', 10);

    //set query
    const [query, setQuery] = useState("");

    const [sorting, setSorting] = useState<ISorting>({
        sort_by: "",
        sort: "ASC"
    });


    function handelSorting(value: string) {
        setSorting(s => ({
            sort_by: value,
            sort: s.sort === "ASC" ? "DESC" : "ASC"
            // sort: s.sort_by !== value ? "ASC" : s.sort === "ASC" ? "DESC" : "ASC"
        }))

        setQuery(s => {
            let updatedQuery = s || '';
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort_by', value);
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort', sorting.sort);
            return updatedQuery;
        });
        navigate("")
    }

    //get orders
    const { isLoading, data, isFetching } = useGetOrders(dispatch, status, page, query)
    useGetOrdersCount(dispatch, "", page, query)

    function updateQuery(query: string) {
        setQuery(query);
    }


    useEffect(() => {
        navigate("")
    }, [query, navigate])


    return (
        <PageLayout>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems={{
                    md: "center",
                }}
                className='mb-4 md:mb-6'
                flexDirection={{ md: "row", xs: "column" }}
            >
                <PageHeader
                    title='Orders'
                    caption={`${data?.total || 0} Orders`}
                />

                {role !== EmployeeRole.KITCHEN && <Button
                    onClick={() => navigate("/orders/add")}
                    width='fit-content'
                    className='ml-auto mt-4'

                >
                    Add New Order
                    <AddIcon sx={{
                        fontSize: "20px",
                        ml: 1
                    }} />
                </Button>}
            </Box>

            <OrdersFilter updateFilter={updateQuery} />


            {/* {isLoading ? <Spinner /> : <>
                <OrdersList orders={orders} handelSorting={handelSorting} isLoading={isFetching} />

                <Pagination count={Math.ceil(data?.all?.total / 10) || 1} page={page} />

            </>} */}

            <OrdersList
                orders={isLoading || isFetching ? [] : orders}
                handelSorting={handelSorting}
                isLoading={isLoading || isFetching}
            />

            <Pagination count={Math.ceil(data?.total / 10) || 1} page={page} />


            <OrderFooter
                doneCount={doneCount}
                processingCount={processingCount}
            />

        </PageLayout>
    )
}
