import { Box, MenuItem, Select } from '@mui/material'
import { useState } from 'react'
import Status from '../../status/Status'
import { OrderStatusProps } from './order-status.props'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook';
import { EmployeeRole } from '../../../interfaces/employees.interface';
import { OrderStatus } from '../../../utilities/constants';
import { updateOrderStatus } from '../../../store/thunk-actions/order-action';
import { setSuccessMessage } from '../../../store/slices/success.slice';


export function filterStatus(role: string, status: string) {

    const orderStatusArr = Object.values(OrderStatus)
    const statusArr = [
        OrderStatus.NEW,
        OrderStatus.CANCELED
    ]

    const nextStatus = orderStatusArr.filter((item, index) => {
        const statusIndex = Object
            .values(OrderStatus)
            .findIndex(item => status === item);

        return (index > statusIndex && item !== OrderStatus.UPDATED && item !== OrderStatus.CANCELED && item !== OrderStatus.NEW)
    })

    if (role === EmployeeRole.SUPERVISOR) return [...nextStatus, OrderStatus.CANCELED];
    if (nextStatus.length > 0) {
        statusArr.unshift(nextStatus[0])
    }

    return statusArr
}
export default function OrderStatusList({
    order,
}: OrderStatusProps) {

    const dispatch = useAppDispatch()
    const { role } = useAppSelector(s => s.user)

    const [open, setOpen] = useState(false)

    function onChange(e: any) {

        if (order.status === OrderStatus.DONE) return;

        dispatch(updateOrderStatus({ id: order.id, status: e.target.value })).unwrap()
            .then(() => {
                dispatch(setSuccessMessage("Order status updated successfully."))
                setOpen(false)
            })

    }

    return (
        <Box
            width="100%"
            py={2}
            position="relative"
            onClick={() => setOpen(s => !s)}
            sx={{
                cursor: "pointer"
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >

                <Status
                    status={order.status}
                    time={order.picked_at}
                    width='150px'
                />

                {(open && OrderStatus.DONE !== order.status) && <Select
                    labelId={`demo-simple-select-label-${order.status}`}
                    id="demo-simple-select"
                    value={order.status}
                    label={"Status"}
                    onChange={onChange}
                    notched={true}
                    open={open}

                    sx={{
                        visibility: "hidden",
                        height: "0px",
                        width: "0px",
                        "fieldset": {
                            display: "none",
                        },
                        "svg": {
                            display: "none"
                        }

                    }}
                >
                    {
                        filterStatus(role, order.status).map(status => <MenuItem
                            onClick={e => e.stopPropagation()}
                            key={status}
                            value={status}
                            sx={{
                                display:
                                    (status === order.status || OrderStatus.UPDATED === status)
                                        ||
                                        (role === EmployeeRole.SUPERVISOR && [
                                            OrderStatus.NEW
                                        ].includes(status))
                                        ||
                                        (role === EmployeeRole.KITCHEN && [
                                            OrderStatus.DONE,
                                            OrderStatus.NEW,
                                            OrderStatus.CANCELED
                                        ].includes(status))
                                        ||
                                        (role === EmployeeRole.salesman && [
                                            OrderStatus.PREPARING,
                                            OrderStatus.READY_TO_PICKUP,
                                            OrderStatus.NEW
                                        ].includes(status)) ?
                                        "none" : "flex"
                            }}
                        >

                            <Status
                                status={status}
                                width='150px'
                                selected={order.status}
                                key={status}
                            />
                        </MenuItem>
                        )}

                </Select>}

                {
                    order.status === OrderStatus.DONE ?
                        null : open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }


            </Box>

            {/* 
            {role === EmployeeRole.KITCHEN && order.status === OrderStatus.DONE ? null :
                open && <StatusList order={order} />} */}
        </Box >

    )
}
