import React from 'react'
import { StatusProps } from './status.props'
import { Box, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import style from "./Status.module.css";
import { slugName } from '../../utilities/helper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Status({
    status,
    time,
    editAble,
    onClick = () => { return },
    selected,
    width
}: StatusProps) {
    return (

        <Box
            onClick={onClick}
            component="div"
            className={`${style.status} ${style[slugName(status)]}`}
            width={width || "fit-content"}
            sx={{
                cursor: onClick ? "pointer" : "auto",
                display: selected || editAble ? "flex" : "block",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1
            }}
        >
            <div>{status}</div>

            {editAble ?
                <KeyboardArrowDownIcon
                    sx={{
                        fontSize: "16px",
                        color: "#FFF",
                    }}
                />
                :
                null}

            {selected && <Box
                component="div"
                sx={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    border: "1px solid #FFF",
                    backgroundColor: status === selected ? "#FFF" : "transparent"
                }}
            >
            </Box>}


            {(time && status === "done") && <div>Pick up time {moment(time).format("hh:mm A")}</div>}

        </Box>

    )
}
