// api/github.api.ts
import { useQuery } from '@tanstack/react-query';
import * as api from "../utilities/api"
import { createFullSearchQuery } from '../utilities/helper';
import { setErrorMessages } from '../store/slices/error.slice';
import { setDoneOrdersCount, setProcessingOrderCount } from '../store/slices/order.slice';

export const getOrders = async (dispatch: any, status: string, page: number, query: string) => {

    try {

        const { data: doneData } = await api.getOrders(createFullSearchQuery({ status: "done", page: 1, query }));

        const { data: processingData } = await api.getOrders(createFullSearchQuery({ status: "processing", page: 1, query }));


        dispatch(setDoneOrdersCount(doneData?.total || 0));
        dispatch(setProcessingOrderCount(processingData?.total || 0));
        return null;

    } catch (error) {
        dispatch(setErrorMessages(error))
        return { doneData: { total: 0 }, processingData: { total: 0 } }
        // throw setErrorFormat(error);
    }
};

export const useGetOrdersCount = (dispatch: any, status: string, page: number, query: string,) => {

    return useQuery(['getOrdersCount', query, status, page], () => getOrders(dispatch, status, page, query), {
        cacheTime: 5 * 60 * 1000, // 10 minutes,
        keepPreviousData: true, // Maintain previous data while fetching new data
        // refetchInterval: 10000,
        refetchOnWindowFocus: false
    });
}; 
