import { ButtonType, buttonProps } from './button.props'
import style from "./Button.module.css"
import { Box } from '@mui/material'

function Button({
    className = "",
    children,
    onClick,
    type = ButtonType.COLORED,
    width = "100%"
}: buttonProps) {
    return (
        <Box
            component="div"
            className={`${className}`}
            width={{ xs: "100%", md: width }}
        >
            <button
                onClick={onClick}
                className={`${style.btn} ${style[type]} flex items-center justify-center`}
                disabled={type === ButtonType.DISABLED ? true : false}
            >
                {children}
            </button>
        </Box>
    )
}

export default Button