import React from 'react'
import { IEmployee } from '../../../interfaces/employees.interface'
import { Box, Avatar, Typography, IconButton } from '@mui/material'
import moment from 'moment'
import Status from '../../status/Status'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email'
import StorefrontIcon from '@mui/icons-material/Storefront';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { mobileBreakPoint } from '../../../utilities/constants'
import { Link } from 'react-router-dom'
import { IUser } from '../../../interfaces/user'

export default function Desktop({ employee }: { employee: IUser }) {
    return (
        <Box
            component="div"
            mt={7}
            sx={{
                backgroundColor: "#FFF",
                p: 3,
                border: "1px solid #D4E6FF",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "space-between",
                [mobileBreakPoint]: {
                    display: "none"
                }
            }}
        >

            <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
            }}>
                <Avatar
                    sx={{
                        width: "95px",
                        height: "95px"
                    }}
                    alt={employee.name.toUpperCase()}
                    src={`${process.env.REACT_APP_SERVER_STORAGE_URL}/${employee.profile_pic}`}
                />

                <Box
                    component="div"
                >
                    <Box
                        component="div"
                        display="flex"
                        mb={2}
                    >
                        <Typography
                            component="div"
                            fontSize="24px"
                            color="#202020"
                            lineHeight="30px"
                            mr={1}
                            textTransform="capitalize"
                        >
                            {employee.name}
                        </Typography>

                        <Box>
                            <Status status={employee.user_type} />
                        </Box>

                    </Box>

                    <Box
                        component="div"
                        display="flex"
                        mr={1}
                        mb={2}
                        gap={2}
                    >
                        <Typography
                            component="div"
                            fontSize="14px"
                            color="#4A4F78"
                            lineHeight="20px"
                        >
                            <PhoneIcon
                                sx={{
                                    color: "#A01A1F",
                                    mr: 1,
                                    fontSize: "16px"
                                }}
                            />
                            {employee.phone_number}
                        </Typography>

                        <Typography
                            component="div"
                            fontSize="14px"
                            color="#4A4F78"
                            lineHeight="20px"
                        >
                            <EmailIcon
                                sx={{
                                    color: "#A01A1F",
                                    mr: 1,
                                    fontSize: "16px"
                                }}
                            />
                            {employee.email}
                        </Typography>



                    </Box>


                    <Box
                        component="div"
                        display="flex"
                        mr={1}
                        gap={2}
                    >
                        <Typography
                            component="div"
                            fontSize="14px"
                            color="#4A4F78"
                            lineHeight="20px"
                        >
                            <StorefrontIcon
                                sx={{
                                    color: "#A01A1F",
                                    mr: 1,
                                    fontSize: "16px"
                                }}
                            />
                            Branch: <span className='underline' style={{
                                color: "#A01A1F",
                            }}>{employee.location_name}</span>
                        </Typography>
                    </Box>
                </Box>

            </Box>

            <Box sx={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column"
            }}>

                <Link to={`/employees/edit/${employee.id}`}>
                    <IconButton
                        sx={{
                            color: "#A01A1F",
                            fontSize: "18px",
                            fontWeight: "500",
                            lineHeight: "18px"
                        }}
                    >
                        <BorderColorOutlinedIcon
                            sx={{
                                mr: 1,
                                fontSize: "20px"
                            }}
                        />
                        Edit
                    </IconButton>
                </Link>


                <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    mb={2}
                    gap={1}
                >
                    <Typography
                        component="div"
                        fontSize="14px"
                        color="#4A4F78"
                        fontWeight="600"
                        lineHeight="30px"
                    >
                        Join Date
                    </Typography>

                    <Typography
                        component="div"
                        fontSize="12px"
                        color="#4A4F78"
                        lineHeight="18px"
                        mr={1}
                    >
                        {moment(employee.created_at).format("DD/MM/YYYY")}
                    </Typography>
                </Box>
            </Box>

        </Box>
    )
}
