import React from 'react'
import { ClientNameProps } from './client-name.props'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { mobileBreakPoint } from '../../../utilities/constants';

export default function ClientName({
    client,
    text
}: ClientNameProps) {

    const navigate = useNavigate()

    return (
        <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
        >
            <Box
                component="div"
                color="#000"
                sx={{
                    textTransform: "capitalize",
                    [mobileBreakPoint]: {
                        color: "#4A4F78",
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "21px"
                    }
                }}
            >
                {text ? text : client.full_name}
            </Box>
            <IconButton
                onClick={() => navigate(`/clients/${client.id}`)}
                sx={{
                    [mobileBreakPoint]: {
                        p: 0
                    }
                }}
            >
                <BorderColorOutlinedIcon
                    sx={{
                        fontSize: "20px",
                        color: "#A01A1F"

                    }}
                />
            </IconButton>
        </Box>
    )
}
