import { Box, Typography, Divider } from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import { ImageInputProps } from './imageInputProps';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';

export default function ImageInput({
    className = "",
    width = "100%",
    image,
    onchange,
    file
}: ImageInputProps) {

    const dispatch = useAppDispatch();

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onchange(event.target.files[0])
    };

    return (
        <Box
            component="div"
            alignItems="center"
            flexDirection="column"
            className={`${className}`}
            width={width}

        >

            <label htmlFor="logo-upload">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "211px",
                        borderRadius: "12px",
                        backgroundColor: "#F4F8FF",
                        cursor: 'pointer',
                        position: "relative",
                        overflow: "hidden"
                    }}
                    component="div"
                >

                    {!image && !file ?
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            gap={1}
                        >
                            <div>
                                <AddAPhotoOutlinedIcon sx={{
                                    color: "#4A4F78"
                                }} />
                            </div>
                            <Typography
                                fontSize="18px"
                                color="#4A4F78"
                                component="div"
                                lineHeight="26px"
                            >
                                Add Image
                            </Typography>
                        </Box>
                        :
                        <Box
                            component="div"
                            width="100%"
                            height="100%"
                        >
                            <img
                                className='w-full object-cover'
                                src={file ? URL.createObjectURL(file) : image}
                            />
                        </Box>
                    }
                    <input
                        id="logo-upload"
                        type="file"
                        accept="image/*"
                        className='hidden'
                        onChange={handleImageChange}
                    />
                </Box>
            </label>
        </Box >
    )
}
