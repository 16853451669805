import React, { useEffect, useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import SelectInput from '../components/form/select-input/SelectInput';
import { IUser } from '../interfaces/user';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { generateLocationMenu } from '../utilities/helper';
import { updateEmployee } from '../store/thunk-actions/employee-action';
import { setSuccessMessage } from '../store/slices/success.slice';
import { phonePlaceHolder, phoneValidation, phoneValidationMsg } from '../utilities/constants';
import { watch } from 'fs';


const schema = yup.object({
    name: yup.string().required("Full name is required"),
    email: yup.string().email().required("Email is required"),
    location: yup.string().notOneOf(["---"], "Location is required").optional().nullable(),
    phone_number: yup.string().matches(phoneValidation, phoneValidationMsg).required("Phone number is required"),


}).required();

export default function EditEmployeeForm({ employee, status }: { employee: IUser, status: string }) {

    const dispatch = useAppDispatch()

    const { location: { locations }, employee: { isLoading } } = useAppSelector(s => s);

    const { control, handleSubmit, watch, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            name: employee.name,
            email: employee.email,
            location: employee.location,
            phone_number: employee.phone_number
        }
    });

    const onSubmit = (data: any) => {

        dispatch(updateEmployee({
            id: employee.id,
            data
        })).unwrap()
            .then(() => {
                reset(s => ({
                    ...s,
                    ...data
                }))
                dispatch(setSuccessMessage("Employee updated successfully."))
            })
    }

    return (
        <FormLayout
            title={`Edit Employee ${employee.name}`}
            onSubmit={handleSubmit(onSubmit)}
            button={{
                isDirty,
                loading: isLoading,
                isValid
            }}
        >

            <Stack spacing={{ md: 4, xs: 3 }} mb={{ xs: 3, md: 4 }}>
                <TextInput
                    control={control}
                    name={"name"}
                    error={errors.name?.message}
                    label={"Full Name"}
                    placeholder='Full name'
                />

                {
                    status === "admin" ? null : <SelectInput
                        control={control}
                        name={"location"}
                        error={errors.location?.message}
                        label={"Branch Name"}
                        menu={generateLocationMenu(locations)}
                    />

                }

                <TextInput
                    control={control}
                    name={"phone_number"}
                    error={errors.phone_number?.message}
                    label={"Phone number"}
                    placeholder={phonePlaceHolder}
                    mask="+\9\71-99-9999999"
                />

                <TextInput
                    control={control}
                    name={"email"}
                    error={errors.email?.message}
                    label={"Email"}
                    placeholder="ex email@gmail.com"
                />
            </Stack>

        </FormLayout>
    )
}
