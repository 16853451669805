import { Box, Grid } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Logo from '../../components/logo/Logo'
import { mobileBreakPoint } from '../../utilities/constants'

export default function AuthLayout() {
    return (
        <Box
            component="div"
        >
            <Grid container minHeight='100vh'>

                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        position: "relative",
                        [mobileBreakPoint]: {
                            height: "420px"
                        }
                    }}
                >
                    <div className='w-full h-full'>
                        <img
                            src="/media/login-image.png"
                            className='w-full h-full object-cover'
                        />
                    </div>

                    <Box
                        component="div"
                        sx={{
                            padding: "50px 40px",
                            backgroundColor: "#FFF",
                            position: "absolute",
                            top: "0",
                            left: "50px",
                            zIndex: "999",
                            [mobileBreakPoint]: {
                                p: 2,
                                left: "10px"
                            }
                        }}
                    >
                        <Logo width="175px" />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            py: "145px",
                            px: "105px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            [mobileBreakPoint]: {
                                py: 2,
                                px: 1
                            }
                        }}
                    >
                        <Outlet />
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}
