import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { IOrder } from '../../../interfaces/order.interface';
import Card from '../../card/card/Card';
import CardContent from '../../card/card-content/CardContent';
import moment from 'moment';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Link } from 'react-router-dom';
import './OrderCard.css'; // Import your custom CSS for animations
import Duration from '../../duration/Duration';
import { useAppSelector } from '../../../hooks/store.hook';
import { EmployeeRole } from '../../../interfaces/employees.interface';
import { OrderStatus } from '../../../utilities/constants';

export default function OrderCard({ order }: { order: IOrder }) {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState('auto');
    const contentRef = useRef<HTMLDivElement>(null);

    const { role } = useAppSelector(s => s.user)

    useEffect(() => {
        if (contentRef.current) {
            setHeight(open ? `${contentRef.current.scrollHeight}px` : `${contentRef.current.scrollHeight}px`);
        }
    }, [open]);

    return (
        <Card
            key={order.id}
            withHeader={true}
            status={order.status}
            order={order}
            time={moment(order.picked_at).format('DD/MM/YYYY hh:mm A')}
            withFooter
            footerEvent={() => setOpen(s => !s)}
            cardCollops={open}
        >
            <div className="content-wrapper" style={{ height }}>
                <div ref={contentRef} className="content-inner">
                    <Grid container>
                        <Grid item xs={6}>
                            <CardContent
                                titleNode={
                                    <Link to={`/orders/edits/${order.id}`}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <div>Ticket No</div>
                                            {

                                                role !== EmployeeRole.KITCHEN
                                                    ?
                                                    [OrderStatus.DONE, OrderStatus.READY_TO_PICKUP].includes(order.status) ?
                                                        null :
                                                        <BorderColorOutlinedIcon sx={{ fontSize: '16px', color: '#A01A1F' }} />
                                                    : null
                                            }
                                        </Box>
                                    </Link>
                                }
                                dark={true}
                                text={`#${order.location_prefix}-${order.ticket_number}`}
                                color='#A01A1F'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardContent title='Duration'
                                textNode={<Duration
                                    completed_at={order.completed_at}
                                    created_at={order.created_at}
                                    picked_at={order?.picked_at}
                                />}
                                underline={true}
                                dark={true}
                            />
                        </Grid>
                        {open && (
                            <>
                                {
                                    role !== EmployeeRole.KITCHEN && <>
                                        <Grid item xs={6}>
                                            <CardContent
                                                title='Client Name'
                                                text={order.full_name}

                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CardContent
                                                title='Phone Number'
                                                text={order.phone_number}
                                            />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={6}>
                                    <CardContent title='Created Date'
                                        text={moment(order.created_at).format('DD/MM/YYYY hh:mm A')}
                                        dark={role !== EmployeeRole.KITCHEN}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CardContent
                                        title='Brach Name'
                                        text={order.location_name}
                                        underline={true}
                                        dark={role !== EmployeeRole.KITCHEN}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <CardContent
                                title='Order Details'
                                textNode={<Link to={`/orders/edits/${order.id}`}>
                                    {order.order_details}
                                </Link>}
                                dark={open && role === EmployeeRole.KITCHEN}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Card >
    );
}


