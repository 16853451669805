import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";
import "./../../table/table.css"
import HeaderSort from "../../table/header-sort/HeaderSort";
import Status from "../../status/Status";
import User from "../../user/User";
import DeleteEmployee from "../delete/DeleteEmployee";


export const columns = (onclick: any, handelSorting: (value: string) => void, isLoading: boolean): GridColDef[] => {
    return [
        {
            field: 'id',
            headerName: 'id',
            width: 130,
            headerClassName: "table-header-col"
        },
        {
            field: 'name',
            headerName: 'Name',
            cellClassName: "table-cell-col",
            headerClassName: "table-header-col",
            sortable: false,
            width: 320,
            renderCell: (params) => <Box
                component="div"
                onClick={() => onclick(params.row.id)}
                sx={{
                    cursor: "pointer",
                }}
            >
                <User
                    name={params.row.name}
                    email={params.row.email}
                    image={params.row.profile_pic}
                />
            </Box>,
        },
        {
            field: 'user_type',
            headerName: 'Role',
            cellClassName: "table-cell-col",
            renderHeader: (params) => <HeaderSort
                text={params.colDef.field}
                handelSorting={handelSorting}
                isLoading={isLoading}
                title={params.colDef.headerName}
            />,
            renderCell: (param) => <Status
                status={param.row.user_type}
                width="120px"
            />,
            width: 150,
            headerClassName: "table-header-col-sort",
            sortable: false,
        },

        {
            field: 'user.phone_number',
            headerName: 'Phone Number',
            width: 200,
            hideable: true,
            sortable: false,
            headerClassName: "table-header-col",
            cellClassName: "table-cell-col",
            renderCell: (params) => (
                <Box
                    color="#4A4F78"
                    component="span"
                >
                    {params.row.phone_number}
                </Box>
            ),
        },
        {
            field: 'created_at',
            headerName: 'Join Date',
            width: 155,
            headerClassName: "table-header-col-sort",
            cellClassName: "table-cell-col",
            renderHeader: (params) => <HeaderSort
                text={params.colDef.field}
                handelSorting={handelSorting}
                isLoading={isLoading}
                title={params.colDef.headerName}
            />,
            sortable: false,
            renderCell: (params) => (
                <Box
                    component="span"
                    maxWidth="100%"
                    whiteSpace="pre-wrap"
                >
                    {moment(params.row.created_at).format("DD/MM/YYYY")}
                </Box>
            ),
        },

        {
            field: 'location_name',
            headerName: 'Branch Name',
            cellClassName: "table-cell-col",
            headerClassName: "table-header-col",
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <Box
                    component="span"
                    className="underline"
                >
                    {params.row.location_name}
                </Box>
            ),
        },

        {
            field: 'city',
            headerName: 'City',
            cellClassName: "table-cell-col",
            headerClassName: "table-header-col",
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <Box
                    component="span"
                    className="underline"
                >
                    {params.row.city}
                </Box>
            ),
        },

        {
            field: 'action',
            headerName: '',
            cellClassName: "table-cell-col",
            headerClassName: "table-header-col",
            sortable: false,
            width: 80,
            renderCell: (params) => (
                <DeleteEmployee employee={params.row} />
            ),
        },

    ];

}  