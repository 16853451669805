import React from 'react'
import { TextInputProps } from './text-input.props'
import { Box, TextField, Typography } from '@mui/material'
import { useForm, Controller } from "react-hook-form";

export default function TextArea({
    control,
    name,
    error,
    label,
    placeholder = "",
    width = "100%",
    className = "",
    isDisabled,
    helperText
}: TextInputProps) {


    return (
        <Box
            className={`${className}`}
            component="div"
            width={width}
        >
            <Controller
                name={name}
                control={control}
                render={({ formState, field }) => <TextField
                    label={label}
                    variant="outlined"
                    placeholder={placeholder}
                    fullWidth
                    error={error ? true : false}
                    helperText={error}
                    multiline
                    rows={7}
                    disabled={isDisabled}
                    sx={{
                        "fieldset": {
                            borderColor: "gree#D7E7FA",
                            borderRadius: "12px",
                            "& legend": {
                                fontSize: "8px"
                            }
                        }
                    }}
                    InputLabelProps={{
                        shrink: true,
                        sx: {
                            // fontSize: "12px",
                            color: "#8E92B9",
                            '&.MuiInputLabel-shrink': {
                                fontSize: "12px",

                            },
                            left: "3px",
                            top: "3px"
                        },
                    }}
                    InputProps={{
                        sx: {
                            "input": {
                                px: "24px",
                                "&:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 1000px white inset"
                                }
                            },
                            fontSize: "17px",
                            color: "#4A4F78",
                            height: "100%"
                        }
                    }}
                    {...field}
                />

                }
            />


            {helperText && <Typography
                color="#8E92B9"
                fontSize="10px"
                mt={1}

            >
                {helperText}
            </Typography>}
        </Box >
    )
}

