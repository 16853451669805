import { Box, Typography, List } from '@mui/material'
import NaveListItem from '../nav-list/NaveListItem'
import { navListGroupProps } from './navListGroup.props'
import { mobileBreakPoint } from '../../../utilities/constants'
import { useAppSelector } from '../../../hooks/store.hook'

function NavListGroup({ list, title, open, onClick }: navListGroupProps) {

    const { role } = useAppSelector(s => s.user)

    function closeNavigation(e: any) {
        e.stopPropagation()
    }
    return (
        <Box

            onClick={closeNavigation}
            sx={{
                pr: open ? "0" : "11px",
                pl: open ? "75px" : "11px",
                pt: "24px",
                [mobileBreakPoint]: {
                    pl: "29px"
                }
            }}>
            {(open && title) && <Typography
                variant='h6'
                component="div"
                fontSize="12px"
                color="#797e96"
                sx={{
                    pl: "12px",
                    height: "32px",
                    display: "Flex",
                    alignItems: "center"
                }}
                className="capitalize"
            >
                {title}
            </Typography>
            }

            <List sx={{ py: 0, width: "auto" }}>
                {list.filter(item => item.roles.includes(role)).map(item =>
                    <NaveListItem
                        list={item}
                        key={item.key}
                        open={open}
                        onClick={onClick}
                    />
                )}
            </List>
        </Box>
    )
}

export default NavListGroup