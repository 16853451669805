import React, { useEffect, useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from '../components/form/text-input/TextInput';
import { useParams } from 'react-router-dom';
import { IClient } from '../interfaces/client.interface';
import moment from 'moment';
import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import { updateClient } from '../store/thunk-actions/clients-action';
import { toggleSuccessModel } from '../store/slices/model.slice';
import { phonePlaceHolder, phoneValidation, phoneValidationMsg } from '../utilities/constants';

const schema = yup.object({
    full_name: yup.string().required("Full name is required"),
    phone_number: yup.string().optional()
}).required();

export default function EditClientForm({ client }: { client: IClient }) {

    const dispatch = useAppDispatch()

    const { clients, isLoading } = useAppSelector(s => s.client)

    const { id } = useParams()

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            full_name: client.full_name,
            phone_number: client.phone_number,
            completed: client.completed_orders,
            created_at: moment(client.created_at).format("DD/MM/YYYY")
        }
    });


    const onSubmit = (data: any) => {
        dispatch(updateClient({ id: +id, data }))
            .unwrap()
            .then(() => {

                dispatch(toggleSuccessModel())
                reset(s => ({
                    ...s,
                    ...data
                }))
            })
    }




    return (
        <FormLayout
            title={`Edit Client ${client?.full_name}`}
            onSubmit={handleSubmit(onSubmit)}
            button={{
                isDirty,
                loading: isLoading,
                isValid
            }}
        >

            <Stack spacing={{ md: 4, xs: 3 }} mb={{ xs: 3, md: 4 }}>
                <TextInput
                    control={control}
                    name={"full_name"}
                    error={errors.full_name?.message}
                    label={"Full Name"}
                    placeholder='Full name'
                />


                <TextInput
                    control={control}
                    name={"phone_number"}
                    error={errors.phone_number?.message}
                    label={"Phone number"}
                    isDisabled={true}
                    placeholder={phonePlaceHolder}
                    mask="+\9\71-99-9999999"

                />

                <TextInput
                    control={control}
                    name={"created_at"}
                    error={""}
                    label={"Added Date"}
                    isDisabled={true}
                />


                <TextInput
                    control={control}
                    name={"completed"}
                    error={errors.completed?.message}
                    label={"Completed"}
                    placeholder="ex +971-50-1234567"
                    isDisabled={true}

                />
            </Stack>
        </FormLayout>
    )
}
