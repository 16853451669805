import { configureStore } from '@reduxjs/toolkit';
import user from "./slices/user.slice";
import auth from "./slices/auth.slice";
import model from "./slices/model.slice"
import success from "./slices/success.slice"
import errors from "./slices/error.slice"
import order from "./slices/order.slice"
import layout from "./slices/layout.slice"
import location from "./slices/location.slice"
import branch from "./slices/branch.slice"
import client from "./slices/client.slice"
import employee from "./slices/employee.slice"

const store = configureStore({
    reducer: {
        auth,
        user,
        model,
        success,
        errors,
        order,
        layout,
        location,
        client,
        branch,
        employee
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;