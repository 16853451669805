// api/github.api.ts
import { useQuery } from '@tanstack/react-query';
import { setErrorFormat } from '../utilities/error-format';
import * as api from "../utilities/api"
import { setLocations } from '../store/slices/location.slice';

export const getLocations = async (dispatch: any) => {

    try {
        const { data } = await api.getLocations("block=true");

        dispatch(setLocations(data))

        return data;
    } catch (error) {
        throw setErrorFormat(error);
    }
};

export const useGetLocations = (dispatch: any) => {

    return useQuery(['getLocations'], () => getLocations(dispatch), {
        // staleTime: 5 * 60 * 1000, // 10 minutes
        cacheTime: 600000, // 10 minutes
    });
};
