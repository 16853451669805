import React, { useEffect, useState } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ListItemType, props } from './props';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import "./NaveListItem.css"
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { Box } from '@mui/material';
import useWindowSize from '../../../hooks/useWindowSize';

function NaveListItem({ list, open, my, onClick }: props) {

    const location = useLocation()
    const [openMenu, setOpenMenu] = useState(false)
    const navigate = useNavigate()
    const windowSize = useWindowSize();

    useEffect(() => {
        if (open && windowSize.width <= 768 && list.type === ListItemType.LINK && onClick)
            onClick()
    }, [location])
    return (
        <div>

            <ListItem
                disablePadding
                sx={{
                    mb: "8px"
                    // padding: "4px 24px"
                }}
            >
                <ListItemButton
                    sx={{
                        justifyContent: open ? 'initial' : 'center',
                        padding: 0,
                        margin: 0
                    }}
                    onClick={(e) => {
                        if (list.type === ListItemType.LINK) return
                        e.preventDefault()

                        setOpenMenu(s => !s)
                    }}
                >
                    <NavLink
                        to={list.path}
                        onClick={(e) => {

                            if (list.type === ListItemType.LINK) return;

                            e.preventDefault()
                        }}
                        className={({ isActive }) => [
                            isActive ? open ? "active-nav" : "active-nav active-nav-close" : "",
                            "nav-link",
                            open ? "" : "nav-link-close",
                            "flex",
                            "items-center",
                            "w-full",
                            open ? "justify-between" : "justify-center"
                        ].join(" ")}>

                        <div className='flex items-center'>
                            <ListItemIcon
                                sx={{
                                    mr: open ? "12px" : "0",
                                    justifyContent: 'center',
                                    minWidth: 0
                                }}
                            >
                                <list.Icon sx={{ hight: 20, width: 20 }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={list.text}
                                primaryTypographyProps={{
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    display: open ? "block" : "none"
                                }}
                                sx={{ fontSize: "14px", opacity: open ? 1 : 0, my: 0 }}
                            />
                        </div>

                        {(list.type === ListItemType.MENU && open) && (!openMenu ?
                            <KeyboardArrowDownOutlinedIcon sx={{ fontSize: "20px" }} />
                            :
                            <KeyboardArrowUpOutlinedIcon sx={{ fontSize: "20px" }} />
                        )
                        }
                    </NavLink >
                </ListItemButton>

            </ListItem >
            {(openMenu && list.items) && <Box
                component="div"
                pl={open ? 3 : 0}
                py={1}
            >

                {list.items.map(item => <NaveListItem list={item} open={open} key={item.key} />)}

            </Box>}
        </div>

    )
}

export default NaveListItem