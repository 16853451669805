import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../utilities/api"
import { IClient } from "../../interfaces/client.interface";



//update user
export const updateClient = createAsyncThunk<
    IClient,
    { id: number, data: Partial<IClient> },
    {
        rejectValue: unknown
    }
>("clients/updateClient", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.updateClient(payload.id, payload.data)

        console.log("data = ", data)
        return data.client;
    } catch (error) {
        return rejectWithValue(error)
    }
})

