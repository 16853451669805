import { Box } from '@mui/material'
import React from 'react'
import { mobileBreakPoint } from '../../../utilities/constants'
import { NavLink } from 'react-router-dom'
import style from "./OrderFooter.module.css"

export default function OrderFooter({ doneCount, processingCount }: { doneCount: number, processingCount: number }) {
    return (
        <Box
            component="div"
            sx={{
                background: "#FFF",
                width: "100%",
                display: "none",
                position: "fixed",
                p: 1,
                bottom: 0,
                left: 0,
                [mobileBreakPoint]: {
                    display: "flex",
                    justifyContent: "center",
                    gap: "8px"
                }
            }}
        >

            <NavLink to="/orders/done"
                className={({ isActive }) => [
                    style.link,
                    isActive ? style.active : "",
                ].join(" ")}>
                ({doneCount}) Done Orders
            </NavLink>

            <NavLink to="/orders/processing"
                className={({ isActive }) => [
                    style.link,
                    isActive ? style.active : "",
                ].join(" ")}
            >
                ({processingCount}) Processing Orders
            </NavLink>
            {/* <Box
                sx={{
                    background: "#FFF",
                    borderRadius: "12px",
                    color: "#4A4F78",
                    fontWeight: "500",
                    fontSize: "13PX",
                    p: 2
                }}
            >
                ({doneCount}) Done Orders
            </Box>

            <Box
                sx={{
                    background: "#A01A1F",
                    borderRadius: "12px",
                    color: "#fff",
                    fontWeight: "500",
                    fontSize: "13PX",
                    p: 2
                }}
            >
                ({processingCount}) Processing Orders
            </Box> */}
        </Box >
    )
}
