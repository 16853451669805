import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbProps } from './breadcrumb.props';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import "./breadcrumb.css";
import { mobileBreakPoint } from '../../utilities/constants';

export default function CustomBreadcrumb({ list }: BreadcrumbProps) {
    const breadcrumbs = [
        <Link key="0" color="inherit" to="/" className='breadcrumb-link'>
            <HomeOutlinedIcon sx={{ fontSize: "17px" }} />
            Home
        </Link>
    ];


    return (
        <Stack
            spacing={2}
            sx={{
                [mobileBreakPoint]: {
                    display: "none"
                }
            }}
        >
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {[
                    ...breadcrumbs,
                    list.map((item, index) => item.type === "link" ?
                        <Link key={index + 1} color="inherit" to={item.path} className='breadcrumb-link'>
                            {item.text}
                        </Link>
                        :
                        <Typography
                            color="#A01A1F"
                            fontSize="12px"
                            fontWeight="500"
                            key={index + 1}
                        >
                            {item.text}
                        </Typography>
                    )
                ]}
            </Breadcrumbs>
        </Stack>
    );
}
