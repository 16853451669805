import { createSlice } from "@reduxjs/toolkit";
import { apiErrorFormat, setErrorFormat } from '../../utilities/error-format';
import { login } from "../thunk-actions/auth-actions";
import { getUser } from "../thunk-actions/user-action";
// import { createUserProfile, uploadProfilePicture } from './../thunk-actions/user-action';



const initialState = {
    isLoading: false,
    errors: [] as string[],
    isLoggedIn: false,
}

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {

        logout(state) {
            localStorage.clear()
            state.isLoggedIn = false;
        },

    },
    extraReducers: (builder) => {
        //login
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.errors = []
        });

        builder.addCase(login.fulfilled, (state) => {
            state.isLoading = false;
            state.isLoggedIn = true
        });

        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.errors = apiErrorFormat(action.payload);
        })

        builder.addCase(getUser.fulfilled, (state, action) => {
            state.isLoggedIn = true;
        })
    }
})

const {
    logout
} = slice.actions

export { logout }

export default slice.reducer;