import { ILocation } from "../interfaces/locations";
import { OrderStatus } from "./constants";

interface IFullName {
    username: string,
    first_name?: string,
    last_name?: string
}

export function slugName(str: string) {
    return str.split(" ").join("-").toLowerCase()
}

export function fullName(member: IFullName): string {
    const fullName = member.first_name ? `${member.first_name} ${member.last_name || ''}` : member.username;

    return fullName;
}

export function capitalizePath(path: string) {
    if (path === "/")
        return "Home"

    // const str = path.substring(1)

    return path.charAt(0).toUpperCase() + path.slice(1);

}

export function getPageTile(path: string) {
    if (path === "/")
        return "Home"

    const strArr = path.substring(1).split("/")
    return strArr[strArr.length - 1].split("-").map(str => capitalizePath(str)).join(" ")
}

export function createSearchQuery(queryData: any): string {

    let query: string = "";

    Object.keys(queryData).forEach(key => {

        const value = queryData[key]
        if (!value) return;

        // query += `${query ? `&${key}=${value}` : `?${key}=${value}`}`

        query += `${query ? `&${key}=${value}` : `${key}=${value}`}`

    })


    return query
}

export function createFullSearchQuery({
    limit = 10, page, status = "", query = ""
}: {
    limit?: number,
    page: number,
    query?: string,
    status?: string
}): string {

    let searchQuery = `per_page=${limit}&page=${page}`

    if (status) {

        if (status === "done") {
            searchQuery += `&status[]=${status}`
        } else if (status === 'canceled') {
            searchQuery += `&status[]=${OrderStatus.CANCELED}`
        } else {
            searchQuery += `&status[]=${OrderStatus.NEW}&status[]=${OrderStatus.PREPARING}&status[]=${OrderStatus.READY_TO_PICKUP}&status[]=updated`
        }
    }

    if (query) {
        searchQuery += `&${query}`
    }

    return searchQuery;

}

export function generateLocationMenu(locations: ILocation[]) {
    return [
        { value: "---", label: "Branch name" },
        ...locations.map(location => ({ value: location.id, label: location.name }))
    ]
}

export const updateQueryStringParameter = (uri: string, key: string, value: any) => {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
    const separator = uri ? "&" : "";
    if (uri.match(re)) {
        return uri.replace(re, `$1${key}=${value}$2`);
    } else {
        return uri + separator + key + "=" + value;
    }
};

export function playSoundNotification() {
    // const audio = new Audio("/media/notification.mp3");

    const audio = new Audio("https://dl.dropboxusercontent.com/scl/fi/to2hgcwd0kb03xe0bjyg1/notification.mp3?rlkey=6zkrx0wo07122g7v8mgf6byyb&st=rhcziaj3");

    audio.play().catch(error => console.error('Audio play failed:', error));
}
