import { RouterProvider } from "react-router-dom";
import routes from './config/routes';
import "./App.css"
import Errors from './components/errors/Errors';
import Success from './components/sucsses/Success';
import Models from './models/Models';
import { useGetLocations } from './hooks/get-locations.hook';
import { useAppDispatch, useAppSelector } from './hooks/store.hook';
import { useEffect, useRef } from "react";
import { getUser } from "./store/thunk-actions/user-action";
import Loading from "./components/loading/Loading";
import 'moment-timezone';
import moment from "moment";
import SoundNotification from "./components/SoundNotification/SoundNotification";

moment.tz.setDefault('Asia/Dubai');

const App = () => {

    const dispatch = useAppDispatch()

    const {
        user: { loading },
    } = useAppSelector(s => s)

    useGetLocations(dispatch);

    useEffect(() => {
        dispatch(getUser())
    }, [dispatch]);


    return (
        <>

            {/* <SoundNotification /> */}

            {loading ? <Loading /> : <RouterProvider router={routes} />}
            <Models />
            <Success />
            <Errors />
        </>
    )
}

export default App