import { IMenu } from "../components/filters/drop-down/drop-down.props";

export const cityMenu: IMenu[] = [
    { value: "---", label: "Choose The City" },
    { value: "Abu Dhabi", label: "Abu Dhabi" },
    { value: "Al Ain", label: "Al Ain" },
    { value: "Dubai", label: "Dubai" },
    { value: "Sharjah", label: "Sharjah" },
]

export const areaMenu: IMenu[] = [
    { value: "---", label: "Choose The City" },
    { value: "barhsa South", label: "barhsa South" },
    { value: "Abu Dhabi", label: "Abu Dhabi" },
    { value: "Al Ain", label: "Al Ain" },
    { value: "Sharjah", label: "Sharjah" },
    { value: "Motor City", label: "Motor City" },
    { value: "Nad Al hammar", label: "Nad Al hammar" },
    { value: "Al nahda", label: "Al nahda" },
    { value: "Nad Al Sheba", label: "Nad Al Sheba" },
    { value: "Silicon oasis", label: "Silicon oasis" },
    { value: "Mamzer", label: "Mamzer" },
    { value: "Jumeriah", label: "Jumeriah" },
    { value: "Al Barsha Mall", label: "Al Barsha Mall" },
    { value: "Um suquiem", label: "Um suquiem" },
]
