import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from './app-bar/AppBar';
import Navigation from './navigation/Navigation';
import Container from './container/Container';
import { Outlet } from 'react-router-dom';
import Content from './content/Content';
import Nav from './nav/Nav';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppSelector } from '../../hooks/store.hook';
import { EmployeeRole } from '../../interfaces/employees.interface';
import usePusher from '../../hooks/usePusher';




export default function Layout() {

    const windowSize = useWindowSize();
    usePusher()

    const drawerWidth = 312;

    const [open, setOpen] = React.useState(windowSize.width > 768);

    const toggleOpen = () => {
        setOpen(s => !s);
    };



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                open={open}
                onClick={toggleOpen}
                width={drawerWidth}

            >
                <Nav open={!open} onClick={toggleOpen} />
            </AppBar>
            <Navigation
                open={open}
                width={drawerWidth}
                onClick={toggleOpen}
            />
            <Box
                component="div"
                sx={{
                    width: {
                        xs: "100%",
                        lg: open ? `calc(100vw - ${drawerWidth}px)` : "calc(100vw - 75px)"
                    }
                }}
                overflow="hidden"
            >
                <Container>
                    <Outlet />
                </Container>
            </Box>
        </Box >
    );
}
