import React from 'react'
import { HeaderSortProps } from './header-sort.props'
import { Box } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useGetOrders } from '../../../hooks/get-orders.hook';
import { useAppDispatch } from '../../../hooks/store.hook';
import CircularProgress from '@mui/material/CircularProgress';


export default function HeaderSort({
    text,
    handelSorting,
    isLoading,
    title
}: HeaderSortProps) {

    const dispatch = useAppDispatch()


    function onClick() {

        if (isLoading) return
        handelSorting(text)

    }
    return (
        <Box
            component="div"
            display="flex"
            alignItems="center"
            // justifyContent="space-between"
            onClick={onClick}
            width="100%"
            sx={{
                cursor: isLoading ? "progress" : "pointer",
                color: "#4A4F78",
                padding: "20px 24px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: "600",
            }}
        >
            {title}

            <ImportExportIcon sx={{ ml: 1, fontSize: "14px" }} />

            {/* {isLoading ?
                <CircularProgress color="secondary" size={20} />
                : <ImportExportIcon sx={{ ml: 1, fontSize: "14px" }} />} */}
        </Box >
    )
}
