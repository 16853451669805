import React from 'react'
import style from "./Container.module.css"
import { ContainerProps } from './containerProps'

function Container({ children }: ContainerProps) {
    return (
        <div className={`${style.container}`}>
            {children}
        </div>
    )
}

export default Container