import React from 'react'
import { UserProps } from './user.props'
import { Box, Avatar, Typography } from '@mui/material'

export default function User({ name, email, image }: UserProps) {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
        }}>
            <Avatar
                sx={{
                    width: "35px",
                    height: "35px"
                }}
                alt={name.toUpperCase()}
                src={`${process.env.REACT_APP_SERVER_STORAGE_URL}/${image}`}
            />

            <Box
                component="div"
            >
                <Typography
                    component="div"
                    fontSize="12px"
                    color="#191B29"
                    lineHeight="18px"
                    textTransform="capitalize"
                >
                    {name}
                </Typography>

                <Typography
                    component="div"
                    fontSize="10px"
                    color="#191B29"
                >
                    {email}
                </Typography>
            </Box>

        </Box>
    )
}
