import React, { useEffect, useState } from 'react'
import PageLayout from '../../layouts/layout/page-layout/PageLayout'
import ClientsList from '../../components/clients/clients-list/ClientsList'
import PageHeader from '../../components/page-header/PageHeader'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ClientFilter from '../../components/clients/filters/ClientFilter';
import Pagination from '../../components/pagination/Pagination';
import { useGetOrders } from '../../hooks/get-orders.hook';
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook';
import { useGetClients } from '../../hooks/get-clients.hook';
import { ISorting } from '../../interfaces/sorting.interface';
import { updateQueryStringParameter } from '../../utilities/helper';

export default function Clients() {

    const navigate = useNavigate()

    const [sorting, setSorting] = useState<ISorting>({
        sort_by: "",
        sort: "ASC"
    });


    function handelSorting(value: string) {
        setSorting(s => ({
            sort_by: value,
            sort: s.sort === "ASC" ? "DESC" : "ASC"
        }))

        setQuery(s => {
            let updatedQuery = s || '';
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort_by', value);
            updatedQuery = updateQueryStringParameter(updatedQuery, 'sort', sorting.sort);
            return updatedQuery;
        });
        navigate("")
    }

    //setup redux options
    const dispatch = useAppDispatch()
    const { clients } = useAppSelector(s => s.client);

    //get page param
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get("page") || '1', 10);

    //set query
    const [query, setQuery] = useState("");

    //get orders
    const { isLoading, data, isFetching } = useGetClients(dispatch, page, query)

    return (
        <PageLayout
        >
            <PageHeader
                title='Clients'
                caption={`${data?.total || 0} registered clients`}
                className='mb-4 md:mb-6'
            />

            <ClientFilter query={query} updateFilter={(query) => setQuery(query)} />

            <ClientsList
                clients={isLoading || isFetching ? [] : clients}
                handelSorting={handelSorting}
                isLoading={isLoading || isFetching}
            />

            <Pagination count={Math.ceil(data?.total / 10) || 1} page={page} />

        </PageLayout>
    )
}


