export const mobileBreakPoint = "@media (max-width:1024px)"

export enum OrderStatus {
    NEW = "new",
    UPDATED = "updated",
    PREPARING = "preparing",
    READY_TO_PICKUP = "ready to pickup",
    DONE = "done",
    CANCELED = "canceled"
}

// export const phoneValidation = /^(?:\+971)(?:2|3|4|6|7|9|50|51|52|55|56)[0-9]{7}$/
export const phoneValidation = /^\+971[0-9]{9}$/

export const phoneValidationMsg = "invalid phone format ex: +971551234567"
export const phonePlaceHolder = "ex: 00971551234567"