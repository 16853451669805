import { createAsyncThunk } from "@reduxjs/toolkit";
import { ILoginCredentials } from "../../interfaces/auth.interface"
import * as api from "./../../utilities/api"
import { IUser } from "../../interfaces/user";
import { setUserRole } from "../slices/user.slice";

// //login
export const login = createAsyncThunk<
    IUser,
    {
        payload: ILoginCredentials
    },
    {
        rejectValue?: unknown,
    }
>("auth/login", async ({ payload }, thunkApi) => {
    const { rejectWithValue, dispatch } = thunkApi;
    try {

        const { data } = await api.login(payload);

        localStorage.setItem("token", JSON.stringify(data.token));

        const user: IUser = data.user
        // dispatch(setUserRole(user.user_type))

        return user
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})
