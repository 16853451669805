import { createBrowserRouter } from "react-router-dom";
import Root from "../routes/Root";
import NodFoundPage from "../routes/404";
import PrivateRoute from "../components/privare-route/PrivateRoute";
import Reports from "../routes/reports/Reports";
import Clients from "../routes/client/Clients";
import Branches from "../routes/branch/Branches";
import Employees from "../routes/employee/Employees";
import StatusReports from "../routes/reports/StatusReports";
import AddEmployee from "../routes/employee/AddEmployee";
import AddBranch from "../routes/branch/AddBranch";
import EditClient from "../routes/client/EditClient";
import Employee from "../routes/employee/Employee";
import EditBranch from "../routes/branch/EditBranch";
import Profile from "../routes/Profile";
import EditEmployee from "../routes/employee/EditEmployee";
import StatusOrder from "../routes/orders/StatusOrders";
import AddOrder from "../routes/orders/AddOrder";
import EditOrder from "../routes/orders/EditOrder";
import AuthLayout from "../layouts/auth-layout/AuthLayout";
import Login from "../routes/auth/Login";
import { EmployeeRole } from "../interfaces/employees.interface";
import ForgetPassword from "../routes/auth/ForgetPassword";

const routes = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                index: true,
                element: <PrivateRoute
                    Component={Reports}
                    roles={["admin", "superadmin"]}
                />
            },
            {
                path: "profile",
                element: <PrivateRoute Component={Profile} />
            },
            {
                path: "reports",
                children: [
                    {
                        path: ":status",
                        element: <PrivateRoute
                            Component={StatusReports}
                            roles={["admin", "superadmin"]}
                        />
                    },
                ]
            },
            {
                path: "orders",
                children: [

                    {
                        path: "add",
                        element: <PrivateRoute
                            Component={AddOrder}
                            roles={[
                                EmployeeRole.salesman,
                                EmployeeRole.SUPERVISOR
                            ]}
                        />
                    },
                    {
                        path: "edits",
                        children: [
                            {
                                path: ":id",
                                element: <PrivateRoute
                                    Component={EditOrder}
                                    roles={[
                                        EmployeeRole.salesman,
                                        EmployeeRole.KITCHEN,
                                        EmployeeRole.SUPERVISOR
                                    ]}
                                />,
                            }
                        ]
                    },
                    {
                        path: ":status",
                        element: <PrivateRoute
                            Component={StatusOrder}
                            roles={[
                                EmployeeRole.salesman,
                                EmployeeRole.KITCHEN,
                                EmployeeRole.SUPERVISOR
                            ]}
                        />
                    },
                ]
            },
            {
                path: "branches",
                children: [
                    {
                        index: true,
                        element: <PrivateRoute
                            Component={Branches}
                            roles={["admin", "superadmin"]}
                        />,
                    },
                    {
                        path: "add",
                        element: <PrivateRoute
                            Component={AddBranch}
                            roles={["admin", "superadmin"]}
                        />,
                    },

                    {
                        path: ":id",
                        element: <PrivateRoute
                            Component={EditBranch}
                            roles={["admin", "superadmin"]}
                        />,
                    }
                ]
            },
            {
                path: "employees",
                children: [
                    {
                        index: true,
                        element: <PrivateRoute
                            Component={Employees}
                            roles={["admin", "superadmin"]}
                        />,
                    },
                    {
                        path: "add",
                        element: <PrivateRoute
                            Component={AddEmployee}
                            roles={["admin", "superadmin"]}
                        />,
                    },
                    {
                        path: "edit",
                        children: [
                            {
                                path: ":id",
                                element: <PrivateRoute
                                    Component={EditEmployee}
                                    roles={["admin", "superadmin"]} />,
                            }
                        ]
                    },
                    {
                        path: ":id",
                        element: <PrivateRoute
                            Component={Employee}
                            roles={["admin", "superadmin"]}
                        />,
                    }
                ]
            },
            {
                path: "clients",
                children: [
                    {
                        index: true,
                        element: <PrivateRoute
                            Component={Clients}
                            roles={["admin", "superadmin"]}
                        />
                    },
                    {
                        path: ":id",
                        element: <PrivateRoute
                            Component={EditClient}
                            roles={["admin", "superadmin"]}
                        />,
                    }
                ]
            },
            {
                path: "*",
                element: <NodFoundPage />
            }
        ]
    },
    {
        path: "/",
        element: <AuthLayout />,
        children: [
            {
                path: "login",
                element: <Login />
            }, {
                path: "forget-password",
                element: <ForgetPassword />
            }
        ]
    }
])

export default routes