import React from 'react'
import { IUser } from '../../../interfaces/user'
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch } from '../../../hooks/store.hook';
import IconButton from '@mui/material/IconButton';
import { toggleDeleteEmployeeModel } from '../../../store/slices/model.slice';

export default function DeleteEmployee({ employee }: { employee: IUser }) {

  const dispatch = useAppDispatch()
  return (
    <IconButton onClick={() => dispatch(toggleDeleteEmployeeModel(employee.id))}>
      <DeleteIcon color="error" />
    </IconButton>
  )
}
