import { Box, Grid } from '@mui/material'
import Table from '../../table/Table'
import { columns } from './columns'
import { ReportsListProps } from './reportsList.props'
import MobileView from '../views/MobileView'
import Spinner from '../../spinner/Spinner'

export default function ReportsList({ isAll, reports, handelSorting, isLoading }: ReportsListProps) {


    return (
        <Box
            component="div"
        >

            <Table
                columns={columns(handelSorting, isLoading)}
                rows={reports}
                columnVisibilityModel={{ id: false }}
                isLoading={isLoading}
            />

            {isLoading ? <Box display={{ md: "none" }}> <Spinner /></Box> : <MobileView reports={reports} />}

        </Box>
    )
}
