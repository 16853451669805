import React from 'react'
import { IEmployee } from '../../../interfaces/employees.interface'
import { Box, Grid, IconButton, Stack } from '@mui/material'
import moment from 'moment'
import { mobileBreakPoint } from '../../../utilities/constants'
import Status from '../../status/Status'
import User from '../../user/User'
import { useNavigate } from 'react-router-dom'
import Card from '../../card/card/Card'
import CardContent from '../../card/card-content/CardContent'
import DeleteIcon from '@mui/icons-material/Delete';
import { IUser } from '../../../interfaces/user'
import { useAppDispatch } from '../../../hooks/store.hook'
import { toggleDeleteEmployeeModel } from '../../../store/slices/model.slice'

export default function MobileView({ employees }: { employees: IUser[] }) {

    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    function onClick(e: any, id: number) {
        e.stopPropagation()
        dispatch(toggleDeleteEmployeeModel(id))
    }
    return (
        <Box
            component="div"
            sx={{
                display: "none",
                [mobileBreakPoint]: {
                    display: "block"
                }
            }}
        >
            <Stack
                spacing={1}
            >
                {employees.map(employee => <Card
                    key={employee.id}
                    onClick={() => navigate(`/employees/${employee.id}`)}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <CardContent
                                dark={true}
                                titleNode={
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <span>Name</span>
                                        <IconButton onClick={(e) => onClick(e, employee.id)}>
                                            <DeleteIcon
                                                color="error"
                                                sx={{
                                                    fontSize: "20px"
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CardContent
                                textNode={
                                    <User
                                        name={employee.name}
                                        email={employee.email}
                                        image={`${employee.profile_pic}`}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item container xs={6}>
                            <Grid item xs={12} >
                                <CardContent
                                    dark={true}
                                    title='Phone Number'
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <CardContent
                                    text={employee.phone_number}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                            <Grid item xs={12} >
                                <CardContent
                                    dark={true}
                                    title='Join Date'
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <CardContent
                                    text={moment(employee.created_at).format("DD/MM/YYYY")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                            <Grid item xs={12} >
                                <CardContent
                                    dark={true}
                                    title='Branch'
                                />
                            </Grid>
                            <Grid item xs={12} height="100%" alignItems="center">
                                <CardContent
                                    text={employee.location_name}
                                    underline={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                            <Grid item xs={12} >
                                <CardContent
                                    dark={true}
                                    title='Role'
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <CardContent
                                    textNode={<Status status={employee.user_type} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>)}

            </Stack>
        </Box>
    )
}
