import { Box } from '@mui/material';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { IUser } from '../../../interfaces/user';

export default function EmployeeData({ employee }: { employee: IUser }) {

    return (
        <Box>
            <Mobile employee={employee} />

            <Desktop employee={employee} />
        </Box>
    )
}
