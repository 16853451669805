import { useAppSelector } from '../../hooks/store.hook';
import { Navigate, useNavigate } from 'react-router-dom';
import React from 'react';


export default function PrivateRoute({ Component, roles, ...rest }: { Component: React.FC<any>, roles?: string[] }) {
    const { auth: { isLoggedIn }, user: { role } } = useAppSelector(s => s)

    return (
        <>
            {isLoggedIn ?
                !roles || roles.includes(role) ? < Component {...rest} />
                    : <Navigate to={role === "admin" || role === "superadmin" ? "/" : "/orders/processing"} replace={true} />
                : <Navigate to="/login" replace={true} />
            }

        </>
    );

};

