import React, { useEffect, useState } from 'react'
import FormLayout from '../components/form/layout/FormLayout'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeRole } from '../interfaces/employees.interface';
import { Box, FormControlLabel, Stack, Switch } from '@mui/material';
import TextInput from '../components/form/text-input/TextInput';
import { fullName } from '../utilities/helper';
import { IMenu } from '../components/filters/drop-down/drop-down.props';
import SelectInput from '../components/form/select-input/SelectInput';
import PasswordInput from '../components/form/password-input/PasswordInput';
import ImageInput from '../components/form/image-input/ImageInput';
import TextArea from '../components/form/text-area-input/TextArea';
import { useAppSelector, useAppDispatch } from '../hooks/store.hook';
import { isValid } from 'date-fns';
import { setSuccessMessage } from '../store/slices/success.slice';
import { addOrder } from '../store/thunk-actions/order-action';
import { addBranch } from '../store/thunk-actions/branch-action';
import RadioInput from '../components/form/radio-input/RadioInput';
import { phonePlaceHolder, phoneValidation, phoneValidationMsg } from '../utilities/constants';
import { areaMenu, cityMenu } from '../data/cities';

const phoneRegExp = /^(\+?971|0)([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const schema = yup.object({
    name: yup.string().required("Branch name is required"),
    location_prefix: yup.string().required("Location code is required"),
    area: yup.string().notOneOf(["---"], "Area is required").required("Area is required"),
    city: yup.string().notOneOf(["---"], "City is required").required("City is required"),
    phone_number: yup.string().matches(phoneValidation, phoneValidationMsg).required("Phone number is required"),
    message_ready: yup.string().optional(),
    message_create: yup.string().optional(),
    message_survey: yup.string().optional(),
}).required();

export default function AddBranchForm() {

    const { branch: { isLoading } } = useAppSelector(s => s)

    const dispatch = useAppDispatch()

    const [file, setFile] = useState(null)

    const [is_disabled_create, setIsPlaceOrderMassage] = useState(true)
    const [is_disabled_ready_to_pick_up, setIsReadyMassage] = useState(true)
    const [is_disabled_survey, setIsSurveyMassage] = useState(true)

    //form part
    const { control, watch, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            area: "---",
            city: "---",
            phone_number: "+971",
            location_prefix: "",
            location_img: "",
            state: "enabled",
            message_ready: "",
            message_create: "",
            message_survey: ""
        }
    });

    function setImage(e: any) {
        setFile(e)
        setValue("location_img", e)
    }

    const onSubmit = (data: any) => {
        const payload = {
            ...data,
            is_disabled_create,
            is_disabled_ready_to_pick_up,
            is_disabled_survey
        }

        dispatch(addBranch(payload)).unwrap()
            .then(() => {
                reset()
                setFile(null)
                setIsPlaceOrderMassage(true)
                setIsReadyMassage(true)
                setIsSurveyMassage(true)
                dispatch(setSuccessMessage("Branch created successfully."))
            })
    }

    // const phoneNumber = watch("phone_number");

    // useEffect(() => {
    //     if (!phoneNumber.startsWith('+971')) {
    //         console.log("mmm")
    //         setValue('phone_number', '+971' + phoneNumber.replace(/^\+971/, ''));
    //     }
    // }, [phoneNumber])

    return (
        <FormLayout
            title='Add New Branch'
            onSubmit={handleSubmit(onSubmit)}
            button={{
                isDirty: true,
                loading: isLoading,
                isValid: true
            }}
        >

            <Stack spacing={{ md: 4, xs: 3 }} mb={{ xs: 3, md: 4 }}>
                <SelectInput
                    control={control}
                    name={"city"}
                    error={errors.city?.message}
                    label={"City"}
                    menu={cityMenu}
                />


                {/* <SelectInput
                    control={control}
                    name={"area"}
                    error={errors.area?.message}
                    label={"Area"}
                    menu={areaMenu}
                /> */}

                <TextInput
                    control={control}
                    name={"area"}
                    error={errors.area?.message}
                    label={"Area"}
                    placeholder='Area'
                />

                <TextInput
                    control={control}
                    name={"name"}
                    error={errors.name?.message}
                    label={"Branch Name"}
                    placeholder='Branch name'
                />

                <TextInput
                    control={control}
                    name={"location_prefix"}
                    error={errors.location_prefix?.message}
                    label={"Branch Code"}
                    placeholder='Branch Code'
                    helperText='Max 4 character!'
                    maxLength={4}
                />

                <ImageInput
                    file={file}
                    onchange={setImage}
                />

                <TextInput
                    control={control}
                    name={"phone_number"}
                    error={errors.phone_number?.message}
                    label={"Branch Manager Phone number"}
                    placeholder={phonePlaceHolder}
                    mask="+\9\71999999999"
                />

                <Box>
                    <TextArea
                        control={control}
                        name={"message_create"}
                        error={errors.message_create?.message}
                        label={"Place order message"}
                        placeholder="The message that will be sent in the SMS for the client when the salesman places an order."
                        helperText='Input variable "{{client_name}} = client name , {{orders_head_of}} = orders ahead of , {{ticket_number}} = ticket number"'
                        isDisabled={is_disabled_create}
                    />

                    <FormControlLabel
                        onChange={() => setIsPlaceOrderMassage(s => !s)}
                        control={<Switch checked={!is_disabled_create} />}
                        label={
                            is_disabled_create ? "Place order message is disabled" : "Place order message is enabled"
                        } />
                </Box>

                <Box>
                    <TextArea
                        control={control}
                        name={"message_ready"}
                        error={errors.message_ready?.message}
                        label={"Ready to pickup a message"}
                        placeholder="The message that will be sent in the SMS for the client when the kitchen make an order ready to pickup."
                        helperText='Input variable "{{client_name}} = client name , {{ticket_number}} = ticket number"'
                        isDisabled={is_disabled_ready_to_pick_up}
                    />

                    <FormControlLabel
                        onChange={() => setIsReadyMassage(s => !s)}
                        control={<Switch checked={!is_disabled_ready_to_pick_up} />}
                        label={
                            is_disabled_ready_to_pick_up ? "Ready to pickup message is disabled" : "Ready to pickup message is enabled"
                        } />
                </Box>

                <Box>
                    <TextArea
                        control={control}
                        name={"message_survey"}
                        error={errors.message_survey?.message}
                        label={"Survey message"}
                        placeholder="The message that will be sent in the SMS for the client after 24 hrs"
                        helperText='Input variable "{{client_name}} = client name"'
                        isDisabled={is_disabled_survey}
                    />

                    <FormControlLabel
                        onChange={() => setIsSurveyMassage(s => !s)}
                        control={<Switch checked={!is_disabled_survey} />}
                        label={
                            is_disabled_survey ? "Survey message is disabled" : "Survey message is enabled"
                        } />
                </Box>


                <RadioInput
                    control={control}
                    name={"state"}
                    label={"Status"}
                    defaultValue={"enabled"}
                    menu={[
                        { value: "enabled", label: "Enabled" },
                        { value: "disabled", label: "Disabled" }
                    ]}
                />
            </Stack>

        </FormLayout>
    )
}
